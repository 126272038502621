import { User } from '../../models/user';
import { createFeatureSelector } from '@ngrx/store';
import { USER_DATA } from '../../../../environments/config';
import { Valet } from '../../../admin/modules/admin-valet/models/valet';

export interface UserState {
  user: User & Valet;
  error?: any;
}

export const userInitialState = {
  user: localStorage.getItem(USER_DATA) ? JSON.parse(localStorage.getItem(USER_DATA)) : null,
};


export const selectAuthState = createFeatureSelector<UserState>('user');
