<nb-card class="recruitment-dialog-card" style="position: relative">
    <nb-card-header class="d-flex justify-content-center align-items-center">
      <img src="assets/images/popvalet_icon_valet.png">
      <h3 class="text-center ml-4">{{'recruitment.login.welcome' | translate}} <span style="color: red">!</span></h3>
    </nb-card-header>

    <nb-card-body class="dialog-container-center" *ngIf="dialogStep === 0" [formGroup]="loginForm">
      <div class="d-flex align-items-center flex-column col-12">
        <input nbInput placeholder="Email" class="col-6" formControlName="email" oninput="this.value = this.value.toLowerCase()">
        <input nbInput placeholder="Mot de passe" class="col-6 mt-4" formControlName="password" [type]="'password'">
        <a (click)="forgottenPassword()" style="color: white; cursor: pointer" class="mt-4">{{'recruitment.login.forgotten_password' | translate}}</a>
      </div>
    </nb-card-body>

    <nb-card-body class="dialog-container-center" *ngIf="this.dialogStep === 1" [formGroup]="resetForm">
      <div class="d-flex align-items-center flex-column col-12">
        <input nbInput placeholder="Email" class="col-6" formControlName="email">
      </div>
    </nb-card-body>

    <nb-card-footer style="text-align: center;">
      <button nbButton class="next-button" *ngIf="dialogStep === 0"
              (click)="submit()">{{'recruitment.login.next' | translate}}</button>
      <button nbButton class="next-button mr-2" *ngIf="dialogStep === 1"
              (click)="returnToLogin()">{{'recruitment.login.previous' | translate}}</button>
      <button nbButton class="next-button" *ngIf="dialogStep === 1"
              (click)="resetPassword()">{{'recruitment.login.next' | translate}}</button>
    </nb-card-footer>
</nb-card>
