import {Pipe, PipeTransform} from '@angular/core';
import {UtilsHelper} from '../helpers/utils.helper';
import {FormGroup} from '@angular/forms';

@Pipe({
  name: 'isNilOrEmpty'
})
export class IsNilOrEmpty implements PipeTransform {

  transform(value: any): boolean {
    return UtilsHelper.IsNilOrEmpty(value);
  }

}

@Pipe({
  name: 'sortByKey'
})
export class SortByKeyPipe implements PipeTransform {

  transform<T>(value: T[], key: string, order: string = 'asc'): T[] {
    if (!Array.isArray(value) || !key) {
      return value;
    }
    const arrayToSort = [...value]
    arrayToSort.sort((a, b) => a[key] > b[key] ? 1 : -1 );
    return order === 'asc' ? arrayToSort : arrayToSort.reverse();
  }

}

@Pipe({
  name: 'asFormGroup'
})
export class AsFormGroup implements PipeTransform {

  transform(value: any) {
    return value as FormGroup;
  }

}
