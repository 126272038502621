// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleMapsApiKey: 'AIzaSyBWHGIO1ESG6eq_ZTY6wzhAR8ry-FrwdJY',
  tinyMCEApiKey: 'k9jry19d7bh0uu2hgvy5widnnacv77xpt768ywhb92g6g6a9',
  backendUrl: 'https://admin-beta-api.popvalet.com/',
  stripeKey: 'pk_test_J8NfXYl9P9pfF21lrZArFQBl',
  versionCheckUrl: 'https://admin-beta.popvalet.com/version.txt'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
