import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ToastHandlerService } from '../../services/toast-handler.service';
import { Observable } from 'rxjs';
import { CityActionTypes, GetCities, GetCitiesSuccess } from './city.actions';
import { map, switchMap } from 'rxjs/operators';
import { CityService } from '../../services/city.service';

@Injectable()
export class CityEffects {

  constructor(private actions: Actions,
              private cityService: CityService,
              private toastService: ToastHandlerService) {
  }


  @Effect()
  GetCities: Observable<any> = this.actions.pipe(
    ofType(CityActionTypes.GET_CITIES),
    // map((action: GetCities) => action.payload),
    switchMap(payload => {
      return this.cityService.getCities().pipe(
        map(res => {
          return new GetCitiesSuccess(res.data);
        })
      );
    })
  );
}
