import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Anomaly, HistoryType } from '../../../models/anomaly';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { AnomalyService } from '../../../services/common-anomalies.service';
import { ACCEPTED_ANOMALY_FILE_TYPES } from 'src/app/shared/constants/constants';

@Component({
  selector: 'pv-common-anomaly-treat-accident-dialog',
  templateUrl: './common-anomaly-treat-accident-dialog.component.html',
  styleUrls: ['./common-anomaly-treat-accident-dialog.component.scss']
})
export class CommonAnomalyTreatAccidentDialogComponent implements OnInit {

  @Input() anomaly: Anomaly;
  @Output() submitEvent = new EventEmitter();
  
  public form: FormGroup;
  public insurance: boolean = true;
  public valetResponsible: boolean = true;
  public quotationValid: boolean = true;
  public acceptedFileTypes = ACCEPTED_ANOMALY_FILE_TYPES;
  public quotationSelect = [];
  public submitDisabled: boolean = false;

  constructor(private fb: FormBuilder,
              private anomalyService: AnomalyService,
              protected dialogRef: NbDialogRef<CommonAnomalyTreatAccidentDialogComponent>) { }

  ngOnInit(): void {
    this.initForm();
    // this.initFiles();
  }

  initForm() {
    this.form = this.fb.group({
      insurance : [true],
      emails: [['assurance@gmail.com', 'comptabilite@popvalet.com'], Validators.required],
      email: [],
      message: ["Bonjour assurance, ici le message pour l'assurance. L’indemnité doit etre versé par virement au RIB fourni dans l’email et non par chèque. Vous trouverez en pièces-jointes les documents nécéssaires au traitement du dossier.", Validators.required],
      valetResponsible: [true],
      quotationValid: [true],
      quotation: [(this.anomaly.quotations[0] as any).filename],
      files: [],
      weproovLink: []
    });

    this.form.valueChanges.subscribe(() => {
      this.insurance = this.form.get('insurance').value;
      this.valetResponsible = this.form.get('valetResponsible').value;
      this.quotationValid = this.form.get('quotationValid').value;
      this.submitDisabled = !this.form.valid;
    });
  }

  initFiles() {
    // TODO : Gérer qu'il y ait deux types de fichiers, ceux que l'on a depuis la DB (registrationCertificate, report, RIB?) et ceux que l'on vient d'ajouter et qui ne sont pas encore en DB, à voir comment faire
    // TODO : Ajouter le RIB dans les fichiers
    
    const formControl = this.form.get('files');
    let files = [];
    files.push(this.anomaly.registrationCertificate);
    files.push(this.anomaly.report);
    formControl.setValue(files);
  }

  async submit() {
    const treatData: any = {
      anomalyId: this.anomaly.id,
      insurance: this.insurance,
      quotation: this.form.get('quotation').value,
      emails: this.form.get('emails').value,
      message: this.form.get('message').value,
      files: this.form.get('files').value,
      valetResponsible: this.valetResponsible,
      quotationValid: this.quotationValid,
      weProovLink: this.form.get('weproovLink').value
    }

    this.anomalyService.treatAccidentAnomaly(treatData).then((res)=>{
      this.anomalyService.createNewHistory(this.anomaly.id, "Anomalie traitée", HistoryType.Modified).then((res)=> {
        this.submitEvent.emit();
        this.closeDialog();
      });
    });
  }

  public uploadFiles(event: any) {
    if (event && event.target && event.target.files) {
      const newFiles = Array.from(event.target.files);
      const formControl = this.form.get('files');
      let files = formControl.value || [];
      for(let file of newFiles) {
        files.push(file);
      }
      formControl.setValue(files);
    }
  }

  public deleteFile(fileName: string) {
    const formControl = this.form.get('files');
    if (formControl && formControl.value) {
      const files = formControl.value.filter((file: any) => file.name !== fileName);
      formControl.setValue(files);
    }
  }

  public addEmail() {
    const emailsControl = this.form.get('emails');
    if (emailsControl) {
      const emails: string[] = emailsControl.value || [];
      const updatedEmails = [...emails, this.form.get('email').value];
      emailsControl.setValue(updatedEmails);
      this.form.get('email').setValue('')
    }
  }

  public deleteEmail(email: string) {
    const emailsControl = this.form.get('emails');
    if (emailsControl) {
      const emails: string[] = emailsControl.value || [];
      const updatedEmails = emails.filter(item => item !== email);
      emailsControl.setValue(updatedEmails);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
