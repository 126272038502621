<div class="caption-2 error_text"
     [ngStyle]="{'text-align': (textAlign || 'right')}"
     [@slideInOut]
     *ngIf="showErrors$ | async">
  <ng-container *ngIf="(this.error$ | async) && !(this.error$ | async).errorLabel; else customLabel" [ngSwitch]="validation">
    <span *ngSwitchCase="'required'">{{'global.form.error.required'|translate}}</span>
    <span *ngSwitchCase="'pattern'">{{'global.form.error.wrong_format'|translate}}</span>
    <span *ngSwitchCase="'email'">{{'global.form.error.wrong_format'|translate}}</span>
    <span *ngSwitchCase="'minlength'">{{'global.form.error.min_length'|translate: {requiredLength: (this.formSet$ | async)?.errors[validation].requiredLength} }}</span>
    <span *ngSwitchCase="'maxlength'">{{'global.form.error.max_length'|translate: {requiredLength: (this.formSet$ | async)?.errors[validation].requiredLength} }}</span>
    <ng-container *ngSwitchDefault>
      <ng-content>
      </ng-content>
    </ng-container>
  </ng-container>
  <ng-template #customLabel>
    <span>{{ (this.error$ | async).errorLabel }}</span>
  </ng-template>
</div>

