import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastHandlerService} from '../../../../shared/services/toast-handler.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'pv-common-file-upload',
  templateUrl: './common-file-upload.component.html',
  styleUrls: ['./common-file-upload.component.scss']
})
export class CommonFileUploadComponent implements OnInit {

  public file: File;
  public filename: string;

  @Input() uploadIndication?: string;
  @Input() acceptedFileTypes: string;
  @Input() maxSize?: number;

  @Output() fileChanged = new EventEmitter<File>();

  constructor(private toastService: ToastHandlerService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  change(event: any) {
    const file = event.target.files[0];

    const extension = file.name.split('.').pop().toLowerCase();
    const acceptedFileTypesArray = this.acceptedFileTypes.split(',').map(ext => ext.trim().substring(1).toLowerCase());
    if (!acceptedFileTypesArray.includes(extension)) {
      this.toastService.showErrorToasts(
        this.translateService.instant('modules.file_upload.error.type_not_accepted', {
          acceptedFileTypes: acceptedFileTypesArray.join(', ')
        })
      );
      return;
    }

    if (this.maxSize && file.size > this.maxSize) {
      this.toastService.showErrorToasts(
        this.translateService.instant('modules.file_upload.error.size_too_big', {
          maxSizeMB: Math.floor(this.maxSize / 1024 / 1024)
        })
      );
      return;
    }

    this.file = file;
    this.filename = this.file.name;
    this.fileChanged.emit(this.file);
  }
}
