import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DateFilterCustomValue, DateFilterUnit} from '../date-filter.model';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, startWith, takeUntil, tap} from 'rxjs/operators';
import * as fastDeepEqual from 'fast-deep-equal';
import {DateFilterHelper} from '../../../helpers/date-filter.helper';
import {NbCalendarRange} from '@nebular/theme';
import {DateFilter} from '../../../../../submodule/model/date-filter.model';

@Component({
  selector: 'pv-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit, OnDestroy {
  public dateFilterUnit = DateFilterUnit;
  public keyUnit = Object.keys(this.dateFilterUnit)
  public dateFilterCustomValue = DateFilterCustomValue;
  public keyCustomValue = Object.keys(this.dateFilterCustomValue)
  public dateSelected$: BehaviorSubject<DateFilter> = new BehaviorSubject<DateFilter>(null);
  public unit$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public value$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public customSelection$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public range: NbCalendarRange<Date>;
  public range$: BehaviorSubject<NbCalendarRange<Date>> = new BehaviorSubject<NbCalendarRange<Date>>(null);
  public date: Date;
  public date$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  private destroy$: Subject<void> = new Subject<void>();

  @Input('filterData')
  set filterData(filterData: DateFilter) {
    this.dateSelected$.next(filterData);
  }

  @Output() dateSelected: EventEmitter<DateFilter> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    const unitWithReset = this.unit$.pipe(
      distinctUntilChanged(),
      tap(() => this.value$.next(null))
    );

    combineLatest([unitWithReset, this.value$]).pipe(
      distinctUntilChanged(fastDeepEqual),
      debounceTime(50),
      tap(([unit, value]) => {
        if (!!unit || !!value) {
          this.resetCustomValue()
        }
      }),
      takeUntil(this.destroy$),
    ).subscribe(([unit, value]) => {
      this.dateSelected$.next(DateFilterHelper.getDateValue(value, unit));
    });

    const customSelectionWithReset$ = this.customSelection$.pipe(
      distinctUntilChanged(),
      filter(Boolean),
      tap((custom) => {
        if (DateFilterHelper.isRangeDate(custom as DateFilterCustomValue)) {
          this.date$.next(null);
        } else {
          this.range$.next(null)
        }
      }),
    );

    const dateWithReset$ = this.date$.pipe(
      distinctUntilChanged(),
      tap((date) => this.date = date),
      filter(Boolean),
      tap(date => {
        if (!!date) this.range$.next(null);
      })
    );

    const rangeWithReset$ = this.range$.pipe(
      distinctUntilChanged(),
      filter(Boolean),
      tap(range => {
        if (!!range) this.date$.next(null);
      })
    );

    combineLatest([
      dateWithReset$.pipe(startWith(null as string)),
      rangeWithReset$.pipe(startWith(null as string)),
      customSelectionWithReset$]
    ).pipe(
      debounceTime(50),
      tap(_ => this.resetValue()),
      takeUntil(this.destroy$)
    ).subscribe(([date, range, customSelection]: [Date, NbCalendarRange<Date>, DateFilterCustomValue]) => {
      this.dateSelected$.next(DateFilterHelper.getCustomValue(date, range, customSelection));
    });
  }

  private resetCustomValue() {
    this.customSelection$.next(null);
    this.date$.next(null);
    this.range$.next(null);
  }

  private resetValue() {
    this.unit$.next(null);
    this.value$.next(null);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
