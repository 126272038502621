import { Action } from '@ngrx/store';
import { User } from '../../models/user';

export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_WITH_TOKEN = '[Auth] Login with token',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGOUT = '[Auth] Logout',
  GET_USER = '[Auth] Get User',
  GET_USER_SUCCESS = '[Auth] Get User SUCCESS',
  GET_USER_ERROR = '[Auth] Get User ERROR',
  UPDATE_USER = '[Auth] Update User',
}

export interface AuthLoginPayload {
  email: string;
  password: string;
  rememberMe: boolean;
  redirectUrl?: string;
}

export interface AuthLoginWithTokenPayload {
  token: string;
  redirectUrl?: string;
}

export class LogIn implements Action {
  readonly type = AuthActionTypes.LOGIN;

  constructor(public payload: AuthLoginPayload) {
  }
}

export class LogInWithToken implements Action {
  readonly type = AuthActionTypes.LOGIN_WITH_TOKEN;

  constructor(public payload: AuthLoginWithTokenPayload) {
  }
}

export interface AuthSuccessLoginPayload {
  access_token: string;
  refresh_token: string;
  user: User;
  rememberMe: boolean;
  redirectUrl?: string;
}

export class LogInSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;

  constructor(public payload: AuthSuccessLoginPayload) {
  }
}

export class LogInFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;

  constructor(public payload: any) {
  }
}

export class LogOut implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class GetUser implements Action {
  readonly type = AuthActionTypes.GET_USER;
}

export class UpdateUser implements Action {
  readonly type = AuthActionTypes.UPDATE_USER;
  constructor(public payload: User) {
  }
}

export type All = LogOut | LogIn | LogInSuccess | LogInFailure;
