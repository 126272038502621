import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pv-map-helper-icon',
  templateUrl: './map-helper-icon.component.html',
  styleUrls: ['./map-helper-icon.component.scss']
})
export class MapHelperIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
