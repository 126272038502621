import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { first, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  constructor() {
  }

  async getPersonTitles(): Promise<{ name: string, value: string }[]> {
    return of([
      {
        name: 'Mr',
        value: 'Mr',
      },
      {
        name: 'Mme',
        value: 'Mme',
      },
      {
        name: 'Mlle',
        value: 'Mlle',
      },
    ]).pipe(first()).toPromise();
  }

  public getDays(): Promise<{ name: string, value: string }[]> {
    return of([{
      name: 'Lundi',
      value: 'monday',
    }, {
      name: 'Mardi',
      value: 'tuesday',
    },
      {
        name: 'Mercredi',
        value: 'wednesday',
      },
      {
        name: 'Jeudi',
        value: 'thursday',
      },
      {
        name: 'Vendredi',
        value: 'friday',
      },
      {
        name: 'Samedi',
        value: 'saturday',
      },
      {
        name: 'Dimanche',
        value: 'sunday',
      }
    ]).toPromise();
  }
}
