export const JWT_TOKEN_STORAGE = 'popvalet-access-token';
export const JWT_REFRESH_TOKEN_STORAGE = 'popvalet-refresh-token';
export const USER_DATA = 'popvalet-user';

export const GRID_EDIT_ICON = {
  editButtonContent: '<i class="far fa-edit"></i>',
  saveButtonContent: '<i class="fa fa-check"></i>',
  cancelButtonContent: '<i class="fas fa-times"></i>',
  confirmSave: true
};

export const GRID_DELETE_ICON = {
  deleteButtonContent: '<i class="far fa-trash-alt"></i>',
  confirmDelete: true,
};


export const GRID_ADD_ICON = {
  addButtonContent: `<i class="fas fa-plus"></i>`,
  createButtonContent: '<i class="fa fa-check"></i>',
  cancelButtonContent: '<i class="fas fa-times"></i>',
  confirmCreate: true
};

export const GRID_EYE_ICON = {
  editButtonContent: '<i class="fas fa-eye"></i>',
};
