import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GoogleAddressParser} from '../../../../shared/classes/google-address-parser';
import {Address} from '../../../../admin/modules/admin-places/models/address';
import {PlaceSearchDirective} from '../../directives/place-search.directive';
import {MapsAPILoader} from '@agm/core';
import {AbstractControl, Form, FormGroup} from '@angular/forms';
import {BehaviorSubject, combineLatest, Subject, Subscription} from 'rxjs';
import PlaceResult = google.maps.places.PlaceResult;
import {debounceTime, filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'pv-common-map-address-selector',
  templateUrl: './common-map-address-selector.component.html',
  styleUrls: ['./common-map-address-selector.component.scss']
})
export class CommonMapAddressSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  public parsedAddress: Address | any = {};
  public latitude = 48.864716;
  public longitude = 2.349014;
  public zoom = 6;
  public showMarker = false;
  public addressData: Address;
  public mapHeight = '80%';

  private currentLabel = '';
  private subs: Subscription[] = [];
  private addressData$: BehaviorSubject<Address> = new BehaviorSubject(null);
  private mapLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private destroy$: Subject<void> = new Subject<void>();

  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() addressErrorVibrate = 0;

  @Input('addressData')
  set setAddressData(addressData: Address) {
    if (addressData) {
      this.addressData = addressData;
      this.addressData$.next(addressData);
    }
  }
  @Input('mapHeight')
  set setMapHeight(mapHeight: string) {
    this.mapHeight = mapHeight;
  }

  @ViewChild(PlaceSearchDirective, {static: true}) placeSearch: PlaceSearchDirective;

  constructor(private mapsAPILoader: MapsAPILoader) { }

  ngOnInit() {
    combineLatest([this.addressData$, this.mapLoaded$])
      .pipe(
        filter(([addressData, mapLoaded]) => !!addressData && !!mapLoaded),
        debounceTime(50),
        takeUntil(this.destroy$)
      ).subscribe(([addressData]) => {
        this.displayMarker(this.addressData.lat, this.addressData.lng);
        this.placeSearch.setName(this.addressData.label);
        this.form.patchValue(addressData);
      });

    if (this.form) {
      const addressForm = this.controlName ? this.form.get(this.controlName) : this.form
      const lat = addressForm?.get('lat')?.value;
      const lng = addressForm?.get('lng')?.value;

      if (!!lat && !!lng) {
        this.displayMarker(lat, lng);
      }
    }
    this.currentLabel = this.form.get('label')?.value;
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
    this.destroy$.next();
  }

  ngAfterViewInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.mapLoaded$.next(true);
    });
  }

  public placeChange(place: PlaceResult) {
    if (!place) {
      return;
    }
    this.parsedAddress = GoogleAddressParser.parseAddress(place);
    this.parsedAddress.lat = place.geometry.location.lat();
    this.parsedAddress.lng = place.geometry.location.lng();
    this.displayMarker(place.geometry.location.lat(), place.geometry.location.lng());

    console.log(this.parsedAddress);
    this.currentLabel = this.parsedAddress.label;
    if (this.controlName) {
      this.form.get(this.controlName).setValue(this.parsedAddress);
      this.form.updateValueAndValidity();
      this.form.get(this.controlName).markAllAsTouched();
      this.form.get(this.controlName).markAsDirty()

    } else {
      const rawValue = this.form.getRawValue();
      for (const key of ['number', 'street', 'label', 'name', 'postalCode', 'city', 'country', 'countryCode', 'lat', 'lng']) {
        rawValue[key] = '';
      }
      this.form.reset(rawValue);
      this.form.patchValue(this.parsedAddress);
      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();
      this.form.markAsDirty()
    }
  }

  private displayMarker(lat, long) {
    this.showMarker = true;
    this.latitude = lat;
    this.longitude = long;
    this.zoom = 14;
  }

  public markerPositionChanged(event) {
    this.latitude = event.latLng.lat();
    this.longitude = event.latLng.lng();
    this.placeSearch.setPlaceByGeocoding({ lat: this.latitude, lng: this.longitude });
  }

}
