<nb-card>
  <nb-card-header>
    <div *ngIf="!(dateSelected$ | async | isNilOrEmpty); else pickDate" class="d-flex align-items-center">
      <div class="mb-1" [innerHTML]="dateSelected$ | async | getDateLabelHtml"></div>
      <button nbButton class="ml-2" size="tiny" status="primary" (click)="dateSelected.emit(dateSelected$.value)">Valider</button>
    </div>
    <ng-template #pickDate>Selectionnez une date ci-dessous</ng-template>
  </nb-card-header>
  <nb-card-body class="example-items-col d-flex flex-column">
    <div class="mb-1"><strong>Sélectionnez une date prédéfinie</strong></div>
    <nb-button-group size="tiny" shape="rectangle" class="mb-1">
      <button *ngFor="let unit of keyUnit"
              nbButtonToggle
              [pressed]="(unit$ | async) === unit"
              (click)="unit$.next(unit)">
        {{ dateFilterUnit[unit] | getUnitLabel }}
      </button>
    </nb-button-group>
    <nb-button-group size="tiny" shape="rectangle">
      <button *ngFor="let value of (dateFilterUnit[(unit$ | async)] | getValuesForUnit)"
              nbButtonToggle
              [pressed]="(value$ | async) === value"
              (click)="value$.next(value.value)">{{ value.label }}</button>
    </nb-button-group>
  </nb-card-body>
  <nb-card-footer>
    <div class="mb-1"><strong>Ou une date personalisée</strong></div>
    <nb-button-group size="tiny" shape="rectangle" class="mb-1">
      <button *ngFor="let value of keyCustomValue"
              nbButtonToggle
              [pressed]="(customSelection$ | async) === value"
              (click)="customSelection$.next(value)">
        {{ dateFilterCustomValue[value] | getCustomValueLabel }}
      </button>
    </nb-button-group>
    <nb-calendar-range *ngIf="dateFilterCustomValue[(customSelection$ | async)] | isRangeDate; else calendar" [range]="range" (rangeChange)="range$.next($event)"></nb-calendar-range>
    <ng-template #calendar>
      <nb-calendar [date]="date" (dateChange)="date$.next($event)">
      </nb-calendar>
    </ng-template>
  </nb-card-footer>
</nb-card>
