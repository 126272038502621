<div class="search_holder">
  <input nbInput type="text" fullWidth autofocus
         [formControl]="searchControl"
         [value]=""
         #searchTextInput>
</div>

<!--<div class="select_items" *ngIf="!selectItems;else staticItems">
  <ng-content>
  </ng-content>
</div>-->

<!--<ng-template #staticItems>-->
<div class="select_items"
     *ngIf="!isLoading; else optionsLoading"
     (scroll)="onScroll($event)">
  <ng-container *ngIf="selectItems | commonAutocompleteAreGroups; else showSingleOptions">
    <ng-container *ngFor="let group of selectItems">
      <div class="group_header caption">
        <!--        (click)="selectAllChildren(group)"-->
        <b>
          {{group.name}}
        </b>
      </div>
      <pv-common-autocomplete-option
        *ngFor="let item of group.children"
        [isSelected]="selectedItems.has(item.value)"
        [multiple]="multiple"
        (onItemSelect)="itemSelected($event, item)">
        <ng-container *ngIf="displayTemplate; else noTemplate">
          <ng-container *ngTemplateOutlet="displayTemplate; context: { $implicit: item}"></ng-container>
        </ng-container>
        <ng-template #noTemplate>
          {{item.name}} - {{ item.value}}
        </ng-template>
      </pv-common-autocomplete-option>
    </ng-container>
    <button *ngIf="newButton"
            class="new-button"
            type="button"
            nbButton
            size="small"
            (click)="onNewButtonClicked()">
      {{'modules.autocomplete.new' | translate}}
    </button>
  </ng-container>
</div>
<!--</ng-template>-->
<!--[ngClass]="{'focus-background': focusedElementIndex == i}"-->

<ng-template #showSingleOptions>
  <!--  [tabIndex]="i"-->
  <button *ngIf="deselectEntitiesButton"
          class="deselect-entities"
          type="button"
          nbButton
          size="small"
          (click)="clearSelectedItems()">
    {{'modules.autocomplete.deselectEntities' | translate}}
  </button>
  <button *ngIf="newButton && !isLoading"
          class="new-button"
          type="button"
          nbButton
          size="small"
          (click)="onNewButtonClicked()">
    <span *ngIf="newButtonText">{{newButtonText}}</span>
    <span *ngIf="!newButtonText">{{'modules.autocomplete.new' | translate}}</span>
  </button>
  <pv-common-autocomplete-option
    *ngFor="let item of selectItems; let i = index, trackBy:trackById"
    [isSelected]="selectedItems.has(item.value)"
    (onItemSelect)="itemSelected($event, item)"
    [multiple]="multiple"
    [ngClass]="{'focus-background': focusedElementIndex == i, 'highlighted-background': item.highlighted}"
  >
    <ng-container *ngIf="displayTemplate; else noTemplate">
      <ng-container *ngTemplateOutlet="displayTemplate; context: { $implicit: item}"></ng-container>
    </ng-container>
    <ng-template #noTemplate>
      <span class="{{item.highlighted ? 'font-weight-bold' : ''}}">{{item.name}}</span>
    </ng-template>
  </pv-common-autocomplete-option>

</ng-template>


<ng-template #optionsLoading>
  <h6 class="text-center">
    {{'modules.autocomplete.loading' | translate}}
  </h6>
</ng-template>
