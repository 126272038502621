import {Component, Input, OnInit} from '@angular/core';
import {PvRecruitmentDialogComponent} from '../../pv-recruitment-dialog/pv-recruitment-dialog.component';
import {NbDialogService} from '@nebular/theme';

@Component({
  selector: 'pv-recruitement-tutorial-step',
  templateUrl: './recruitement-tutorial-step.component.html',
  styleUrls: ['./recruitement-tutorial-step.component.scss']
})
export class RecruitementTutorialStepComponent implements OnInit {
  @Input() candidate: any;

  constructor(private dialogService: NbDialogService) { }

  ngOnInit(): void {
  }

  public showRecruitmentDialog() {
    this.dialogService.open(PvRecruitmentDialogComponent, {
      context: {
        dialogStep: 1,
        retry: true
      }
    });
  }

}
