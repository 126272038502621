import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'profileAction'
})
export class ProfileActionPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(log: any): string {
    switch (true) {
      case log.active === false || log.ready === false:
        return this.translateService.instant('modules.valet.valet_profile.valet_disabled');
      case log.active && log.ready:
        return this.translateService.instant('modules.valet.valet_profile.valet_activated');
      case log.action.includes('blockRemuneration') && log.action.includes('blockExpenses'):
        return this.translateService.instant('modules.valet.valet_profile.expenses_and_remuneration_blocked');
      case log.action.includes('activateRemuneration') && log.action.includes('activateExpenses'):
        return this.translateService.instant('modules.valet.valet_profile.expenses_and_remuneration_activated');
      case log.action.includes('activateRemuneration') && !log.action.includes('activateExpenses'):
        return this.translateService.instant('modules.valet.valet_profile.remuneration_activated');
      case !log.action.includes('activateRemuneration') && log.action.includes('activateExpenses'):
        return this.translateService.instant('modules.valet.valet_profile.expenses_activated');
      case log.action.includes('blockRemuneration') && !log.action.includes('blockExpenses'):
        return this.translateService.instant('modules.valet.valet_profile.remuneration_blocked');
      case !log.action.includes('blockRemuneration') && log.action.includes('blockExpenses'):
        return this.translateService.instant('modules.valet.valet_profile.expenses_blocked');
      default:
        return '';
    }
  }

}
