import {Injectable} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import fixWebmDuration from 'fix-webm-duration';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class VideoRecordingService {

  private stream: MediaStream;
  private recorder: MediaRecorder;
  private startTime: number;
  private duration : number;
  private chunks: Blob[] = [];
  private blob = new Subject<Blob>();
  private blobSubscription: Subscription;
  private _recordingFailed = new Subject<string>();
  private _videoMimeType: string;

  get videoMimeType() {
    return this._videoMimeType;
  }

  constructor(private translateService: TranslateService) {
    this._videoMimeType = this.getVideoMimeType();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  activeCamera(conf: MediaStreamConstraints) {
    return new Promise<MediaStream>((resolve, reject) => {
      navigator.mediaDevices.getUserMedia(conf).then(stream => {
        this.stream = stream;
        resolve(this.stream);
      }).catch(error => {
        reject(error);
      });
    });
  }

  startRecording() {
    if (this.recorder) {
      this.recorder = null;
    }

    try {
      this.recorder = new MediaRecorder(this.stream, {
        mimeType: this._videoMimeType,
        bitsPerSecond: 1500000
      });
      this.recorder.start();

      this.recorder.ondataavailable = (evt) => {
        this.chunks.push(evt.data);
      }

      this.recorder.onstop = async () => {
        const recordedBlob = new Blob(this.chunks, {type: this.chunks[0].type});
        const fixedBlob = this._videoMimeType === 'video/webm'
          ? await fixWebmDuration(recordedBlob, this.duration)
          : recordedBlob;
        this.blob.next(fixedBlob);
        this.chunks = [];
      }

      this.duration = 0;
      this.startTime = performance.now();

    } catch (error) {
      this._recordingFailed.next(error.message);
    }
  }

  private getVideoMimeType() {
    const allTypes = [
      'video/webm',
      'video/mp4'
    ];

    const supportedTypes = allTypes.filter(t => MediaRecorder.isTypeSupported(t));

    if (supportedTypes.length === 0) {
      throw new Error(this.translateService.instant('recruitment.process.interview.no_supported_type_error'));
    }

    return supportedTypes[0];
  }

  stopRecording(): Promise<Blob> {
    return new Promise((resolve) => {
      if (this.recorder) {
        if (this.blobSubscription) {
          this.blobSubscription.unsubscribe();
        }
        this.recorder.stop();
        this.blobSubscription = this.blob.subscribe(blob => {
          resolve(blob);
        });
      }
    });
  }

  pauseRecording() {
    if (this.recorder) {
      this.recorder.pause();
      this.duration += performance.now() - this.startTime;
    }
  }

  resumeRecording() {
    if (this.recorder) {
      this.recorder.resume();
      this.startTime = performance.now();
    }
  }

  stopStream() {
    if (this.stream) {
      for (const track of this.stream.getTracks()) {
        track.stop();
      }
    }
  }
}
