<nb-card>
  <nb-card-body>
    <h3 class="m-0">{{'modules.valet.valet_invoices.title' | translate}}</h3>
    <stbw-grid [filteringObject]="filteringObj"
               [settings]="settings"
               [source]="source"
               [isLoading]="loading"
               (paginatorChangeEvent)="pageChanged($event)">
    </stbw-grid>
  </nb-card-body>
</nb-card>
