<div class="mt-5 mb-5">
  <ng-container *ngIf="candidate?.videoInterviewStatus === 'missing'">

    <!-- Introduction video -->
    <div *ngIf="introductionVideo">
      <div class="text-steps-interview">
        <h3 class="text-center">
          {{ 'recruitment.process.interview.introduction_title' | translate }}
        </h3>
        <p class="text-center">
          {{ 'recruitment.process.interview.welcome_message' | translate }}
        </p>
      </div>
      <div class="d-flex align-items-center flex-column container-introduction-video">
        <div class="introduction-container-interview">
          <video controls>
            <source
              src="https://d17m62rzgdg0on.cloudfront.net/videos/presentation_parcours_valet-2500k.mp4#t=0.001"
              type="video/mp4"
            >
          </video>
        </div>
        <button nbButton class="yellow-button" (click)="startInterviewProcess()">
          {{ 'recruitment.process.interview.introduction_video_done' | translate }}
        </button>
      </div>
    </div>

    <!-- Introduction finished -->
    <div *ngIf="!introductionVideo">
      <!-- Initial information -->
      <div class="text-steps-interview" *ngIf="testStep && !microphoneTest">
        <h3 class="text-center">
          {{ 'recruitment.process.interview.welcome_interview' | translate }}
        </h3>
        <p>
          {{ 'recruitment.process.interview.initial_information' | translate }}
        </p>
      </div>

      <!-- Camera/microphone verification -->
      <div class="text-steps-interview" *ngIf="testStep && microphoneTest">
        <h3 class="text-center">
          {{ 'recruitment.process.interview.camera_microphone_verification' | translate }}
        </h3>
        <p class="text-center" *ngIf="microphoneIsTesting">
          {{ 'recruitment.process.interview.camera_microphone_test' | translate }}
        </p>
      </div>

      <!-- Verification results -->
      <div class="text-steps-interview" *ngIf="!testStep && !finalRecord">
        <h4 class="text-center">{{ 'recruitment.process.interview.verification_finished' | translate }}</h4>
        <div class="recruitment-authorisation-container">
          <div class="recruitment-authorisation-valid mr-2" *ngIf="cameraActivated">
            <nb-icon icon="check"></nb-icon>
          </div>
          <div class="recruitment-authorisation-invalid mr-2" *ngIf="!cameraActivated">
            <nb-icon icon="times" style="text-align: center"></nb-icon>
          </div>
          <p *ngIf="cameraActivated">{{ 'recruitment.process.interview.authorization_granted' | translate }}</p>
          <p *ngIf="!cameraActivated">{{ 'recruitment.process.interview.authorization_not_granted' | translate }}</p>
        </div>
        <div *ngIf="!cameraActivated">
          <p>{{ 'recruitment.process.interview.authorisation_popup_message' | translate }}</p>
          <div class="d-flex manage-interview-buttons mt-4 mb-5">
            <button
              nbButton
              class="yellow-button"
              (click)="startTestMicrophone()"
            >
              {{ 'recruitment.process.interview.ask_authorisations' | translate }}
            </button>
          </div>
          <a *ngIf="platform.BLINK" href="https://support.google.com/chrome/answer/2693767?hl=fr" target="_blank">
            {{ 'recruitment.process.interview.authorisations_help_chrome' | translate }}
          </a>
        </div>
      </div>

      <!-- Video recording -->
      <div class="text-steps-interview" *ngIf="finalRecord && !recordFinished">
        <h3 class="text-center" *ngIf="!isVideoRecording">
          {{ 'recruitment.process.interview.video_recording_prompt' | translate }}
        </h3>
        <h3 class="text-center" *ngIf="isVideoRecording">
          {{ 'recruitment.process.interview.answer_to_question' | translate }}
        </h3>
        <p class="text-center each-question-interview">
          {{ questions[currentQuestionIndex].question }}
        </p>
        <h4 class="text-center" *ngIf="isVideoRecording && recordingTime">
          {{ 'recruitment.process.interview.time_remaining' | translate }} {{ secondsRemaining }}
        </h4>
      </div>

      <!-- Start recording information -->
      <div class="d-flex align-items-center flex-column container-tutorial-video" *ngIf="!introductionVideo">
        <div *ngIf="isVideoRecording && recordingTime" class="d-flex align-items-center">
          <div class="record_circle"></div>
          <h4 class="ml-2" style="margin-top: 0; margin-bottom: 0">
            {{ 'recruitment.process.interview.recording_in_progress' | translate }}
          </h4>
        </div>
        <div class="video-container-interview">
          <div class="counter-video-interview-container">
            <p class="counter-video-interview" *ngIf="showStartVideoCounter && startVideoCounter > 0">
              {{ startVideoCounter }}
            </p>
            <h3 class="good-luck-video-interview" *ngIf="showStartVideoCounter && startVideoCounter === 0">
              {{ 'recruitment.process.interview.good_luck' | translate }}
            </h3>
          </div>
          <video #videoElement *ngIf="!recordFinished" playsinline></video>
        </div>
      </div>
    </div>

    <!-- Action buttons -->
    <div class="d-flex mt-5 mb-5 manage-interview-buttons" *ngIf="!introductionVideo">
      <button
        *ngIf="testStep && !microphoneTest"
        nbButton
        class="yellow-button"
        (click)="showTestMicrophone()"
      >
        {{ 'recruitment.process.interview.understand' | translate }}
      </button>
      <button
        *ngIf="!disabledButton && testStep && microphoneTest && !microphoneIsTesting"
        nbButton
        class="yellow-button"
        (click)="startTestMicrophone()"
      >
        {{ 'recruitment.process.interview.start_verification' | translate }}
      </button>
      <button
        *ngIf="!testStep && !finalRecord && cameraActivated"
        nbButton
        class="yellow-button"
        (click)="showFirstQuestion()"
      >
        {{ 'recruitment.process.interview.show_first_question' | translate }}
      </button>
      <button
        *ngIf="!isVideoRecording && finalRecord && (currentQuestionIndex < 1) && !disabledButton"
        nbButton
        class="yellow-button"
        (click)="startVideoRecording()"
      >
        {{ 'recruitment.process.interview.start_interview' | translate }}
      </button>
      <button
        *ngIf="isVideoRecording && finalRecord && !disabledButton"
        nbButton
        class="yellow-button"
        (click)="pauseVideoRecording()"
      >
        {{ 'recruitment.process.interview.answer_done' | translate }}
      </button>
      <button
        *ngIf="!isVideoRecording && finalRecord && !recordFinished && (currentQuestionIndex >= 1) && !disabledButton"
        nbButton
        class="yellow-button"
        (click)="resumeVideoRecording()"
      >
        {{ 'recruitment.process.interview.continue_interview' | translate }}
      </button>
    </div>
  </ng-container>

  <!-- Video upload -->
  <div *ngIf="(candidate?.videoInterviewStatus === 'pending') || recordFinished">
    <div *ngIf="uploadInProgress" class="text-steps-interview">
      <h4 class="text-center">
        {{ 'recruitment.process.interview.video_upload_progress' | translate }} ({{uploadPercent}}%)
      </h4>
      <p>{{ 'recruitment.process.interview.dont_close_page' | translate }}</p>
    </div>
    <div *ngIf="!uploadInProgress" class="text-steps-interview">
      <h4 class="text-center">
        {{ 'recruitment.process.interview.interview_completed' | translate }}
      </h4>
      <p class="thanks-message-interview">
        {{ 'recruitment.process.interview.thanks_message' | translate }}
      </p>
    </div>
  </div>
</div>
