import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {PvReservationDataService} from "../../pv-modules/pv-reservation/services/pv-reservation-data.service";
import {MISSION_TYPE} from "../models/order.model";

@Pipe({
  name: 'missionTypeTranslate'
})
export class MissionTypeTranslatePipe implements PipeTransform {

  constructor(private reservationDataService: PvReservationDataService){}

  transform(value: MISSION_TYPE): string {
    const settings = this.reservationDataService.getSettings();
    if (settings) {
      const missionType = settings.missionTypes[value];
      if (missionType) {
        return missionType.name;
      }
    }

    switch (value) {
      case MISSION_TYPE.PICK_AND_DELIVERY:
        return 'pv_modules.pv_reservation.mission_type.pick_up_and_delivery';
      case MISSION_TYPE.PICKUP_AND_DELIVERY_SECTION:
        return 'pv_modules.pv_reservation.mission_type.pick_up_and_delivery_section';
      case MISSION_TYPE.LIVRAISON_AVEC_REPRISE:
        return 'pv_modules.pv_reservation.mission_type.livraison_avec_reprise';
      case MISSION_TYPE.DELIVERY:
        return 'pv_modules.pv_reservation.mission_type.delivery';
      case MISSION_TYPE.PICK_UP:
        return 'pv_modules.pv_reservation.mission_type.pick_up';
      case MISSION_TYPE.REPRISE:
        return 'pv_modules.pv_reservation.mission_type.reprise';
      case MISSION_TYPE.LIVRAISON:
        return 'pv_modules.pv_reservation.mission_type.livraison';
      case MISSION_TYPE.TRANSFER_ALLER_RETOUR:
        return 'pv_modules.pv_reservation.mission_type.transfer_aller_retour';
      case MISSION_TYPE.BUYBACK:
        return 'pv_modules.pv_reservation.mission_type.buy_back';
      case MISSION_TYPE.LIVRAISON_REPRISE_SECTION:
        return 'pv_modules.pv_reservation.mission_type.livraison_reprise_section';
      case MISSION_TYPE.TRANSFER:
        return 'pv_modules.pv_reservation.mission_type.transfer';
      case MISSION_TYPE.TRANSFER_RETOUR:
        return 'pv_modules.pv_reservation.mission_type.transfer_retour';
      case MISSION_TYPE.TRANSFERT_SECTION:
        return 'pv_modules.pv_reservation.mission_type.transfer_section';
      case MISSION_TYPE.COURTOISIE:
        return 'pv_modules.pv_reservation.mission_type.courtesy';
    }
    return null;
  }

}
