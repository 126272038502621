import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'pv-common-autocomplete-option',
  templateUrl: './common-autocomplete-option.component.html',
  styleUrls: ['./common-autocomplete-option.component.scss']
})
export class CommonAutocompleteOptionComponent implements OnInit {

  @Output('onItemSelect') itemSelected = new EventEmitter();
  @Input() isSelected: boolean;
  @Input() multiple: boolean;

  @ViewChild('checkboxContainer', {static: true}) checkboxContainer: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
  }

}
