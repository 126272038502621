import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Candidate} from '../../models/Candidate';
import {PvRecruitmentService} from '../../pv-recruitment.service';
import {UserIdentityCheck} from '../../models/UserIdentityCheck';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Valet} from '../../../../admin/modules/admin-valet/models/valet';
import {VALET_DOCUMENT_TYPE} from '../../../../common-modules/common-valet/models/valet-document';

@Component({
  selector: 'pv-recruitement-id-check-step',
  templateUrl: './recruitement-id-check-step.component.html',
  styleUrls: ['./recruitement-id-check-step.component.scss']
})
export class RecruitementIdCheckStepComponent implements OnInit {

  public files: {
    idDocFront?: File;
    idDocBack?: File;
    licenseFront?: File;
    licenseBack?: File;
  } = {};
  public initialInvalidSiret = false;
  public isSending = false;
  public isChecking = false;
  public idCheck: UserIdentityCheck = {
    idDocumentCheckStatus: null,
    idDocumentCheckRejectedComment: null,
    idVerificationCheckStatus: null,
    idVerificationCheckRejectedComment: null,
    idVerificationCheckUrl: null,
    siretStatus: null,
    siretErrorMessage: null,
    facePictureFile: null,
  };
  public form: FormGroup;

  private interval;

  @Input() candidate: Candidate;
  @Input() valet: Valet;

  @Output() candidateUpdated = new EventEmitter();

  constructor(private recruitmentService: PvRecruitmentService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      siret: [this.valet.siret || null, [Validators.required, Validators.pattern(/^\d{9}$|^\d{14}$/)]]
    });


    if (this.form.get('siret').invalid) {
      this.initialInvalidSiret = true;
      this.form.get('siret').markAsTouched();
    }

    this.refreshStatus().then(hasCheck => {
      if (hasCheck) {
        this.isChecking = true;
        this.interval = setInterval(() => {
          this.refreshStatus();
        }, 5000);
      }
    });
  }

  fileLoaded(file: File, side: 'idDocFront' | 'idDocBack' | 'licenseFront' | 'licenseBack') {
    this.files[side] = file;
  }

  startIdCheck(idDocumentCheck: boolean, idVerificationCheck: boolean, siretCheck: boolean) {
    if (idDocumentCheck && (!this.files.idDocFront || !this.files.idDocBack)) {
      return;
    }

    const siret = this.form.get('siret').value;
    if (siretCheck && !siret) {
      return;
    }
    this.valet.siret = siret;

    this.isChecking = true;
    this.isSending = true;
    this.initialInvalidSiret = false;
    this.recruitmentService.startIdCheck(
      this.files.idDocFront,
      this.files.idDocBack,
      siret,
      idDocumentCheck,
      idVerificationCheck,
      siretCheck
    ).then(() => {
      this.files.idDocFront = null;
      this.files.idDocBack = null;

      if (idDocumentCheck) {
        this.idCheck.idDocumentCheckStatus = null;
      }
      if (idVerificationCheck) {
        this.idCheck.idVerificationCheckStatus = null;
      }
      if (siretCheck) {
        this.idCheck.siretStatus = null;
        this.form.reset();
      }

      if (!this.interval) {
        this.interval = setInterval(() => {
          this.refreshStatus();
        }, 5000);
      }
    }).catch(error => {
      this.isChecking = false;
    }).finally(() => {
      this.isSending = false;
    });
  }

  async sendLicense() {
    if (!this.files.licenseFront || !this.files.licenseBack) {
      return;
    }
    this.isSending = true;
    try {
      await this.recruitmentService.updateCandidateDocument({
        documentType: VALET_DOCUMENT_TYPE.drivingLicense,
        url: this.files.licenseFront
      });
      await this.recruitmentService.updateCandidateDocument({
        documentType: VALET_DOCUMENT_TYPE.drivingLicenseVerso,
        url: this.files.licenseBack
      });
      this.files.licenseFront = null;
      this.files.licenseBack = null;
      this.refreshStatus();
    } catch (error) {
      console.error(error);
    }
    this.isSending = false;
  }

  refreshIdVerificationCheckUrl() {
    this.isSending = true;
    this.recruitmentService.refreshIdVerificationCheckUrl().then(data => {
      window.location.href = data.idVerificationCheckUrl;
    }).finally(() => {
      this.isSending = false;
    });
  }

  refreshStatus(): Promise<boolean> {
    return new Promise(((resolve, reject) => {
      this.recruitmentService.getIdCheckStatus().then(data => {
        if (data) {
          this.idCheck = data;
          if (
            this.interval &&
            data.idDocumentCheckStatus === 'VALID' &&
            data.idVerificationCheckStatus === 'VALID' &&
            data.siretStatus === 'VALID'
          ) {
            clearInterval(this.interval);
            setTimeout(() => {
              this.candidateUpdated.emit();
            }, 5000);
          }
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch(error => {
        reject(error);
      });
    }));
  }

}
