import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {UtilsHelper} from '../helpers/utils.helper';


export class CustomValidators {
  public static requiredIfKeyNotEmpty(key: string, errorLabel?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const keyToCheckHasValue = !UtilsHelper.IsNilOrEmpty(parent.get(key).value);
      const currentControlHasValue = !UtilsHelper.IsNilOrEmpty(control.value);

      if (keyToCheckHasValue && !currentControlHasValue) {
        return { required: parent.get(key).value, ...( errorLabel ? { errorLabel } : {} ) };
      }
      return null;
    }
  }

  public static requiredIfKeyEmpty(key: string, errorLabel?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const keyToCheckIsEmpty = UtilsHelper.IsNilOrEmpty(parent.get(key).value);
      const currentControlHasValue = !UtilsHelper.IsNilOrEmpty(control.value);
      if (keyToCheckIsEmpty && !currentControlHasValue) {
        return { required: parent.get(key).value, ...( errorLabel ? { errorLabel } : {} ) };
      }
      return null;
    }
  }

  public static requiredIfKeyTrue(key: string, errorLabel?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const keyToCheck = parent.get(key);
      if (!keyToCheck) {
        return null;
      }

      const keyToCheckValue = keyToCheck.value;
      const currentControlHasValue = control.value;
      if (keyToCheckValue === true && !currentControlHasValue) {
        return { required: true, ...( errorLabel ? { errorLabel } : {} ) };
      }

      return null;
    };
  }

  public static requiredIfAnyKeysEmpty(keys: string[], errorLabel?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parent = control.parent;
      if (!parent) {
        return null;
      }

      const someKeysToCheckAreEmpty = keys.some((key) => UtilsHelper.IsNilOrEmpty(parent.get(key).value))
      const currentControlHasValue = !UtilsHelper.IsNilOrEmpty(control.value);
      if (someKeysToCheckAreEmpty && !currentControlHasValue) {
        return { required: keys, ...( errorLabel ? { errorLabel } : {} ) };
      }
      return null;
    }
  }

  public static required(label: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const val = Validators.required(control);
      if (val) {
        return {
          errorLabel: label,
          ...{val}
        }
      }
    }
  }
}
