import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import * as fastDeepEqual from 'fast-deep-equal';
import {NbPopoverDirective} from '@nebular/theme';
import {DateFilter} from '../../../../submodule/model/date-filter.model';
import {DateFilterHelper} from "../../helpers/date-filter.helper";

@Component({
  selector: 'pv-date-filter-button',
  templateUrl: './date-filter-button.component.html',
  styleUrls: ['./date-filter-button.component.scss']
})
export class DateFilterButtonComponent implements OnInit, OnDestroy {
  public dateSelected$: BehaviorSubject<DateFilter> = new BehaviorSubject(null);
  public deleteSelection$: Subject<void> = new Subject();

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  @Input('filterData')
  set filterData(filterData: DateFilter | null) {
    this.dateSelected$.next(filterData);
  }

  @Output() dateSelected: EventEmitter<DateFilter> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.dateSelected$.pipe(
      distinctUntilChanged(fastDeepEqual),
      debounceTime(100),
      takeUntil(this.destroy$),
    ).subscribe(date => {
      this.dateSelected.emit(date);
      this.popover.hide();
    })

    this.deleteSelection$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(_ => {
      this.dateSelected$.next(null);
      this.dateSelected.emit(null);
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
