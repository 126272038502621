<nb-card class="slnk-card d-flex w-100"
         [class.h-100]="fullHeight"
         [ngClass]="cardColor" [ngStyle]="{'margin-bottom' : marginBottom }">
  <nb-card-header *ngIf="isHeaderDisplayed">
    <ng-content select="common-card-header">
    </ng-content>
  </nb-card-header>
  <nb-card-body *ngIf="isBodyDisplayed" style="z-index: 1;">
    <ng-content select="common-card-body">
    </ng-content>
  </nb-card-body>
  <nb-card-footer *ngIf="isFooterDisplayed">
    <ng-content select="common-card-footer">
    </ng-content>
  </nb-card-footer>
</nb-card>
