import * as moment from 'moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export enum DateFilterCustomValue {
  BEFORE = 'before',
  BETWEEN = 'between',
  EXACT = 'exact',
  AFTER = 'after',
}

export enum DateFilterUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum DateFilterValue {
  LAST = 'last',
  CURRENT = 'current',
  NEXT = 'next',
}

export const DateFilterCustomLabels: { [key in DateFilterCustomValue]: string } = {
  [DateFilterCustomValue.AFTER]: 'Après le ...',
  [DateFilterCustomValue.BETWEEN]: 'Du ... Au ...',
  [DateFilterCustomValue.BEFORE]: 'Avant le ...',
  [DateFilterCustomValue.EXACT]: 'Le ...',

}

export const DateFilterUnitLabels: { [key in DateFilterUnit]: string } = {
  [DateFilterUnit.DAY]: 'Jour',
  [DateFilterUnit.WEEK]: 'La semaine',
  [DateFilterUnit.MONTH]: 'Le mois',
  [DateFilterUnit.YEAR]: 'L\'année',
}
export interface DateFilterValueObject { value: DateFilterValue, label: string };

export const femDateFilterValues: DateFilterValueObject[] = [
  { value: DateFilterValue.LAST, label: 'Dernière' },
  { value: DateFilterValue.CURRENT, label: 'En cours' },
  { value: DateFilterValue.NEXT, label: 'Prochaine' }
]
export const masDateFilterValues: DateFilterValueObject[] = [
  { value: DateFilterValue.LAST, label: 'Dernier' },
  { value: DateFilterValue.CURRENT, label: 'En cours' },
  { value: DateFilterValue.NEXT, label: 'Prochain' }
]
export const dayDateFilterValues: DateFilterValueObject[] = [
  { value: DateFilterValue.LAST, label: 'Hier' },
  { value: DateFilterValue.CURRENT, label: 'Aujourd\'hui' },
  { value: DateFilterValue.NEXT, label: 'Demain' }
]
export type ValuesForUnit = { [key in DateFilterUnit]: DateFilterValueObject[] }
export const DateFilterValuesForUnit: ValuesForUnit = {
  [DateFilterUnit.DAY]: dayDateFilterValues,
  [DateFilterUnit.WEEK]: femDateFilterValues,
  [DateFilterUnit.MONTH]: masDateFilterValues,
  [DateFilterUnit.YEAR]: femDateFilterValues,
}

export const dateFilterValueToMomentAction: { [key in DateFilterValue]: (unit: DateFilterUnit) => any } = {
  [DateFilterValue.LAST]: (unit: DateFilterUnit) => moment().subtract(1, unit as DurationConstructor),
  [DateFilterValue.NEXT]: (unit: DateFilterUnit) => moment().add(1, unit as DurationConstructor),
  [DateFilterValue.CURRENT]: (_) => moment(),
}



