<nb-layout *ngIf="candidate">
  <nb-layout-column style="position: relative">
    <ng-container *ngIf="candidate.step !== Step.idCheck || candidate.profileStatus !== 'valid'">
      <h2 class="d-flex justify-content-center text-center">
        {{'recruitment.process.question_valet' | translate}}<br>
        {{'recruitment.process.follow_guide' | translate}}
      </h2>
    </ng-container>
    <div class="container-stepper-process" style="background-color: white">
      <div *ngIf="showArchived">
        <nb-alert outline="info">
          <div class="d-flex align-items-center">
            <div class="mr-3">
              <nb-icon icon="info-circle" status="info" style="font-size:2rem;"></nb-icon>
            </div>
            <div class="d-flex flex-column">
              <span>
                {{'recruitment.process.archived_candidate' | translate}}
              </span>
            </div>
          </div>
        </nb-alert>
      </div>
      <nb-stepper #stepper
                  *ngIf="showStepper"
                  [ngClass]="{'stepper-hide-header': candidate.step === Step.idCheck && candidate.profileStatus === 'valid'}"
                  [selectedIndex]="stepperIndex"
                  [disableStepNavigation]="true">
        <nb-step label="Parcours">
          <pv-recruitement-tutorial-step
            [candidate]="candidate"></pv-recruitement-tutorial-step>
          <div>
            <button *ngIf="candidate.eligibilityStatus === 'valid'" nbButton (click)="nextStep()" class="yellow-step-button first-next-step">{{'recruitment.process.next' | translate}}</button>
          </div>
        </nb-step>
        <nb-step label="Entretien" #interviewStep>
          <pv-recruitement-interview-step *ngIf="stepper.isStepSelected(interviewStep)"
            [candidate]="candidate">
          </pv-recruitement-interview-step>
        </nb-step>
        <nb-step label="Identité">
          <pv-recruitement-id-check-step
            [candidate]="candidate"
            [valet]="candidateUser"
            (candidateUpdated)="reloadUserData()">
          </pv-recruitement-id-check-step>
          <div>
            <button *ngIf="candidate.idCheckStatus === 'valid' && candidate.profileStatus === 'missing'"
                    nbButton
                    (click)="nextStep(true)"
                    class="yellow-step-button first-next-step">
              {{'recruitment.process.next' | translate}}
            </button>
          </div>
        </nb-step>
        <nb-step label="Profil">
          <div class="profile-component-container">
            <pv-common-valet-profile-form
              [isCreatingNew]="false"
              [viewAs]="USER_ROLE.CANDIDATE"
              [valet]="candidateUser"
              [hideSubmitButton]="true"
              #profileForm>
            </pv-common-valet-profile-form>
          </div>
          <div class="recruitment-steps-buttons-container">
            <button nbButton (click)="previousStep()" class="yellow-step-button previous-step">{{'recruitment.process.previous' | translate}}</button>
            <button nbButton type="submit" (click)="submitProfile()" class="yellow-step-button next-step">{{'recruitment.process.next' | translate}}</button>
          </div>
        </nb-step>
        <nb-step label="Contrat">
          <div class="contract-component-container">
            <pv-admin-valet-documents
              *ngIf="candidate?.documentsStatus !== 'valid'"
              (documentUpdated)="updateDocument($event)"
              [documentsData]="documents"
              [viewAs]="USER_ROLE.CANDIDATE"
              [candidate]="candidate"
              [hideContract]="true"
              [valet]="candidateUser"></pv-admin-valet-documents>
          </div>
          <div class="recruitment-contract-status-message">
            <div *ngIf="candidate?.documentsStatus === 'missing' && candidate?.contractStatus === 'missing'">
              <nb-alert outline="info">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <nb-icon icon="info-circle" status="info" style="font-size:2rem;"></nb-icon>
                  </div>
                  <div class="d-flex flex-column">
                    <span>
                      {{'recruitment.process.request_documents' | translate}}
                    </span>
                  </div>
                </div>
              </nb-alert>
              <nb-alert outline="warning">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <nb-icon icon="pause-circle" status="warning" style="font-size:2rem;"></nb-icon>
                  </div>
                  <div class="d-flex flex-column">
                    <span>
                      {{'recruitment.process.documents_missing' | translate}}
                    </span>
                  </div>
                </div>
              </nb-alert>
            </div>
            <div *ngIf="candidate?.documentsStatus === 'pending' && candidate?.contractStatus === 'missing'">
              <nb-alert outline="success">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <nb-icon icon="check-circle" status="success" style="font-size:2rem;"></nb-icon>
                  </div>
                  <div class="d-flex flex-column">
                    <span>
                      {{'recruitment.process.documents_pending_info' | translate}}
                    </span>
                  </div>
                </div>
              </nb-alert>
<!--              <nb-alert outline="warning">-->
<!--                <div class="d-flex align-items-center">-->
<!--                  <div class="mr-3">-->
<!--                    <nb-icon icon="pause-circle" status="warning" style="font-size:2rem;"></nb-icon>-->
<!--                  </div>-->
<!--                  <div class="d-flex flex-column">-->
<!--                    <span>-->
<!--                      {{'recruitment.process.documents_pending' | translate}}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </nb-alert>-->
            </div>
            <div *ngIf="candidate?.documentsStatus === 'valid' && candidate?.contractStatus === 'missing'">
              <nb-alert outline="info">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <nb-icon icon="info-circle" status="info" style="font-size:2rem;"></nb-icon>
                  </div>
                  <div class="d-flex flex-column">
                    <span>
                      {{'recruitment.process.request_contract' | translate}}
                    </span>
                  </div>
                </div>
              </nb-alert>
              <nb-alert outline="info">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <nb-icon icon="info-circle" status="info" style="font-size:2rem;"></nb-icon>
                  </div>
                  <div class="d-flex flex-column">
                    <span>
                      {{'recruitment.process.request_patience' | translate}}
                    </span>
                  </div>
                </div>
              </nb-alert>
<!--              <nb-alert outline="success">-->
<!--                <div class="d-flex align-items-center">-->
<!--                  <div class="mr-3">-->
<!--                    <nb-icon icon="check-circle" status="success" style="font-size:2rem;"></nb-icon>-->
<!--                  </div>-->
<!--                  <div class="d-flex flex-column">-->
<!--                    <span>-->
<!--                      {{'recruitment.process.documents_valid' | translate}}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </nb-alert>-->
              <nb-alert outline="warning">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <nb-icon icon="pause-circle" status="warning" style="font-size:2rem;"></nb-icon>
                  </div>
                  <div class="d-flex flex-column">
                    <span>
                      {{'recruitment.process.contract_missing' | translate}}
                    </span>
                  </div>
                </div>
              </nb-alert>
            </div>
            <nb-alert outline="success" *ngIf="candidate?.documentsStatus === 'valid' && candidate?.contractStatus === 'valid'">
              <div class="d-flex align-items-center">
                <div class="mr-3">
                  <nb-icon icon="check-circle" status="success" style="font-size:2rem;"></nb-icon>
                </div>
                <div class="d-flex flex-column">
                  <span>
                    {{'recruitment.process.contract_valid' | translate}}
                  </span>
                  <span>
                    <a href="https://admin.popvalet.com/">https://admin.popvalet.com/</a>
                  </span>
                </div>
              </div>
            </nb-alert>
          </div>
          <div class="recruitment-steps-buttons-container">
            <button *ngIf="candidate?.contractStatus !== 'valid'" nbButton (click)="previousStep()" class="yellow-step-button previous-step">{{'recruitment.process.previous' | translate}}</button>
            <button *ngIf="candidate?.contractStatus === 'valid'" nbButton (click)="nextStep()" class="yellow-step-button next-step">{{'recruitment.process.next' | translate}}</button>
          </div>
        </nb-step>
        <nb-step label="Webinar">
          <pv-recruitement-formation-step
            *ngIf="candidateUser?.role === USER_ROLE.VALET"
            [candidateUser]="candidateUser"
            [candidate]="candidate"
            (candidateUpdated)="updateCandidateData($event)"
            (sessionSetError)="reloadUserData()">
          </pv-recruitement-formation-step>
        </nb-step>
      </nb-stepper>
    </div>
  </nb-layout-column>
</nb-layout>
