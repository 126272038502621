<common-card style="width: 500px">
    <common-card-header>
        <div class="header-content">
            <h6 class="font-weight-bold mt-2 mb-2">{{ 'modules.anomaly.treat_dialog_accident.title' | translate }}</h6>
            <button nbButton ghost class="p-0" (click)="closeDialog()">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </common-card-header>
    <common-card-body>
        <ng-container [formGroup]="form">
            <div>
                <b>{{ 'modules.anomaly.treat_dialog_accident.quotations' | translate }}</b>
                <div class="toggle-container"></div>
                <nb-select
                         size="small"
                         formControlName="quotation"
                         fullWidth
                         class="col-9 p-0 mt-2">
                <nb-option *ngFor="let quote of this.anomaly?.quotations"
                           [value]="quote.filename">
                  {{quote.filename}}
                </nb-option>
              </nb-select>
            </div>
            <div class="mt-3">
                <b>{{ 'modules.anomaly.treat_dialog_accident.insurance' | translate }}</b>
                <div class="toggle-container">
                    <p class="mr-2 pb-2">{{ 'modules.anomaly.treat_dialog_accident.no' | translate }}</p>
                    <nb-toggle formControlName="insurance" status="danger"></nb-toggle>
                    <p class="ml-2 pb-2">{{ 'modules.anomaly.treat_dialog_accident.yes' | translate }}</p>
                </div>
            </div>
            <div *ngIf="!insurance" class="mt-1">
                <b>{{ 'modules.anomaly.treat_dialog_accident.responsible' | translate }}</b>
                <div class="toggle-container">
                    <p class="mr-2 pb-2">{{ 'modules.anomaly.treat_dialog_infraction.no' | translate }}</p>
                    <nb-toggle formControlName="valetResponsible" status="danger"></nb-toggle>
                    <p class="ml-2 pb-2">{{ 'modules.anomaly.treat_dialog_infraction.yes' | translate }}</p>
                </div>
                <!-- <div>
                    <button nbButton (click)="docsInput.click()" class="mt-2">
                        <span>{{ 'modules.anomaly.treat_dialog_infraction.choose_docs' | translate }}</span>
                    </button>
                    <input class="invisible_drop"
                            #docsInput type="file"
                            [multiple]="true"
                            [accept]="acceptedFileTypes"
                            (change)="uploadFiles($event)">
                    <div *ngFor="let doc of this.form.get('docs').value">
                        <div *ngIf="doc?.name" class="progress-label mt-2 p-2">
                            <div class="ml-2">{{doc.name}}</div>
                            <div>
                                <nb-icon icon="times" (click)="deleteFile(doc.name)" class="ml-3"></nb-icon>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div *ngIf="valetResponsible && !insurance" class="mt-1">
                <common-card>
                    <common-card-body>
                        <div class="row align-items-center">
                            <nb-icon icon="info" class="ml-3"></nb-icon>
                            <div class="ml-3 description">{{ 'modules.anomaly.treat_dialog_accident.warning' | translate }}</div>
                        </div>
                    </common-card-body>
                </common-card>
            </div>
            <div *ngIf="!insurance" class="mt-3">
                <b>{{ 'modules.anomaly.treat_dialog_accident.validation' | translate }}</b>
                <div class="toggle-container">
                    <p class="mr-2 pb-2">{{ 'modules.anomaly.treat_dialog_accident.we_proov' | translate }}</p>
                    <nb-toggle formControlName="quotationValid" status="danger"></nb-toggle>
                    <p class="ml-2 pb-2">{{ 'modules.anomaly.treat_dialog_accident.valid' | translate }}</p>
                </div>
            </div>
            <div *ngIf="!quotationValid && !insurance" class="mt-0">
                <b>{{ 'modules.anomaly.treat_dialog_accident.we_proov_link' | translate }}</b>
                <input nbInput
                    fullWidth
                    formControlName="weproovLink" class="mt-2">
            </div>

            <div *ngIf="insurance" class="mt-0">
                <b>{{ 'modules.anomaly.treat_dialog_accident.recipients' | translate }}</b>
                <div *ngFor="let email of this.form.get('emails').value">
                    <div class="progress-label mt-2 p-2">
                        <div class="ml-2 mt-1">{{email}}</div>
                        <div>
                            <nb-icon icon="times" (click)="deleteEmail(email)" class="ml-3 mt-1"></nb-icon>
                        </div>
                    </div>
                </div>
                <div class="mt-2 inputRow">
                    <input nbInput
                    formControlName="email">
                    <button nbButton (click)="addEmail()" class="addButton">
                        {{ 'modules.anomaly.treat_dialog_accident.add_recipient' | translate }}
                    </button>
                </div>
            </div>
            <div *ngIf="insurance" class="mt-3">
                <b>{{ 'modules.anomaly.treat_dialog_accident.message' | translate }}</b>
                <textarea nbInput
                    fullWidth
                    formControlName="message"
                    rows="4"
                    class="autoresize mt-2">
                </textarea>
            </div>
            <div *ngIf="insurance" class="mt-3">
                <b>{{ 'modules.anomaly.treat_dialog_accident.files' | translate }}</b>
                <input class="invisible_drop"
                        #docsInput type="file"
                        [multiple]="true"
                        [accept]="acceptedFileTypes"
                        (change)="uploadFiles($event)">
                <div *ngFor="let doc of this.form.get('files').value">
                    <div *ngIf="doc?.name" class="progress-label mt-2 p-2">
                        <div class="ml-2 mt-1">{{doc.name}}</div>
                        <div>
                            <nb-icon icon="times" (click)="deleteFile(doc.name)" class="ml-3 mt-1"></nb-icon>
                        </div>
                    </div>
                    <div *ngIf="doc?.filename" class="progress-label mt-2 p-2">
                        <div class="ml-2 mt-1">{{doc.filename}}</div>
                    </div>
                </div>
                <div>
                    <button nbButton (click)="docsInput.click()" class="mt-2">
                        <span>{{ 'modules.anomaly.treat_dialog_accident.add_files' | translate }}</span>
                    </button>
                </div>
            </div>
        </ng-container>
    </common-card-body>
    <common-card-footer>
        <div class="text-center">
            <button nbButton type="button" status="primary" class="pt-2 pb-2" (click)="submit()" [disabled]="submitDisabled">
                {{ 'modules.anomaly.treat_dialog_accident.send' | translate }}
            </button>
        </div>
    </common-card-footer>
</common-card>