import { Component, OnInit } from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {selectAuthState, UserState} from '../../../shared/store/user/auth.state';
import {Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LogIn} from '../../../shared/store/user/auth.actions';
import {PvRecruitmentService} from '../pv-recruitment.service';

@Component({
  selector: 'pv-pv-recruitment-dialog-reactivate-account',
  templateUrl: './pv-recruitment-dialog-reactivate-account.component.html',
  styleUrls: ['./pv-recruitment-dialog-reactivate-account.component.scss']
})
export class PvRecruitmentDialogReactivateAccountComponent implements OnInit {
  public dialogStep = 0;
  public errors = {};
  public loginForm: FormGroup;
  public resetForm: FormGroup;
  public disableSubmit = false;

  constructor(private fb: FormBuilder,
              protected dialogRef: NbDialogRef<PvRecruitmentDialogReactivateAccountComponent>,
              private store: Store<UserState>,
              private recruitmentService: PvRecruitmentService) { }

  ngOnInit(): void {
    this.initLoginForm();
    this.initResetForm();
    this.store.select(selectAuthState).subscribe(res => {
      this.errors = res.error;
      this.loginForm.setErrors(this.errors);
    });
    this.dialogStep = 0
  }

  private initLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required/*, Validators.pattern(GLOBAL_SETTINGS.patterns.email)*/]],
      password: ['', Validators.required],
      rememberMe: [true],
    });
  }

  private initResetForm() {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required]],
    });
  }

  public forgottenPassword() {
    this.dialogStep = this.dialogStep + 1;
  }

  public returnToLogin() {
    this.dialogStep = this.dialogStep - 1;
  }

  public resetPassword() {
    const rawValues = this.resetForm.getRawValue();
    this.recruitmentService.forgotPassword(rawValues.email).then(() => {
      this.disableSubmit = true;
    });
  }

  public submit() {
    const payload = {
      redirectUrl: '/recruitment-process',
      ...this.loginForm.getRawValue()
    };
    this.store.dispatch(
      new LogIn(payload)
    );

    // To improve
    this.store.select((a) => {
      return a;
    }).subscribe(val => {
      if ((val as any)?.user?.error === false) {
        this.closeDialog();
      }
    });
  }

  nextStep() {
    this.dialogStep  = this.dialogStep + 1;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
