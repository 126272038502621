import { Injectable } from '@angular/core';
import {ExportToCsv, Options} from 'export-to-csv';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ExportCsvService {
  public exportDateHoursFormat = 'DD-MM-YYYY HH:mm:ss';

  constructor() {
  }

  export(data: any[]) {
    const options: Options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: false,
      showTitle: false,
      useTextFile: false,
      useKeysAsHeaders: true,
      filename: moment().format('YYYYMMDD') + ' Export Pop Valet'
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

  }
}
