import { Action } from '@ngrx/store';
import { AuthSuccessLoginPayload } from '../user/auth.actions';
import { City } from './city.state';

export enum CityActionTypes {
  GET_CITIES = '[City] Get',
  GET_CITIES_SUCCESS = '[City] Get success',
}

export class GetCities implements Action {
  readonly type = CityActionTypes.GET_CITIES;
}

export class GetCitiesSuccess implements Action {
  readonly type = CityActionTypes.GET_CITIES_SUCCESS;

  constructor(public payload: City[]) {
  }
}

export type ALL = GetCities;
