import {Pipe, PipeTransform} from '@angular/core';
import {BatchHelper} from '../helpers/batch.helper';

@Pipe({
  name: 'getPrice'
})
export class GetPrice implements PipeTransform {

  transform(batch: any): number {
    return BatchHelper.getPrice(batch);
  }

}

@Pipe({
  name: 'getCost'
})
export class GetCost implements PipeTransform {

  transform(batch: any): number {
    return BatchHelper.getCost(batch);
  }

}
