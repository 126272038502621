<nb-icon
  [nbPopover]="addressExplanationTemplate"
  class="cursor-pointer info-color"
  nbPopoverTrigger="click"
  icon="question-mark-circle-outline"
  status="info"
  pack="eva">
</nb-icon>

<ng-template #addressExplanationTemplate>
  <nb-card class="container">
    <nb-card-header>
      <p>Pour le bon fonctionnement de nos processus et pour faciliter le travail de nos valets, nous avons besoin d'<strong>adresses complètes.</strong></p>
      <p>Une adresse complète se caractérise par un <strong>numéro</strong> et un <strong>type de voie</strong>, <strong>ou bien</strong> par le <strong>nom de l'établissement</strong>, ainsi que par un <strong>pays</strong>, une <strong>ville</strong> et un <strong>code postal</strong>.</p>
    </nb-card-header>
    <nb-card-body>
      <p>Voici quelques exemples corrects et leur équivalent incorrect :</p>
      <p><ng-container *ngTemplateOutlet="addressExemple; context: {correct: 'Ford Morlaix, Route de Paris, 29600 Morlaix, France', incorrect: 'Route de Paris, 29600 Morlaix, France'}"></ng-container></p>
      <p><ng-container *ngTemplateOutlet="addressExemple; context: {correct: '22 RUE DU MARCADE 65700 LAFITOLE FRANCE', incorrect: 'RUE DU MARCADE 65700 LAFITOLE FRANCE'}"></ng-container></p>
      <p><ng-container *ngTemplateOutlet="addressExemple; context: {correct: 'Carrosserie Mantel, 630, D60, 62217 Beaurains, France', incorrect: '630, D60, 62217 Beaurains, France'}"></ng-container></p>
    </nb-card-body>
    <nb-card-footer>
      <p>Merci de bien valider votre sélection en cliquant dessus.</p>
      <img class="gif" src="assets/images/address-helper.gif" alt="">
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #addressExemple let-correct="correct" let-incorrect="incorrect">
  <div class="d-flex">
    <nb-icon
      class="mr-2"
      icon="checkmark-circle-2-outline"
      status="success"
      pack="eva">
    </nb-icon>
    {{ correct }}
  </div>
  <div class="d-flex">
    <nb-icon
      class="mr-2"
      icon="close-circle-outline"
      status="danger"
      pack="eva">
    </nb-icon>
    {{ incorrect }}
  </div>
</ng-template>
