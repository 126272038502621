import { createFeatureSelector } from '@ngrx/store';

export interface City {
  name: string;
  id: string;
}


export const citiesInitialState = [];

export const selectCities = createFeatureSelector<City[]>('cities');
