import {
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  Input, OnDestroy,
  OnInit,
  Output,
  QueryList, TemplateRef, TrackByFunction,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { CommonAutocompleteOptionComponent } from '../common-autocomplete-option/common-autocomplete-option.component';
import { FormControl } from '@angular/forms';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {CommonAutocompleteItem} from '../../common-autocomplete.component';
import {Subject} from 'rxjs';

@Component({
  selector: 'pv-common-autocomplete-option-list',
  templateUrl: './common-autocomplete-option-list.component.html',
  styleUrls: ['./common-autocomplete-option-list.component.scss']
})
export class CommonAutocompleteOptionListComponent implements OnInit, OnDestroy {
  public searchControl: FormControl;
  public searchText = '';
  public focusedElementIndex = -1;
  private destroy$: Subject<void> = new Subject<void>();
  @Input() displayTemplate: TemplateRef<any>;
  @Input() isLoading: boolean;
  @Input() selectItems: CommonAutocompleteItem[];
  @Input() multiple = false;
  @Input() selectedItems: Set<string>;
  @Input() newButton = false;
  @Input() deselectEntitiesButton = false;
  @Input() newButtonText = '';
  @Input() dropdown: any;
  @ViewChildren(CommonAutocompleteOptionComponent) options: QueryList<CommonAutocompleteOptionComponent>;
  @ViewChild('searchTextInput', {static: true}) searchInput: ElementRef;

  @Output('onItemSelect') onItemSelect = new EventEmitter();
  @Output('onItemsSelect') onItemsSelect = new EventEmitter();
  @Output() newButtonClicked = new EventEmitter();
  @Output() bottomReached = new EventEmitter();
  @Output() searchChanged = new EventEmitter();

  public trackById: TrackByFunction<CommonAutocompleteItem> = (_, item) => item.value;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.searchControl = new FormControl('');
    this.subscribeFilterTextChange();
  }

  clearSelectedItems() {
    this.selectedItems.clear()
  }

  subscribeFilterTextChange() {
    this.searchControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(200)
    ).subscribe(change => {
      this.searchText = change;
      this.searchChanged.emit(this.searchText);
      this.focusedElementIndex = -1;
    });
  }

  itemSelected(event, item) {
    this.onItemSelect.emit({
      item,
      selected: event
    });
  }

  public focusSearchInput() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0);
  }

  selectAllChildren(group) {
    console.log(group);
    this.onItemsSelect.emit({
      items: group.children,
      selected: true
    });
  }

  onNewButtonClicked() {
    this.newButtonClicked.emit();
  }

  @HostListener('window:keyup', ['$event'])
  keyEventUp(event: KeyboardEvent) {
    if (!this.dropdown || !this.dropdown.showing) {
      return;
    }
    switch (event.code) {
      case 'ArrowDown':
        if (this.focusedElementIndex <= this.selectItems.length) {
          this.focusedElementIndex++;
          this.scrollToFocusedElement();
        }
        break;
      case 'ArrowUp':
        if (this.focusedElementIndex > 0) {
          this.focusedElementIndex--;
          this.scrollToFocusedElement();
        }
        break;
      case 'Enter':
        if (this.focusedElementIndex >= 0 && this.focusedElementIndex < this.selectItems.length) {
          this.itemSelected(true, this.selectItems[this.focusedElementIndex]);
          this.cdr.detectChanges();
        }
        break;
    }
  }

  private scrollToFocusedElement() {
    if (this.focusedElementIndex >= 0 && this.focusedElementIndex < this.selectItems.length) {
      const option = this.options.find((item, index) => index === this.focusedElementIndex);
      option.checkboxContainer.nativeElement.scrollIntoView({block: 'nearest', inline: 'start'});
    }
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 20) {
      this.bottomReached.emit();
    }
  }

}
