import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PvRecruitmentComponent} from './pv-recruitment.component';
import {PvRecruitmentProcessComponent} from './pv-recruitment-process/pv-recruitment-process.component';
import {PvRecruitmentRegistrationComponent} from './pv-recruitment-registration/pv-recruitment-registration.component';

const routes: Routes = [
  {
    path: '',
    component: PvRecruitmentComponent,
  },
  {
    path: 'inscription',
    component: PvRecruitmentRegistrationComponent,
  },
  {
    path: 'recruitment-process',
    component: PvRecruitmentProcessComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PvRecruitmentRoutingModule { }
