import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'pv-shared-input-phone-number',
  templateUrl: './shared-input-phone-number.component.html',
  styleUrls: ['./shared-input-phone-number.component.scss']
})
export class SharedInputPhoneNumberComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() placeholder: string | undefined;
  @Input() required: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  removeSpacesPhoneNumber() {
    this.formGroup.get(this.controlName).setValue(this.formGroup.get(this.controlName).value.trim())
  }

}
