import { Component, Input, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { RoutingStatesService } from '../../../../shared/services/routing-states.service';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'common-card-header',
  templateUrl: './common-card-header.component.html',
  styleUrls: ['./common-card-header.component.scss']
})
export class CommonCardHeaderComponent implements OnInit {

  public defaultBackButtonRoute: string;
  public queryParams = {};
  @Input() showBackButton: boolean;

  @Input('defaultBackButtonRoute')
  set defaultBackButtonRouteData(defaultBackButtonRoute: string) {
    if (defaultBackButtonRoute) {
      const route = defaultBackButtonRoute.split('?');
      this.defaultBackButtonRoute = route[0];
      if (route[1]) {
        this.queryParams = this.parseQueryParams(route[1]);
      } else {
        this.queryParams = {};
      }
    }
  }

  constructor(private router: Router, private routingState: RoutingStatesService) {
  }

  ngOnInit() {
    // TODO: REFACTOR AND ADD BACK BUTTON TO CARD
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
      // console.log('AAA');
      // console.log(e[0].urlAfterRedirects); // previous url
    });
  }

  parseQueryParams(queryString: string): any {
    const query = {};
    const queries = queryString.split('&');
    queries.forEach(queryItem => {
      const queryKeyVal = queryItem.split('=');
      query[queryKeyVal[0]] = queryKeyVal[1];
    });
    return query;
  }

  back() {
    this.router.navigate([this.defaultBackButtonRoute], {
      queryParams: this.queryParams
    });
  }
}
