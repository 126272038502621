<nb-card class="tracking-main-info-progress-card">
  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="col-1">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="tracking-progress-circle-step"
               [ngStyle]="{'background-color': (trackingStatus === 'confirmed') || (trackingStatus === 'in-progress') || (trackingStatus === 'done') ? 'limegreen' : '#c4c4c4'}"></div>
          <span class="tracking-progress-bar-step-title mt-1">{{'tracking_link.recovery' | translate }}</span>
        </div>
      </div>
      <div class="col-3 tracking-progress-bar">
        <div class="tracking-progress-bar-fill"
             [ngStyle]="{'width': firstBarProgress + '%'}">
        </div>
      </div>
      <div class="col-1">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="tracking-progress-circle-step"
               [ngStyle]="{'background-color': (trackingStatus === 'in-progress') || (trackingStatus === 'done') ? 'limegreen' : '#c4c4c4'}"></div>
          <span class="tracking-progress-bar-step-title mt-1">{{'tracking_link.delivery' | translate }}</span>
        </div>
      </div>
      <div class="col-3 tracking-progress-bar">
        <div class="tracking-progress-bar-fill"
             [ngStyle]="{'width': secondBarProgress + '%'}">
        </div>
      </div>
      <div class="col-1">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img *ngIf="trackingStatus !== 'done'"
               class="tracking-progress-circle-third-step"
               src="assets/images/pin-in-progress.png" alt="">
          <img *ngIf="trackingStatus === 'done'"
               class="tracking-progress-circle-third-step"
               src="assets/images/pin-done.png" alt="">
          <span class="tracking-progress-bar-step-title"
                style="position: relative"
                [ngStyle]="{'bottom': trackingStatus !== 'done' ? '3px' : '1px'}">{{'tracking_link.delivered' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</nb-card>
