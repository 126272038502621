import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NbDialogRef} from "@nebular/theme";

@Component({
  selector: 'pv-common-valet-invoicing-block-dialog',
  templateUrl: './common-valet-invoicing-block-dialog.component.html',
  styleUrls: ['./common-valet-invoicing-block-dialog.component.scss']
})
export class CommonValetInvoicingBlockDialogComponent implements OnInit {

  @Output() submitEvent = new EventEmitter();

  public form: FormGroup;
  public reasons = [
    {value: 'process', name: 'Manquements de process répétitifs'},
    {value: 'speeding', name: 'Grand excès de vitesse'},
    {value: 'delay', name: 'Retards répétitifs'},
    {value: 'behavior', name: 'Comportement inapproprié'},
    {value: 'withdrawal', name: 'Absences / Plantages répétitifs'},
    {value: 'hijacking', name: 'Détournement'},
    {value: 'accident', name: 'Sinistre'},
    {value: 'other', name: 'Autre'},
  ];

  constructor(protected dialogRef: NbDialogRef<CommonValetInvoicingBlockDialogComponent>,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      invoicingBlockReason: [null, Validators.required],
      invoicingBlockComment: [null, Validators.required]
    });
  }

  cancel() {
    this.submitEvent.emit();
  }

  submit() {
    this.submitEvent.emit(this.form.getRawValue());
  }

}
