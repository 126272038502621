import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {NbDialogRef} from '@nebular/theme';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'pv-common-news-dialog',
  templateUrl: './common-news-dialog.component.html',
  styleUrls: ['./common-news-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonNewsDialogComponent implements OnInit {

  @Input() newsletters: any;
  @Output() submitEvent = new EventEmitter();

  constructor(private authService: AuthService,
              private sanitizer: DomSanitizer,
              protected dialogRef: NbDialogRef<CommonNewsDialogComponent>) { }

  ngOnInit(): void {
    this.trustContent(this.newsletters);
  }

  markAsRead(newsletter) {
    this.authService.setNewsletterAsRead(newsletter.id).then(() => {
      this.authService.getNewsletters().then(newsletters => {
        if (newsletters.length > 0) {
          this.trustContent(newsletters);
          this.newsletters = newsletters;
        } else {
          this.dialogRef.close();
        }
      });
    });
  }

  private trustContent(newsletters: any[]) {
    for (const newsletter of newsletters) {
      newsletter.content = this.sanitizer.bypassSecurityTrustHtml(newsletter.content);
    }
  }
}
