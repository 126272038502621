import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valetHasBlockedInvoicing'
})
export class ValetHasBlockedInvoicingPipe implements PipeTransform {

  transform(log: any): boolean {
    return log.action.includes('blockRemuneration') || log.action.includes('blockExpenses');
  }

}
