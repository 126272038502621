import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { USER_ROLE } from '../models/user';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private auth: AuthService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isClient();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.isClient();
  }

  isClient(): Observable<boolean> | Promise<boolean> | boolean {

    return this.auth.getRole().pipe(
      map(role => {
        console.log(role);
        if (role === USER_ROLE.CUSTOMER_ADMIN) {
          return true;
        }
        if (role === USER_ROLE.CUSTOMER_USER) {
          return true;
        }
        if (role === USER_ROLE.CUSTOMER_READ_ONLY) {
          return true;
        }
        return false;
      })
    );
  }
}
