import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFileUploadComponent } from './components/common-file-upload/common-file-upload.component';
import {NbIconModule} from '@nebular/theme';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [CommonFileUploadComponent],
  exports: [
    CommonFileUploadComponent
  ],
  imports: [
    CommonModule,
    NbIconModule,
    TranslateModule
  ]
})
export class CommonFileUploadModule { }
