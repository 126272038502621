import {Injectable} from '@angular/core';
import {NbGlobalPhysicalPosition, NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastHandlerService {
  constructor(private toastrService: NbToastrService,
              private translate: TranslateService) {
  }

  public showErrorToasts(message: string, title?: string, duration?: number): any {
    this.toastrService.danger(message,
      title ? title : this.translate.instant('global.toasts.error'), {
        duration: duration ?? 8000
      }
    );
  }

  /**
   * show main response error and all validation errors
   * @param error http error
   */
  public showErrorToastsResponse(error: HttpErrorResponse): any {
    console.log(error);
    if (error && error.error) {
      this.toastrService.danger(
        error.error.message, this.translate.instant('global.toasts.error'), {
          duration: 8000
        }
      );
    }
  }

  public showValidationErrors(error: HttpErrorResponse): any {
    let errorsObject = {};
    if (error && error.error && error.error.errors) {
      errorsObject = error.error.errors;
      Object.keys(error.error.errors).forEach(key => {
        this.toastrService.danger(
          error.error.errors[key],
          this.translate.instant('global.toasts.error')
        );
      });
    } else {
      this.toastrService.danger(
        this.translate.instant('global.toasts.server_error'),
        this.translate.instant('global.toasts.error')
      );
    }
    return errorsObject;
  }

  public showSuccessToast(message: string, title?: string, duration?: number) {
    this.toastrService.success(
      message,
      title ? title : this.translate.instant('global.toasts.success'), {
        duration: duration ?? 8000
      }
    );
  }

  public showReloadToast() {
    const toastRef: any = this.toastrService.show(
      this.translate.instant('global.toasts.new_version.message'),
      this.translate.instant('global.toasts.new_version.title'),
      {
        position: NbGlobalPhysicalPosition.TOP_RIGHT,
        duration: 0,
        status: 'basic',
        icon: ''
      }
    );
    toastRef.toastContainer.nativeElement.addEventListener('click', () => {
      location.reload(true);
    });
  }

}
