import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pv-pv-recruitment-common-footer',
  templateUrl: './pv-recruitment-common-footer.component.html',
  styleUrls: ['./pv-recruitment-common-footer.component.scss']
})
export class PvRecruitmentCommonFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
