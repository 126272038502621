<div class="footer">
  <div class="footer-1">
    <div class="overlap-group-23">
      <div class="flex-row-1">
        <div class="flex-col">
          <div class="logo-adresse">
            <img class="pop_logotype_rvb" src="assets/images/pop-logotype-rvb-3@2x.png" alt="pop_logotype_rvb" />
          </div>
          <div class="avis">
            <div class="etoiles">
              <div class="group-7-13"><div class="text-1 isidorasansalt-semi-bold-white-14px">{{'recruitment.home.footer.review' | translate}}</div></div>
              <div class="group-60-2">
                <div class="group-61-26">
                  <img
                    class="solid-favorite-1"
                    src="assets/images/solid---favorite-1@2x.png"
                    alt="Solid / favorite"
                  />
                  <img
                    class="solid-favorite-1 variant-1"
                    src="assets/images/solid---favorite-1@2x.png"
                    alt="Solid / favorite"
                  />
                  <img
                    class="solid-favorite-1 variant-2"
                    src="assets/images/solid---favorite-1@2x.png"
                    alt="Solid / favorite"
                  />
                  <img
                    class="solid-favorite-1 variant-3"
                    src="assets/images/solid---favorite-1@2x.png"
                    alt="Solid / favorite"
                  />
                  <img
                    class="solid-favorite-1 variant-4"
                    src="assets/images/solid---favorite-1@2x.png"
                    alt="Solid / favorite"
                  />
                </div>
              </div>
            </div>
            <div class="group-61-4">
              <p class="x2-800-avis-clients-2 montserrat-normal-white-10px">{{'recruitment.home.footer.clients_reviews' | translate}}</p>
            </div>
          </div>
        </div>
        <div class="aide-contact-questio blogtextbold">
          <span class="span-13 blogtextbold">Aide<br /></span>
          <span class="span-14 montserrat-normal-white-14px">
                    <a href="https://www.popvalet.com/popvalet-general-contact">Contact</a><br />
                    <a href="https://www.popvalet.com/popvalet-general-assurance">Assurance</a><br />
                    <a href="https://www.popvalet.com/popvalet-general-cgu">CGU</a><br />
                  </span>
        </div>
        <div class="nos-services-transpo blogtextbold">
          <span class="span-13 blogtextbold">Nos Services<br /></span>
          <span class="span-14 montserrat-normal-white-14px">
                    <a href="https://www.popvalet.com/popvalet-pro-services-valet">Transport par Valet</a><br />
                    <a href="https://www.popvalet.com/popvalet-pro-services-camion">Transport par Camion</a><br />
                    <a href="https://www.popvalet.com/popvalet-pro-services-expertise">Expertise</a><br />
                  <a href="https://www.popvalet.com/popvalet-pro-services-reparation">Réparation</a><br />
                  <a href="https://www.popvalet.com/popvalet-pro-services-restitution">Restitution</a>
                </span>
        </div>
        <div class="nous-connaitre-qui-s blogtextbold">
          <span class="span-13 blogtextbold">Qui sommes-nous ?<br /></span>
          <span class="span-14 montserrat-normal-white-14px">
              <a href="https://www.popvalet.com/popvalet-general-vision">Notre vision</a><br />
              <a href="https://www.popvalet.com/popvalet-general-qualite">Qualité de service</a><br />
              <a href="https://www.popvalet.com/popvalet-general-reactivite">Réactivité</a><br />
              <a href="https://www.popvalet.com/popvalet-general-technologie">Notre technologie</a><br />
              <a href="https://www.popvalet.com/popvalet-general-nos-use-cases">Nos use cases</a>
            </span>
        </div>
      </div>
      <div class="overlap-group1"><div class="copyright montserrat-normal-white-14px">{{'recruitment.home.footer.copyright' | translate}}</div></div>
    </div>
  </div>
</div>
