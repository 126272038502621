import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormGroup, ValidationErrors} from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import {BehaviorSubject, combineLatest, merge, Observable, Subject} from 'rxjs';
import {debounceTime, filter, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'slnk-shared-input-validator-item',
  templateUrl: './shared-input-validator-item.component.html',
  styleUrls: ['./shared-input-validator-item.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          opacity: 0,
          // 'max-height': 0,
          overflow: 'hidden'
        }),
        animate('200ms ease-in', style({
          opacity: 1,
          // 'max-height': '200px',
        }))
      ]),
      transition(':leave', [
        style({
          position: 'absolute',
          width: '100%',
          top: 0
        }),
        animate('200ms ease-in', style({
          opacity: 0,
        }))
      ])
    ])
  ]
})
export class SharedInputValidatorItemComponent implements OnInit, OnDestroy {
  @Input() validation: string;
  @Input() textAlign: string;
  @Input() shouldBeTouched: true;

  @Input('validationFormControl')
  set validationFormControl(validationFormControl: AbstractControl) {
    this.formSet$.next(validationFormControl);
  }

  public formSet$: BehaviorSubject<AbstractControl> = new BehaviorSubject(null);
  public showErrors$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public controlToCheck$: BehaviorSubject<AbstractControl> = new BehaviorSubject(null);
  public error$: BehaviorSubject<ValidationErrors> = new BehaviorSubject(null);

  private destroy$: Subject<void> = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.formSet$.pipe(
      filter((form) => !!form),
      switchMap(() => {
        return this.formSet$.value.statusChanges.pipe(startWith(this.formSet$.value.status));
      }),
      debounceTime(50),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      const currentForm = this.formSet$.value;
      if (currentForm instanceof FormGroup) {
        const controlNames = Object.keys(currentForm.controls);
        let controlToCheck = null
        for (const controlName of controlNames) {
          if (currentForm.get(controlName).status === 'INVALID') {
            controlToCheck = currentForm.get(controlName);
            break;
          }
        }
        this.controlToCheck$.next(controlToCheck);
      } else if (currentForm instanceof FormArray) {
        this.controlToCheck$.next(currentForm.controls.find((c) => c.status === 'INVALID'));
      } else {
        return this.controlToCheck$.next(this.formSet$.value)
      }
    })


    this.controlToCheck$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((control) => {
      const show = control &&
        (control.dirty || control.touched) &&
        control.errors &&
        (!this.validation || !!control.errors[this.validation])

      this.showErrors$.next(!!show);
      if (show) {
        this.error$.next(control.errors);
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
