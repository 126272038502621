import {
  DateFilterCustomLabels,
  DateFilterCustomValue,
  DateFilterUnit,
  DateFilterUnitLabels,
  DateFilterValue,
  DateFilterValueObject,
  DateFilterValuesForUnit,
  dateFilterValueToMomentAction,
} from '../components/date-filter-button/date-filter.model';
import * as moment from 'moment/moment';
import {UtilsHelper} from './utils.helper';
import {NbCalendarRange} from '@nebular/theme';
import {DateFilter} from '../../../submodule/model/date-filter.model';

export class DateFilterHelper {
  private static humanFormatDate = 'dddd Do MMMM YYYY'

  public static isRangeDate(value: DateFilterCustomValue): boolean {
    return [DateFilterCustomValue.BETWEEN].includes(value);
  }

  public static getValueForUnit(unit: DateFilterUnit): DateFilterValueObject[] {
    if (!unit) return null;
    return DateFilterValuesForUnit[unit];
  }

  public static getUnitLabel(unit: DateFilterUnit): string {
    if (!unit) return '';
    return DateFilterUnitLabels[unit]
  }

  public static getDateLabelHtml(date: DateFilter) {
    if (!date) return '';


    if (!UtilsHelper.IsNilOrEmpty(date.before) && !UtilsHelper.IsNilOrEmpty(date.after)) {
      const after = moment(date.after).format(DateFilterHelper.humanFormatDate);
      const before = moment(date.before).format(DateFilterHelper.humanFormatDate)
      return `<strong>Du:&nbsp;</strong>${after}&nbsp;<br><strong>Au:&nbsp;</strong>${before}`;
    }

    if (!UtilsHelper.IsNilOrEmpty(date.before)) {
      const before = moment(date.before).format(DateFilterHelper.humanFormatDate)
      return `<strong>Avant le:&nbsp;</strong>${before}`;
    }

    if (!UtilsHelper.IsNilOrEmpty(date.after)) {
      const after = moment(date.after).format(DateFilterHelper.humanFormatDate);
      return `<strong>Après le:&nbsp;</strong>${after}`;
    }

    if (!UtilsHelper.IsNilOrEmpty(date.date)) {
      const dateFormated = moment(date.date).format(DateFilterHelper.humanFormatDate);
      return `<strong>Le:&nbsp;</strong>${dateFormated}`
    }

    return ``;
  }

  public static getDateValue(value: DateFilterValue, unit: DateFilterUnit): DateFilter {
    if (!unit || !value) return null;

    if (DateFilterUnit[unit] === DateFilterUnit.DAY) {
      return {
        date: dateFilterValueToMomentAction[value](unit).format('YYYY-MM-DDTHH:mm:ssZ')
      }
    }

    return {
      before: dateFilterValueToMomentAction[value](unit).endOf(unit).format('YYYY-MM-DDTHH:mm:ssZ'),
      after: dateFilterValueToMomentAction[value](unit).startOf(unit).format('YYYY-MM-DDTHH:mm:ssZ'),
    }
  }

  public static getCustomValue(date: Date, range: NbCalendarRange<Date>, customSelection: DateFilterCustomValue): DateFilter {
    if ((!range && !date) || !customSelection) return null;

    if (DateFilterCustomValue[customSelection] === DateFilterCustomValue.BETWEEN && !!range && !!range.end && !!range.start) {
      return {
        before: moment(range.end).endOf('days').format('YYYY-MM-DDTHH:mm:ssZ'),
        after: moment(range.start).startOf('days').format('YYYY-MM-DDTHH:mm:ssZ'),
      }
    }

    if (!!date) {
      if (DateFilterCustomValue[customSelection] === DateFilterCustomValue.EXACT) {
        return {
          date: moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
        }
      } else if (DateFilterCustomValue[customSelection] === DateFilterCustomValue.BEFORE) {
        return {
          before: moment(date).startOf('days').format('YYYY-MM-DDTHH:mm:ssZ')
        }
      } else if (DateFilterCustomValue[customSelection] === DateFilterCustomValue.AFTER) {
        return {
          after: moment(date).endOf('days').format('YYYY-MM-DDTHH:mm:ssZ')
        }
      }
    }
  }

  public static getCustomValueLabel(value: DateFilterCustomValue) {
    return DateFilterCustomLabels[value]
  }
}
