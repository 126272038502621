import {Component, OnInit, ViewChild} from '@angular/core';
import {USER_ROLE} from '../../../shared/models/user';
import {selectAuthState, UserState} from '../../../shared/store/user/auth.state';
import {Store} from '@ngrx/store';
import {PvRecruitmentService} from '../pv-recruitment.service';
import {ValetDocument, ValetDocuments} from '../../../common-modules/common-valet/models/valet-document';
import {UpdateUser} from '../../../shared/store/user/auth.actions';
import {ToastHandlerService} from '../../../shared/services/toast-handler.service';
import {AuthService} from '../../../shared/services/auth.service';
import {CommonValetProfileFormComponent} from '../../../common-modules/common-valet/components/common-valet-profile-form/common-valet-profile-form.component';
import * as moment from 'moment/moment';
import {NbStepperComponent} from '@nebular/theme';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Candidate, Step} from '../models/Candidate';

@Component({
  selector: 'pv-pv-recruitment-process',
  templateUrl: './pv-recruitment-process.component.html',
  styleUrls: ['./pv-recruitment-process.component.scss']
})
export class PvRecruitmentProcessComponent implements OnInit {
  public USER_ROLE = USER_ROLE;
  public Step = Step;
  public candidateUser: any;
  public documents: ValetDocuments;
  public candidate: Candidate;
  public stepperIndex = 0;
  public showStepper = false;
  public showArchived = false;

  private readonly STEPS = ['tutorial', 'videoInterview', 'idCheck', 'profile', 'contract', 'training'] as const;

  @ViewChild('profileForm') valetProfileFormComponent: CommonValetProfileFormComponent
  @ViewChild('stepper') stepper: NbStepperComponent;

  constructor(private store: Store<UserState>,
              private toastService: ToastHandlerService,
              private recruitmentService: PvRecruitmentService,
              private authService: AuthService,
              private translateService: TranslateService,
              private router: Router) { }

  ngOnInit(): void {
    this.getCandidateDocuments()
    this.store.select(selectAuthState).subscribe(({user}) => {
      this.candidateUser = user;
    });

    this.authService.getUserData().toPromise().then((user: any) => {
      this.candidate = user.data.candidate;
      this.candidateUser = user.data;

      this.store.dispatch(new UpdateUser(user.data));

      this.stepperIndex = this.STEPS.findIndex(s => s === this.candidate.step);
      this.recruitmentService.gaPageEvent('recruitment-process/' + this.STEPS[this.stepperIndex]);

      if (this.candidate.archived && this.candidate.eligibilityStatus === 'valid') {
        this.showArchived = true;
      } else {
        this.showStepper = true;
      }
    });
  }

  public updateCandidateData(candidate: any) {
    this.candidate = candidate;
  }

  public reloadUserData() {
    return new Promise((resolve, reject) => {
      this.authService.getUserData().toPromise().then((user: any) => {
        this.candidate = user.data.candidate;
        this.candidateUser = user.data;
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  }

  private getCandidateDocuments() {
    return new Promise((resolve, reject) => {
      this.recruitmentService.getCandidateDocuments().then(res => {
        this.documents = res;
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  }

  public async nextStep(reloadUserData?: boolean) {
    if (reloadUserData) {
      await this.reloadUserData();
      await this.getCandidateDocuments();
    }
    this.stepper.next();
    this.recruitmentService.gaPageEvent('recruitment-process/' + this.STEPS[this.stepper.selectedIndex]);
  }

  public previousStep() {
    this.stepper.previous();
    this.recruitmentService.gaPageEvent('recruitment-process/' + this.STEPS[this.stepper.selectedIndex]);
  }

  updateDocument(document: ValetDocument) {
    this.recruitmentService.updateCandidateDocument(document).then(res => {
      document.isSubmitting = false;
      this.documents = res;
      this.authService.getUserData().toPromise().then((user: any) => {
        this.candidate = user.data.candidate;
      });
    }).catch(err => {
      document.isSubmitting = false;
    });
  }

  submitProfile() {
    const formData = this.valetProfileFormComponent.valetForm.getRawValue();
    if (formData.birthDate) {
      formData.birthDate = moment(formData.birthDate).set({hour: 0, minute: 0, second: 0});
    }
    if (this.valetProfileFormComponent.valetForm.valid) {
      this.recruitmentService.updateCandidateProfile(formData).then(res => {
        const candidateOnly = JSON.parse(JSON.stringify(res));
        delete candidateOnly.user;

        this.store.dispatch(new UpdateUser({
          ...res.user,
          candidate: candidateOnly
        }));
        this.nextStep();
      }).catch(err => {
        this.toastService.showErrorToastsResponse(err);
      });
    } else if (this.valetProfileFormComponent.valetForm.invalid) {
      this.valetProfileFormComponent.valetForm.markAllAsTouched();
      this.toastService.showErrorToasts(
        this.translateService.instant('recruitment.process.step.documents.form_not_valid')
      );
    }
  }

  redirectToHomePage() {
    this.router.navigate(['/']);
  }
}
