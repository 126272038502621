export enum MISSION_TYPE {
  TRANSFER = 'transfert',
  BUYBACK = 'buyBack',
  PICK_UP = 'pickup',
  DELIVERY = 'delivery',
  COURTOISIE = 'courtoisie',
  PICK_AND_DELIVERY = 'pickupAndDelivery',
  LIVRAISON = 'livraison',
  REPRISE = 'reprise',
  LIVRAISON_AVEC_REPRISE = 'livraisonAvecReprise',
  TRANSFER_RETOUR = 'transfertRetour',
  TRANSFER_ALLER_RETOUR = 'transfertAllerRetour',
  LIVRAISON_REPRISE_SECTION = 'livraisonRepriseSection',
  PICKUP_AND_DELIVERY_SECTION = 'pickupDeliverySection',
  TRANSFERT_SECTION = 'transfertSection',
}

export const MissionLinkedType: { [key in MISSION_TYPE]?: MISSION_TYPE } = {
  [MISSION_TYPE.PICK_AND_DELIVERY]: MISSION_TYPE.DELIVERY,
  [MISSION_TYPE.LIVRAISON_AVEC_REPRISE]: MISSION_TYPE.REPRISE,
  [MISSION_TYPE.TRANSFER_ALLER_RETOUR]: MISSION_TYPE.TRANSFER_RETOUR,
}

export const MissionBaseType: { [key in MISSION_TYPE]?: MISSION_TYPE } = {
  [MISSION_TYPE.PICK_AND_DELIVERY]: MISSION_TYPE.PICK_UP,
  [MISSION_TYPE.LIVRAISON_AVEC_REPRISE]: MISSION_TYPE.LIVRAISON,
  [MISSION_TYPE.TRANSFER_ALLER_RETOUR]: MISSION_TYPE.TRANSFER,
}

export const MissionBaseCourtesyType: { [key in MISSION_TYPE]?: MISSION_TYPE } = {
  [MISSION_TYPE.PICK_UP]: MISSION_TYPE.COURTOISIE,
  [MISSION_TYPE.DELIVERY]: MISSION_TYPE.DELIVERY,
}

export const MissionSecondCourtesyType: { [key in MISSION_TYPE]?: MISSION_TYPE } = {
  [MISSION_TYPE.PICK_UP]: MISSION_TYPE.PICK_UP,
  [MISSION_TYPE.DELIVERY]: MISSION_TYPE.COURTOISIE,
}
