<div *ngIf="candidate?.trainingStatus === 'missing' || candidate?.trainingStatus === 'invalid'">
  <div class="text-center">
    <nb-icon icon="times-circle"
             status="danger"></nb-icon>
    <p>{{'recruitment.process.step.webinar.missing' | translate}}</p>
  </div>
  <h5>{{'recruitment.process.step.webinar.next_sessions' | translate}}</h5>
  <div [formGroup]="form" class="row">
    <div class="col-12">
      <div class="form-group">
        <nb-radio-group formControlName="session">
          <nb-radio
            *ngFor="let session of sessions"
            [disabled]="session.isFull"
            [value]="session.id">
            <span [ngClass]="{full: session.isFull}">
              {{session.title}}.
              <strong>{{(session.estimatedStartDate * 1000) | momentPipe: '[Date :] dddd DD MMMM [à] HH:mm '}}</strong>
              <ng-container *ngIf="session.isFull">{{'recruitment.process.step.webinar.session_full' | translate}}</ng-container>
            </span>
          </nb-radio>
        </nb-radio-group>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <button nbButton
              type="submit"
              status="primary"
              [disabled]="form.invalid || isSubmitting"
              [nbSpinner]="isSubmitting"
              (click)="submit()">
        {{'global.buttons.submit' | translate}}
      </button>
    </div>
  </div>
</div>
<div *ngIf="candidate?.trainingStatus === 'pending'"
     class="d-flex justify-content-center align-items-center" style="height: 450px;">
  <div class="text-center">
    <nb-icon icon="pause-circle"
             status="warning"></nb-icon>
    <p>{{'recruitment.process.step.webinar.valid' | translate}}</p>
    <p>{{'recruitment.process.step.webinar.advice' | translate}}</p>
  </div>
</div>
<div class="mt-5">
  {{'recruitment.process.step.webinar.download_app' | translate}}
  <a href="https://admin-api.popvalet.com/valet-app/download" target="_blank">
    {{'recruitment.process.step.webinar.download_app_button' | translate}}
  </a>
</div>
