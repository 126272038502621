import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {USER_ROLE} from '../../../../../shared/models/user';
import {VALET_DOCUMENT_STATUS, VALET_DOCUMENT_TYPE, ValetDocument} from '../../../models/valet-document';
import {ACCEPTED_VALET_FILE_TYPES} from '../../../../../shared/constants/constants';
import * as moment from 'moment';
import {AdminValetService} from '../../../../../admin/modules/admin-valet/services/admin-valet.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'pv-admin-valet-form-document',
    templateUrl: './common-valet-profile-form-document.component.html',
    styleUrls: ['./common-valet-profile-form-document.component.scss']
})
export class CommonValetProfileFormDocumentComponent implements OnInit {
    public fileName: string | File = '';
    public DOCUMENT_STATUS = VALET_DOCUMENT_STATUS;
    public file;
    public ROLES = USER_ROLE;
    public document: ValetDocument;
    public allowUpload = true;
    public expiryDate = null;
    public emittedDate = null;
    public wasFileChanged: boolean;
    public acceptedValetFileTypes = ACCEPTED_VALET_FILE_TYPES;
    public validStatus: boolean;
    public invalidStatus: boolean;
    public form: FormGroup;
    public today: moment.Moment = moment();

    @Input() cardHeader: string;
    @Input() viewAs: USER_ROLE;
    @Input() documentType: VALET_DOCUMENT_TYPE;
    @Input() ifApplicable: boolean;
    @Input() optional: boolean;
    @Input() requireExpiryDate: boolean;
    @Input() requireEmittedDate: boolean;
    @Input() rejectedReasons?: string[];

    @Input('document')
    set documentData(document: ValetDocument) {
        if (document) {
            this.file = document.url;
            if (this.viewAs !== USER_ROLE.ADMIN && document.status === 'VALID') {
              this.allowUpload = false;
            }
            if (document.comment !== null) {
              if (!this.form) {
                this.initForm();
              }
              this.form.get('comment').setValue(document.comment);
            }
            if (typeof document.url === 'string') {
                const splitFilePath = document.key.split('/');
                this.fileName = splitFilePath[splitFilePath.length - 1];
            } else {
                this.fileName = document.url.name;
            }
            this.document = document;
            if (document.expiryDate) {
              this.expiryDate = moment(document.expiryDate);
            }
            if (document.emittedDate) {
              this.emittedDate = moment(document.emittedDate);
            }
            this.wasFileChanged = false;
        }
    }

    @Input() isSubmitting: boolean;
    @Output() documentChanged = new EventEmitter<ValetDocument>();

    constructor(private adminValetService: AdminValetService,
                private fb: FormBuilder,) {
    }

    ngOnInit() {
      this.validStatus = false;
      this.invalidStatus = false;

      this.initForm();
    }

    initForm() {
      this.form = this.fb.group({
        comment: [''],
      });
    }

    fileInputChanged(file: File) {
        this.wasFileChanged = true;
        if (file) {
            this.file = file;
            this.fileName = file.name;
            if (this.emittedDate) {
              this.emittedDate = null;
            }
        }
    }

    public uploadFile() {
        const newDocument: ValetDocument = {
            url: this.file,
            documentType: this.documentType
        };
        if (this.expiryDate && this.requireExpiryDate) {
          newDocument.expiryDate = this.expiryDate.toISOString();
        }
        if (this.emittedDate && this.requireEmittedDate) {
          newDocument.emittedDate = this.emittedDate.toISOString();
        }
        this.documentChanged.emit(newDocument);
    }

    fileValidationChanged(setDocumentStatus: VALET_DOCUMENT_STATUS) {
        const newDocument: ValetDocument = {
            documentType: this.documentType,
            status: setDocumentStatus,
            comment: this.form.get('comment').value
        };
        this.documentChanged.emit(newDocument);

        if (setDocumentStatus === 'VALID') {
          this.validStatus = true;
          this.invalidStatus = false;
        } else if (setDocumentStatus === 'INVALID') {
          this.invalidStatus = true;
          this.validStatus = false;
        }
    }

    expiryDateChanged(date) {
      this.expiryDate = date;
    }

    emittedDateChanged(date) {
      this.emittedDate = date;
    }

    canUploadFile() {
      return this.wasFileChanged &&
        (!this.requireExpiryDate || (this.requireExpiryDate && this.expiryDate)) &&
        (!this.requireEmittedDate || (this.requireEmittedDate && this.emittedDate));
    }

    showComment() {
      return !!this.document?.comment;
    }
}
