import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LogOut} from '../store/user/auth.actions';
import {Store} from '@ngrx/store';
import {UserState} from '../store/user/auth.state';
import {TranslateService} from '@ngx-translate/core';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private translate: TranslateService,
              private toastStr: NbToastrService,
              private store: Store<UserState>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if (request.url.indexOf('/auth') === -1 && err.status === 401) {
        try {
          this.toastStr.danger(
            'Your token has expired.',
            this.translate.instant('global.toasts.error')
          );
        } catch (error) {
          console.error(error);
        }
        this.store.dispatch(new LogOut());
      }

      return throwError(err);
    }));
  }
}
