import {Pipe, PipeTransform} from '@angular/core';
import {
  DateFilterCustomValue,
  DateFilterUnit,
  DateFilterValueObject,
} from '../components/date-filter-button/date-filter.model';
import {DateFilterHelper} from '../helpers/date-filter.helper';
import {DateFilter} from '../../../submodule/model/date-filter.model';

@Pipe({
  name: 'getValuesForUnit'
})
export class GetValuesForUnit implements PipeTransform {

  transform(unit: string | null): DateFilterValueObject[] {
    if (!unit) return null;
    return DateFilterHelper.getValueForUnit(unit as DateFilterUnit);
  }

}

@Pipe({
  name: 'getUnitLabel'
})
export class GetUnitLabel implements PipeTransform {

  transform(unit: DateFilterUnit): string {
    if (!unit) return '';
    return DateFilterHelper.getUnitLabel(unit);
  }

}

@Pipe({
  name: 'getDateLabelHtml'
})
export class GetDateLabelHtmlPipe implements PipeTransform {

  transform(date: DateFilter): string {
    return DateFilterHelper.getDateLabelHtml(date);
  }

}

@Pipe({
  name: 'getCustomValueLabel'
})
export class GetCustomValueLabelPipe implements PipeTransform {

  transform(value: DateFilterCustomValue): string {
    if (!value) return '';
    return DateFilterHelper.getCustomValueLabel(value);
  }
}

@Pipe({
  name: 'isRangeDate'
})
export class IsRangeDate implements PipeTransform {

  transform(value: DateFilterCustomValue | null): boolean {
    if (!value) return false;
    return DateFilterHelper.isRangeDate(value);
  }
}
