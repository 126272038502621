<div class="sur-header-container">
  <div class="sur-header">
    <div class="rating-group">
      <div class="rating-sub-group">
        <div class="rating-text isidorasansalt-semi-bold-white-14px">
          {{'recruitment.home.header.review' | translate}}
        </div>
        <div>
          <img class="star" src="assets/images/solid---favorite-1@2x.png" alt="9,7 / 10" />
          <img class="star" src="assets/images/solid---favorite-1@2x.png" alt="9,7 / 10" />
          <img class="star" src="assets/images/solid---favorite-1@2x.png" alt="9,7 / 10" />
          <img class="star" src="assets/images/solid---favorite-1@2x.png" alt="9,7 / 10" />
          <img class="star" src="assets/images/solid---favorite-1@2x.png" alt="9,7 / 10" />
        </div>
      </div>
      <div class="rating-count-text montserrat-normal-white-10px">
        <a href="https://www.avis-verifies.com/avis-clients/popvalet.com" target="_blank" style="color: white; text-decoration: none">{{'recruitment.home.header.clients_reviews' | translate}}</a>
      </div>
    </div>
    <div class="quality-group">
      <img class="quality-icon" src="assets/images/user-interface---like-7@2x.png" alt="Qualité de service" />
      <div class="quality-text isidorasansalt-semi-bold-white-14px">
        <a href="https://www.popvalet.com/popvalet-general-qualite" style="color: white; text-decoration: none">{{'recruitment.home.header.quality_service' | translate}}</a>
      </div>
    </div>
    <div class="reactivity-group">
      <img class="reactivity-icon" src="assets/images/time---watch-1@2x.png" alt="Réactivité" />
      <div class="reactivity-text isidorasansalt-semi-bold-white-14px">
        <a href="https://www.popvalet.com/popvalet-general-reactivite" style="color: white; text-decoration: none">{{'recruitment.home.header.reactivity' | translate}}</a>
      </div>
    </div>
  </div>
</div>
<div class="header-container">
  <header class="header">
    <a href="http://www.popvalet.com" style="text-decoration: none">
      <img class="popvalet-logo" src="assets/images/header-composant-2@2x.png" alt="Pop Valet" />
    </a>
    <div class="cta-container">
      <a style="text-decoration: none; cursor: pointer" (click)="showConnexionDialog()">
        <div class="cta-button blogtextbold yellow">
          {{'recruitment.home.login' | translate}}
        </div>
      </a>
      <a style="text-decoration: none; cursor: pointer" (click)="showRecruitmentPage()">
        <div class="cta-button blogtextbold yellow">
          {{'recruitment.home.become_valet' | translate}}
        </div>
      </a>
    </div>

    <div class="mobile-nav-button">
      <div class="mobile-nav-container">
        <a style="text-decoration: none; cursor: pointer" (click)="showConnexionDialog()">
          <div class="cta-button blogtextbold" style="background-color: #FADC41; color: #002244">
            {{'recruitment.home.login' | translate}}
          </div>
        </a>
        <a style="text-decoration: none; cursor: pointer" (click)="showRecruitmentPage()">
          <div class="cta-button blogtextbold" style="background-color: #FADC41; color: #002244">
            {{'recruitment.home.become_valet' | translate}}
          </div>
        </a>
      </div>
    </div>
  </header>
</div>
