import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commonAutocompleteAreGroups'
})
export class CommonAutocompleteArrayOfGroupsPipe implements PipeTransform {

  transform(value: any[]): unknown {
    if (value.length > 0) {
      return value[0].children;
    }
    return false;
  }

}
