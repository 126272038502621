import { Address } from '../../admin/modules/admin-places/models/address';
import PlaceResult = google.maps.places.PlaceResult;
import {UtilsHelper} from "../helpers/utils.helper";

interface GoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}

export class GoogleAddressParser {
  constructor() {}

  public static parseAddress(place: PlaceResult): Address {
    const addressComponents = place.address_components;
    const address: Address | any = {};

    if (!Array.isArray(addressComponents)) {
      throw Error('Address Components is not an array');
    }

    if (!addressComponents.length) {
      throw Error('Address Components is empty');
    }

    addressComponents.forEach((item) => {
      const component: GoogleAddressComponent = item;
      if (GoogleAddressParser.isStreetNumber(component)) {
        address.number = component.long_name;
      }

      if (GoogleAddressParser.isStreetName(component)) {
        address.street = component.long_name;
      }

      if (GoogleAddressParser.isCity(component)) {
        address.city = component.long_name;
      }

      if (GoogleAddressParser.isCountry(component)) {
        address.country = component.long_name;
        address.countryCode = component.short_name;
      }

      if (GoogleAddressParser.isPostalCode(component)) {
        address.postalCode = component.long_name;
      }
    });
    const labelAndName  = GoogleAddressParser.getAddressFormattedLabels(place)

    return { ...address, ...labelAndName };
  }

  private static isStreetNumber(component: GoogleAddressComponent): boolean {
    return component.types.includes('street_number');
  }

  private static isStreetName(component: GoogleAddressComponent): boolean {
    return component.types.includes('route');
  }

  private static isCity(component: GoogleAddressComponent): boolean {
    return component.types.includes('locality');
  }

  private static isCountry(component: GoogleAddressComponent): boolean {
    return component.types.includes('country');
  }

  private static isPostalCode(component: GoogleAddressComponent): boolean {
    return component.types.includes('postal_code');
  }

  public static getAddressFormattedLabels(place: PlaceResult): { label: string, name?: string } {
    const nameShouldBeMeaningful = place.types.includes('establishment');
    const formatedAddressContainName = place.formatted_address.startsWith(place.name);
    if (!UtilsHelper.IsNilOrEmpty(place.name) && nameShouldBeMeaningful && !formatedAddressContainName) {
      return { label:`${place.name}, ${place.formatted_address}`, name: place.name };
    }
    return { label: place.formatted_address };
  }
}
