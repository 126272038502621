import { Injectable } from '@angular/core';
import {BehaviorSubject, observable, Observable, throwError} from 'rxjs';
import { Settings } from '../models/settings';
import { PvReservationService } from './pv-reservation.service';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ToastHandlerService} from '../../../shared/services/toast-handler.service';
import {MISSION_TYPE} from "../../../shared/models/order.model";

@Injectable()
export class PvReservationDataService {
  public reservationData = new BehaviorSubject({});
  public linkedMissionReservationData = new BehaviorSubject({});
  public priceCalculation = new BehaviorSubject({});
  public reservationBillingData: any;
  public reservationPaymentData: any;
  public selectedType: MISSION_TYPE;
  private settings: Settings;

  constructor(private pvReservationService: PvReservationService,
              private http: HttpClient,
              private toastService: ToastHandlerService) {
  }

  public getPlateNumber(plateNumber: string) {
    return this.http.get(`${environment.backendUrl}plate-number/${plateNumber}`, {})
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          console.error(err);
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getReservationData(): Observable<any> {
    return this.reservationData;
  }

  public setReservationData(data) {
    this.reservationData.next({...this.reservationData.value, ...data});
  }

  public setLinkedMissionReservationData(data) {
    this.linkedMissionReservationData.next({...this.linkedMissionReservationData.value, ...data});
  }

  public getLinkedMissionReservationData(): Observable<any> {
    return this.linkedMissionReservationData;
  }

  public setReservationBillingData(data) {
    this.reservationBillingData = data;
  }

  public getReservationBillingData(): any {
    return this.reservationBillingData;
  }

  public setReservationPaymentData(data) {
    this.reservationPaymentData = data;
  }

  public getReservationPaymentData(): any {
    return this.reservationPaymentData;
  }

  public getPriceCalculationObservable(): Observable<any> {
    return this.priceCalculation;
  }

  public setSettings(data: Settings) {
    this.settings = data;
  }

  public getSettings() {
    return this.settings;
  }

  public calculatePrice(publicKey: string,
                        isCalculationReservationDataNeeded: boolean,
                        isCalculationLinkedMissionReservationDataNeeded: boolean,
                        withDefaultValues: boolean
  ) {
    if (this.selectedType) {
      if (isCalculationLinkedMissionReservationDataNeeded) {
        if (this.selectedType === MISSION_TYPE.LIVRAISON_AVEC_REPRISE) {
          this.pvReservationService.getPrice(publicKey,
            withDefaultValues ? this.getDefaultFormValues(this.reservationData) : this.reservationData.value,
            withDefaultValues
              ? this.getDefaultFormValues(this.linkedMissionReservationData)
              : this.linkedMissionReservationData.value).subscribe(resp => {
            this.priceCalculation.next({...this.priceCalculation.value, ...this.formatPriceCalculationResponse(
              resp,
              this.selectedType === MISSION_TYPE.PICK_AND_DELIVERY,
              true
            )});
          });
        } else {
          this.pvReservationService.getPrice(publicKey,
            withDefaultValues
              ? this.getDefaultFormValues(this.linkedMissionReservationData)
              : this.linkedMissionReservationData.value).subscribe(resp => {
            this.priceCalculation.next({...this.priceCalculation.value, ...this.formatPriceCalculationResponse(
              resp,
              this.selectedType === MISSION_TYPE.PICK_AND_DELIVERY,
              true
            )});
          });
        }
      }
      if (isCalculationReservationDataNeeded) {
        if (this.selectedType === MISSION_TYPE.LIVRAISON_AVEC_REPRISE) {
          this.pvReservationService.getPrice(publicKey,
            withDefaultValues ? this.getDefaultFormValues(this.reservationData) : this.reservationData.value,
            withDefaultValues
              ? this.getDefaultFormValues(this.linkedMissionReservationData)
              : this.linkedMissionReservationData.value).subscribe(resp => {
            this.priceCalculation.next({...this.priceCalculation.value, ...this.formatPriceCalculationResponse(
              resp,
              this.selectedType === MISSION_TYPE.PICK_AND_DELIVERY,
              false
            )});
          });
        } else {
          this.pvReservationService.getPrice(publicKey,
            withDefaultValues ? this.getDefaultFormValues(this.reservationData) : this.reservationData.value).subscribe(resp => {
            this.priceCalculation.next({priceCalculation: resp, linkedMissionPriceCalculation: null});
          });
        }

      }
    }
  }

  private formatPriceCalculationResponse(response: any, isPickupAndDelivery: boolean, isSecondMission: boolean) {
    const price: any = {
      linkedMissionPriceCalculation: {
        value: isPickupAndDelivery && isSecondMission ? response.value : response.linkedMissionValue,
        valueInclTax: isPickupAndDelivery && isSecondMission ? response.valueInclTax : response.linkedMissionValueInclTax
      }
    };

    if (!isPickupAndDelivery || !isSecondMission) {
      price.priceCalculation = {
        value: response.value,
        valueInclTax: response.valueInclTax
      };
    }

    return price;
  }

  public setSelectedType(selectedType) {
    this.selectedType = selectedType;
  }

  public getSelectedType(): MISSION_TYPE {
    return this.selectedType;
  }

  private getDefaultFormValues(formAsBehaviorSubject) {
    const defaultValues = {
      largeVehicle: false,
      electricVehicle: false,
      wGarage: false,
      services: {
        washing: this.settings.services.forceWashing,
        fillUp: this.settings.services.fillUpDefaultValue,
        handOver: formAsBehaviorSubject.value.type === MISSION_TYPE.LIVRAISON ? this.settings.services.handOverDefaultValue : '',
        contactLess: false,
        restitutionManagement: formAsBehaviorSubject.value.type === MISSION_TYPE.REPRISE
          && this.settings.services.forceRestitutionManagement
      }
    };

    return {...formAsBehaviorSubject.value, ...defaultValues};
  }

  public getRecommendedChargingCount(brand: string, model: string, distance: number) {
    return this.http.post(`${environment.backendUrl}mission-services/get-recommended-charging-count`, {
      brand,
      model,
      distance
    })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }
}
