<div #checkboxContainer class="w-100">
  <nb-checkbox class="d-flex flex-column w-100"
               [class.single_select]="!multiple"
               [checked]="isSelected"
               (checkedChange)="itemSelected.emit($event)">
    <ng-content>
    </ng-content>
  </nb-checkbox>

</div>
