import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CommonRoleTranslatePipe } from './pipes/common-role-translate.pipe';

@NgModule({
  declarations: [CommonRoleTranslatePipe],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [TranslateModule, CommonRoleTranslatePipe]
})
export class CommonTranslateModule {
}
