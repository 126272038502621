import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonCardComponent } from './components/common-card/common-card.component';
import { NbCardModule, NbIconModule } from '@nebular/theme';
import { CommonCardBodyComponent } from './components/common-card-body/common-card-body.component';
import { CommonCardFooterComponent } from './components/common-card-footer/common-card-footer.component';
import { CommonCardHeaderComponent } from './components/common-card-header/common-card-header.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    CommonCardComponent,
    CommonCardBodyComponent,
    CommonCardFooterComponent,
    CommonCardHeaderComponent
  ],
    imports: [
        CommonModule,
        NbCardModule,
        RouterModule,
        NbIconModule
    ],
  exports: [
    CommonCardComponent,
    CommonCardBodyComponent,
    CommonCardFooterComponent,
    CommonCardHeaderComponent
  ]
})
export class CommonCardModule {
}
