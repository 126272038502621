<div class="row">
  <div class="col-12">
    <h2>
      {{'modules.valet.valet_evaluation.evaluation' |translate}}
    </h2>
  </div>
  <div class="col-12">
    <common-card class="d-flex w-100">
      <common-card-body class="d-flex flex-column">
          <ng-container>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="d-flex">
                    <div class="w-100 mt-3">
                      {{'modules.valet.valet_evaluation.average_client_rate' |translate}} {{ valet.averageClientRate | number:'1.0-2' }}/5
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </ng-container>
      </common-card-body>
    </common-card>
  </div>
</div>
