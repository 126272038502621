<form [formGroup]="profilePictureForm">
  <ng-container *ngIf="valet">
    <common-card>
      <common-card-header>
        <div class="row no-gutters">
          <div class="col-auto mr-2">
            <nb-icon pack="regular" icon="address-card"></nb-icon>
          </div>
          <div class="col">
            <h6>
              {{'modules.valet.valet_profile.profile_picture' |translate}}
            </h6>
          </div>
        </div>
      </common-card-header>
      <common-card-body>
        <div class="d-flex justify-content-center">
          <div class="profile-picture" *ngIf="profilePicturePreview">
            <img [src]="profilePicturePreview" alt="Profile picture" />
          </div>
          <div class="profile-picture" *ngIf="valet.profilePictureFile?.url && !profilePicturePreview">
            <img [src]="valet.profilePictureFile?.url" alt="Profile picture" />
          </div>
          <div class="profile-picture" *ngIf="valet.identityCheck?.facePictureFile?.url && !valet.profilePictureFile?.url && !profilePicturePreview">
            <img [src]="valet.identityCheck?.facePictureFile?.url" alt="Profile picture" />
          </div>
          <div class="profile-picture" *ngIf="!valet.identityCheck?.facePictureFile?.url && !valet.profilePictureFile?.url && !profilePicturePreview">
            <img src="assets/images/popvalet_icon.png" alt="Profile picture" />
          </div>
          <div class="d-flex align-items-end mb-2 position-relative">
            <div class="profile-picture-container" style="cursor: pointer" (click)="fileInput.click()">
              <nb-icon class="pen_button" icon="pen"></nb-icon>
            </div>
            <input type="file"
                   class="d-none"
                   #fileInput
                   [accept]="acceptedProfilePictureFileTypes"
                   (change)="fileChanged($event)">
          </div>
        </div>
        <ng-container *ngIf="!profilePicturePreview">
          <p class="text-center">{{'modules.valet.valet_profile.profile_picture_accepted_types' | translate}}</p>
          <p class="text-center">{{'modules.valet.valet_profile.profile_picture_accepted_size' | translate}}</p>
        </ng-container>
        <p *ngIf="profilePicturePreview" class="text-center">{{'modules.valet.valet_profile.profile_picture_accepted_reminder' | translate}}</p>
      </common-card-body>
    </common-card>
    <div class="row mb-4">
      <div class="col-auto ml-auto">
        <button nbButton
                type="submit"
                status="primary"
                (click)="submitProfilePicture()"
                [disabled]="!showProfilePictureButton">
          {{'modules.valet.valet_profile.validate_profile_picture' | translate}}
        </button>
      </div>
    </div>
  </ng-container>
</form>
<common-card *ngIf="viewAs === ROLE.VALET">
  <common-card-body>
    <div class="d-flex align-items-center flex-wrap">
      <div class="mr-3">
        <nb-icon icon="users"></nb-icon>
      </div>
      <div class="mr-3">
        <h6 class="m-0">
          {{'modules.valet.valet_profile.my_referral_code' | translate}}
        </h6>
      </div>
      <div class="mr-3 my-1">
        <h5 class="m-0">{{valet.referralCode}}</h5>
      </div>
      <div class="mr-2">
        <button nbButton
                size="small"
                status="primary"
                class="mr-2 my-1"
                (click)="copyReferralCode()">
          <nb-icon icon="copy"></nb-icon>
          {{'modules.valet.valet_profile.copy_referral_code' | translate}}
        </button>
        <button nbButton
                size="small"
                status="primary"
                class="my-1"
                (click)="emailReferralCode()">
          <nb-icon icon="envelope"></nb-icon>
          {{'modules.valet.valet_profile.email_referral_code' | translate}}
        </button>
      </div>
    </div>
  </common-card-body>
</common-card>
<common-card [formGroup]="valetForm">
  <common-card-header>
    <div class="row no-gutters">
      <div class="col-auto mr-2">
        <nb-icon pack="regular" icon="address-card"></nb-icon>
      </div>
      <div class="col">
        <h6>
          {{'modules.valet.valet_profile.info' |translate}}
        </h6>
      </div>
    </div>
  </common-card-header>
  <common-card-body>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.title' |translate}}</label>
          <nb-select formControlName="title" fullWidth>
            <nb-option *ngFor="let type of ( availableTitles| async)"
                       [value]="type.value">
              {{type.name}}
            </nb-option>
          </nb-select>
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['title']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="firstName" class="label required">
            {{'modules.valet.valet_profile.first_name' |translate}}
          </label>
          <input id="firstName"
                 fullWidth
                 nbInput
                 formControlName="firstName"
                 type="text">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['firstName']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="lastName" class="label required">
            {{'modules.valet.valet_profile.last_name' |translate}}
          </label>
          <input id="lastName"
                 fullWidth
                 nbInput
                 formControlName="lastName"
                 type="text">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['lastName']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="birth_date"
                 class="label required"
          >
            {{'modules.valet.valet_profile.date_of_birth' |translate}}
          </label>
          <input [nbDatepicker]="datepicker"
                 nbInput
                 fullWidth
                 readonly
                 id="birth_date"
                 formControlName="birthDate"
          >
          <nb-datepicker #datepicker
                         [format]="'DD.MM.YYYY'">

          </nb-datepicker>
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['birthDate']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="creation_date" class="label">
            {{'modules.valet.valet_profile.creation_date' |translate}}
          </label>
          <input nbInput
                 fullWidth
                 readonly
                 id="creation_date"
                 formControlName="creationDate">
        </div>
      </div>
      <div class="col-md-4" *ngIf="valetForm.controls['drivingLicenseNumber'].value">
        <div class="form-group">
          <label for="drivingLicenseNumber" class="label">
            {{'modules.valet.valet_profile.driving_license_number' |translate}}
          </label>
          <div class="d-flex">
            <input id="drivingLicenseNumber"
                   readonly
                   disabled
                   fullWidth
                   nbInput
                   formControlName="drivingLicenseNumber"
                   type="text">
          </div>
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['drivingLicenseNumber']">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4" *ngIf="viewAs === ROLE.ADMIN">
        <div class="form-group">
          <label for="wGarage" class="label">
            W-Garage
          </label>
          <div class="d-flex">
            <nb-toggle formControlName="hasWGarage"
                       status="basic"></nb-toggle>
            <input *ngIf="valetForm.controls['hasWGarage'].value"
                   class="ml-4"
                   id="wGarage"
                   fullWidth
                   nbInput
                   formControlName="wGarage"
                   type="text">
          </div>
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['wGarage']">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4" *ngIf="viewAs === ROLE.ADMIN && valet?.candidate">
        <div class="form-group" *ngIf="valet.candidate.sourcingType !== null">
          <label class="label">
            Sourcing Pop Valet
          </label>
          <div class="d-flex" *ngIf="valet.candidate.sourcingType !== 'other'">
            <input fullWidth
                   nbInput
                   type="text"
                   disabled
                   [value]="getSourcingName()">
          </div>
          <div class="d-flex" *ngIf="valet.candidate.sourcingType === 'other'">
            <input fullWidth
                   nbInput
                   type="text"
                   disabled
                   [value]="valet.candidate.sourcingNote">
          </div>
        </div>
      </div>
    </div>
  </common-card-body>
</common-card>
<common-card [formGroup]="valetForm">
  <common-card-header>
    <div class="row no-gutters">
      <div class="col-auto mr-2">
        <nb-icon pack="regular"
                 icon="address-book"></nb-icon>
      </div>
      <div class="col">
        <h6>
          {{'modules.valet.valet_profile.contact_info' |translate}}
        </h6>
      </div>
    </div>
  </common-card-header>
  <common-card-body>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.email' |translate}}
          </label>
          <input fullWidth
                 nbInput
                 formControlName="email"
                 type="text">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['email']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['email']"
              [validation]="'pattern'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.phone_number' |translate}}
          </label>
          <pv-shared-input-phone-number
            [formGroup]="valetForm"
            controlName="phone"
            placeholder=""
            [required]="true">
          </pv-shared-input-phone-number>
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['phone']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['phone']"
              [validation]="'pattern'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.metropolis' |translate}}
          </label>
          <nb-select formControlName="metropolis" fullWidth>
            <nb-option *ngFor="let type of availableMetropolis"
                       [value]="type.id">
              {{type.name}}
            </nb-option>
          </nb-select>
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['metropolis']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.address' |translate}}
          </label>
          <input placeSearch
                 fullWidth
                 nbInput
                 autocomplete="off"
                 placeholder=" "
                 [value]="valetForm.controls['addressLabel'].value"
                 (placeSelected)="addressChanged($event)"
                 type="text">
          <slnk-shared-input-validator-item
            [validationFormControl]="valetForm.controls['address']"
            [validation]="'required'">
          </slnk-shared-input-validator-item>
        </div>
      </div>
      <div class="col-md-8" *ngIf="viewAs === ROLE.ADMIN">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.address' |translate}}
          </label>
          <input fullWidth
                 nbInput
                 formControlName="address"
                 type="text"
                 (change)="copyAddressesIfAllowed()">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['address']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4" *ngIf="viewAs === ROLE.ADMIN">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.city' |translate}}
          </label>
          <input fullWidth
                 nbInput
                 formControlName="city"
                 type="text"
                 (change)="copyAddressesIfAllowed()">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['city']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4" *ngIf="viewAs === ROLE.ADMIN">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.postal_code' |translate}}
          </label>
          <input fullWidth
                 nbInput
                 formControlName="postalCode"
                 type="text"
                 (change)="copyAddressesIfAllowed()">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['postalCode']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4" *ngIf="viewAs === ROLE.ADMIN">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.country' |translate}}
          </label>
          <input fullWidth
                 nbInput
                 formControlName="country"
                 type="text"
                 (change)="copyAddressesIfAllowed()">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['country']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-12" *ngIf="viewAs === ROLE.ADMIN">
        <nb-checkbox (checkedChange)="copyAddresses($event)"
          formControlName="copyAddresses">
          <div class="label mb-0">
            {{'modules.valet.valet_profile.addresses_are_identical' | translate}}
          </div>
        </nb-checkbox>
      </div>
    </div>
  </common-card-body>
</common-card>
<common-card [formGroup]="valetForm">
  <common-card-header>
    <div class="row no-gutters">
      <div class="col-auto mr-2">
        <nb-icon
          icon="file-signature"></nb-icon>
      </div>
      <div class="col">
        <h6>
          {{'modules.valet.valet_profile.billing_information' |translate}}
        </h6>
      </div>
    </div>
  </common-card-header>
  <common-card-body>
    <div class="row">
      <div class="col-md-8" *ngIf="viewAs === ROLE.ADMIN">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.address' |translate}}
          </label>
          <input fullWidth
                 nbInput
                 formControlName="billingAddress"
                 type="text">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['billingAddress']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4" *ngIf="viewAs === ROLE.ADMIN">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.city' |translate}}
          </label>
          <input fullWidth
                 nbInput
                 formControlName="billingCity"
                 type="text">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['billingCity']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4" *ngIf="viewAs === ROLE.ADMIN">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.postal_code' |translate}}
          </label>
          <input fullWidth
                 nbInput
                 formControlName="billingPostalCode"
                 type="text">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['billingPostalCode']"
              [validation]="'required'">
              requ
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4" *ngIf="viewAs === ROLE.ADMIN">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.country' |translate}}
          </label>
          <input fullWidth
                 nbInput
                 formControlName="billingCountry"
                 type="text">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['billingCountry']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="label required" [ngClass]="valetForm?.controls['status']?.value === 'external-worker' ? 'required' : ''">
            {{'modules.valet.valet_profile.siret' |translate}}
          </label>
          <input
            class="hide_number_arrows"
            fullWidth
            nbInput
            formControlName="siret"
            type="number">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              *ngIf="valetForm?.controls['status']?.value === 'external-worker'"
              [validationFormControl]="valetForm.controls['siret']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.remuneration_iban' |translate}}
          </label>
          <input
            fullWidth
            nbInput
            formControlName="remunerationIBAN"
            type="text">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['remunerationIBAN']"
              [validation]="'required'">
              requ
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.fees_iban' |translate}}
          </label>
          <input
            fullWidth
            nbInput
            formControlName="feesIBAN"
            type="text">
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="valetForm.controls['feesIBAN']"
              [validation]="'required'">
              requ
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
    </div>
  </common-card-body>
</common-card>
