import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree, Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState, UserState } from '../store/user/auth.state';
import { first, map, tap } from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import { FrontChatService } from '../services/front-chat.service';
import {NbDialogService} from "@nebular/theme";
import {CommonNewsDialogComponent} from "../../common-modules/common-news-dialog/common-news-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private store: Store<UserState>,
              private router: Router,
              private authService: AuthService,
              private frontChatService: FrontChatService,
              private dialogService: NbDialogService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('AUTH CAN ACTIVATE');
    return this.isUserAuth(state.url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('AUTH CAN ACTIVATE CHILD');
    return this.isUserAuth(state.url);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    console.log('AUTH CAN LOAD');

    const navigation = this.router.getCurrentNavigation();
    let url = '/';

    if (navigation) {
      url = navigation.extractedUrl.toString();
    }

    return this.isUserAuth(url);
  }

  private isUserAuth(redirectUrl: string): Observable<boolean> {
    return this.store.select(selectAuthState).pipe(
      first(),
      map(userData => {
        if (!this.authService.isAccessTokenExpired() && userData.user) {

          if (
            userData.user.customer?.customSettings?.frontUrl &&
            !window.location.href.startsWith(userData.user.customer.customSettings.frontUrl)
          ) {
            window.location.href = userData.user.customer.customSettings.frontUrl;
          }

          if (window.location.href.indexOf('/reservation/') === -1) {
            this.frontChatService.loadChat();
          }

          if (userData.user.role !== 'popvalet-admin') {
            this.authService.getNewsletters().then(newsletters => {
              if (newsletters.length > 0) {
                setTimeout(() => {
                  this.openNewsDialog(newsletters);
                }, 1000);
              }
            })
          }

          return true;
        } else {
          this.router.navigate(['/', 'auth'], {
            queryParams: {
              redirectUrl
            }
          });
          return false;
        }
      })
    );
  }

  openNewsDialog(newsletters: any) {
    const dialog = this.dialogService.open(CommonNewsDialogComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      context: {
        newsletters
      },
    });
    return dialog.componentRef.instance.submitEvent.pipe(
      map(res => {
        return {
          dialog,
          data: res
        };
      })
    );
  }
}
