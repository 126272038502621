import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {selectAuthState, UserState} from '../store/user/auth.state';
import {first, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private authService: AuthService,
              private store: Store<UserState>,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.store.select(selectAuthState).pipe(
      first(),
      map(userData => {
        if (!this.authService.isAccessTokenExpired() && userData.user) {
          const url = this.authService.getDefaultUrlFromRole(userData.user.role);
          if (url) {
            this.router.navigateByUrl(url);
          }
          return true;
        } else {
          this.router.navigateByUrl('/auth/login');
          return false;
        }
      })
    );
  }

}
