import {Order} from '../../admin/models/order';
import {
  MISSION_TYPE,
  MissionBaseCourtesyType,
  MissionBaseType,
  MissionLinkedType,
  MissionSecondCourtesyType
} from '../models/order.model';

export type orderForType = Pick<Order, 'type' | 'courtesy'>;

export class OrderHelper {
  static haveReturnJourney(order: orderForType): boolean {
    const typeWithReturnJourney = [
      MISSION_TYPE.LIVRAISON_AVEC_REPRISE,
      MISSION_TYPE.TRANSFER_ALLER_RETOUR,
      MISSION_TYPE.PICK_AND_DELIVERY
    ];

    const typeHavingReturnJourneyForCourtesy = [
      MISSION_TYPE.PICK_UP,
      MISSION_TYPE.DELIVERY
    ]
    return (order.courtesy && typeHavingReturnJourneyForCourtesy.includes(order.type as MISSION_TYPE)) ||
      typeWithReturnJourney.includes(order.type as MISSION_TYPE);
  }

  static haveLinkedMission(order: Pick<Order, 'type' | 'courtesy'>): boolean {
    const typeWithReturnJourney = [
      MISSION_TYPE.LIVRAISON_AVEC_REPRISE,
      MISSION_TYPE.TRANSFER_ALLER_RETOUR,
    ];

    const typeHavingReturnJourneyForCourtesy = [
      MISSION_TYPE.DELIVERY,
      MISSION_TYPE.PICK_UP,
    ]
    const missionHaveCourtesy = typeHavingReturnJourneyForCourtesy.includes(order.type as MISSION_TYPE) && order.courtesy;

    return typeWithReturnJourney.includes(order.type as MISSION_TYPE) || missionHaveCourtesy;
  }

  static getFirstMissionType(order: Pick<Order, 'type' | 'courtesy'>) {
    if (order.courtesy) { return MissionBaseCourtesyType[order.type] }

    const haveReturnJourney = OrderHelper.haveReturnJourney(order);
    if (haveReturnJourney) {
      return MissionBaseType[order.type];
    }

    return order.type;
  }

  static getSecondMissionType(order: Pick<Order, 'type' | 'courtesy'>) {
    if (order.courtesy) { return MissionSecondCourtesyType[order.type] }

    const haveReturnJourney = OrderHelper.haveReturnJourney(order);
    if (haveReturnJourney) {
      return MissionLinkedType[order.type];
    }

    return order.type;
  }
}
