import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

@Pipe({
  name: 'hasValidator'
})
export class HasValidator implements PipeTransform {

  transform(validatorFn: ValidatorFn, validatorName: string) {
    const validator = validatorFn ? validatorFn({} as AbstractControl) : null;
    return validator && !!validator[validatorName];
  }

}
