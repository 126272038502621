<div class="row">
  <div class="col-12">
    <div class="form-group">
      <input placeSearch
             autocomplete="off"
             fullWidth
             nbInput
             [disabled]="form.disabled"
             (placeSelected)="placeChange($event)"
             type="text">
      <slnk-shared-input-validator [pvVibrate]="addressErrorVibrate">
        <slnk-shared-input-validator-item
          [validationFormControl]="form">
        </slnk-shared-input-validator-item>
      </slnk-shared-input-validator>
    </div>
    <div class="maps_wrapper mb-3" [ngStyle]="{'padding-top': mapHeight}">
      <agm-map
        [latitude]='latitude'
        [zoom]="zoom"
        [longitude]='longitude'>
        <agm-marker
          *ngIf="showMarker"
          [latitude]="latitude"
          [longitude]="longitude"
          (dragEnd)="markerPositionChanged($event)"
          [markerDraggable]="!form.disabled">
        </agm-marker>
      </agm-map>
    </div>
  </div>
</div>
