import {Component, OnInit} from '@angular/core';
import {PvRecruitmentDialogComponent} from '../../pv-recruitment-dialog/pv-recruitment-dialog.component';
import {
  PvRecruitmentDialogReactivateAccountComponent
} from '../../pv-recruitment-dialog-reactivate-account/pv-recruitment-dialog-reactivate-account.component';
import {PvRecruitmentService} from '../../pv-recruitment.service';
import {NbDialogService} from '@nebular/theme';
import {Router} from "@angular/router";

@Component({
  selector: 'pv-pv-recruitment-common-header',
  templateUrl: './pv-recruitment-common-header.component.html',
  styleUrls: ['./pv-recruitment-common-header.component.scss']
})
export class PvRecruitmentCommonHeaderComponent implements OnInit {
  private fbclid: string | undefined;

  constructor(private dialogService: NbDialogService,
              private router: Router,
              private recruitementService: PvRecruitmentService) { }

  ngOnInit(): void { }

  public showRecruitmentPage() {
    this.router.navigate(['/', 'inscription'], {
      state: {
        dialogStep: 0,
        retry: false
      }
    });
    this.recruitementService.gaPageEvent('recruitement-dialog');
    this.recruitementService.fbEvent('ViewContent', this.fbclid).catch(error => {
      console.error(error);
    });
  }

  public showConnexionDialog() {
    this.dialogService.open(PvRecruitmentDialogReactivateAccountComponent, {});
    this.recruitementService.gaPageEvent('login-dialog');
  }
}
