<common-card>
    <common-card-header>
      <h5>{{'modules.valet.valet_profile.disable_dialog.title' | translate}}</h5>
    </common-card-header>
  <common-card-body [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="label required">
            {{'modules.valet.valet_profile.disable_dialog.reason' | translate}}
          </label>
          <nb-select formControlName="disableReason" fullWidth>
            <nb-option *ngFor="let reason of reasons"
                       [value]="reason.value">
              {{reason.name}}
            </nb-option>
          </nb-select>
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="form.controls['type']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="label required" for="disableComment">
            {{'modules.valet.valet_profile.disable_dialog.comment' | translate}}
          </label>
          <textarea nbInput
                    fullWidth
                    id="disableComment"
                    formControlName="disableComment">
          </textarea>
          <slnk-shared-input-validator>
            <slnk-shared-input-validator-item
              [validationFormControl]="form.controls['note']"
              [validation]="'required'">
            </slnk-shared-input-validator-item>
          </slnk-shared-input-validator>
        </div>
      </div>
    </div>
  </common-card-body>
  <common-card-footer>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button nbButton (click)="cancel()">
          {{'global.buttons.cancel' | translate}}
        </button>
      </div>
      <div class="col-auto">
        <button nbButton
                status="primary"
                [disabled]="!form.valid"
                (click)="submit()">
          {{'global.buttons.submit' | translate}}
        </button>
      </div>
    </div>
  </common-card-footer>
</common-card>
