<div class="row align-content-center">
  <div class="col-auto d-flex align-items-center" *ngIf="showBackButton">
    <a class="d-flex" style="text-decoration: none; cursor: pointer"
       (click)="back()">
      <nb-icon status="warning" icon="arrow-left"></nb-icon>
    </a>
  </div>
  <div class="col">
    <ng-content>
    </ng-content>
  </div>
</div>
