<common-card style="width: 400px">
    <common-card-header>
        <div class="header-content">
            <h6 class="font-weight-bold mt-2 mb-2">{{ 'modules.anomaly.treat_dialog_infraction.title' | translate }}</h6>
            <button nbButton ghost class="p-0" (click)="closeDialog()">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </common-card-header>
    <common-card-body>
        <ng-container [formGroup]="form">
            <div>
                <b>{{ 'modules.anomaly.treat_dialog_infraction.denounce' | translate }}</b>
                <div class="toggle-container">
                    <p class="mr-2 pb-2">{{ 'modules.anomaly.treat_dialog_infraction.no' | translate }}</p>
                    <nb-toggle formControlName="denounce" status="danger"></nb-toggle>
                    <p class="ml-2 pb-2">{{ 'modules.anomaly.treat_dialog_infraction.yes' | translate }}</p>
                </div>
            </div>
            <div *ngIf="denounce" class="mt-0">
                <b>{{ 'modules.anomaly.treat_dialog_infraction.email' | translate }}</b>
                <input nbInput
                    fullWidth
                    formControlName="email" class="mt-2">
            </div>
            <div *ngIf="denounce" class="mt-3">
                <b>{{ 'modules.anomaly.treat_dialog_infraction.docs' | translate }}</b>
                <div>
                    <button nbButton (click)="docsInput.click()" class="mt-2">
                        <span>{{ 'modules.anomaly.treat_dialog_infraction.choose_docs' | translate }}</span>
                    </button>
                    <input class="invisible_drop"
                            #docsInput type="file"
                            [multiple]="true"
                            [accept]="acceptedFileTypes"
                            (change)="uploadFiles($event)">
                    <div *ngFor="let doc of this.form.get('docs').value">
                        <div *ngIf="doc?.name" class="progress-label mt-2 p-2">
                            <div class="ml-2 mt-1">{{doc.name}}</div>
                            <div>
                                <!-- <nb-icon icon="eye" (click)="openUrl(doc.url)"></nb-icon> -->
                                <nb-icon icon="times" (click)="deleteFile(doc.name)" class="ml-3  mt-1"></nb-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!denounce" class="mt-1">
                <common-card>
                    <common-card-body>
                        <div class="row align-items-center">
                            <nb-icon icon="exclamation-triangle" class="ml-2"></nb-icon>
                            <div class="ml-4 description">{{ 'modules.anomaly.treat_dialog_infraction.accounting' | translate }}</div>
                        </div>
                    </common-card-body>
                </common-card>
            </div>
        </ng-container>
    </common-card-body>
    <common-card-footer>
        <div class="text-center">
            <button nbButton type="button" status="primary" class="pt-2 pb-2" (click)="submit()" [disabled]="createDisabled">
                {{ 'modules.anomaly.treat_dialog_infraction.valid' | translate }}
            </button>
        </div>
    </common-card-footer>
</common-card>
