<div class="d-flex align-items-center">
  <div class="label"><strong>Date:&nbsp;</strong></div>
  <button
    size="small"
    nbButton
    [nbPopover]="datePicker"
    [nbPopoverTrigger]="'click'">
    <div [innerHTML]="(dateSelected$ | async | getDateLabelHtml) || 'Filtrer par date'"></div>

  </button>
  <nb-icon *ngIf="!!(dateSelected$ | async)"
           nbSuffix
           class="delete-icon icon-basic-color"
           icon="close-outline"
           pack="eva"
           (click)="deleteSelection$.next()"></nb-icon>
  <ng-template #datePicker>
    <pv-date-filter
      (dateSelected)="dateSelected$.next($event)">
    </pv-date-filter>
  </ng-template>
</div>
