import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAutocompleteOptionComponent } from './components/common-autocomplete-option/common-autocomplete-option.component';
import { CommonAutocompleteComponent } from './common-autocomplete.component';
import {NbButtonModule, NbCheckboxModule, NbFormFieldModule, NbIconModule, NbInputModule} from '@nebular/theme';
import { CommonAutocompleteOptionListComponent } from './components/common-autocomplete-option-list/common-autocomplete-option-list.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonAutocompleteOptionDropdownComponent } from './components/common-autocomplete-option-dropdown/common-autocomplete-option-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonAutocompleteArrayOfGroupsPipe } from './pipes/common-autocomplete-array-of-groups.pipe';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    CommonAutocompleteOptionComponent,
    CommonAutocompleteComponent,
    CommonAutocompleteOptionListComponent,
    CommonAutocompleteOptionDropdownComponent,
    CommonAutocompleteArrayOfGroupsPipe],
  exports: [
    CommonAutocompleteComponent,
    CommonAutocompleteOptionComponent
  ],
  imports: [
    CommonModule,
    NbInputModule,
    PortalModule,
    OverlayModule,
    NbCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    NbButtonModule,
    TranslateModule,
    NbIconModule,
    NbFormFieldModule,
  ],
  providers: []
})
export class CommonAutocompleteModule {
}
