import { Component, OnInit } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { RoutingStatesService } from './shared/services/routing-states.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import {VersionCheckService} from './shared/services/version-check.service';
import {environment} from '../environments/environment';
import { FrontChatService } from './shared/services/front-chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pop-valet';
  public isLeasys: boolean;

  constructor(private iconLibraries: NbIconLibraries,
              private translate: TranslateService,
              private routingState: RoutingStatesService,
              private versionCheckService: VersionCheckService,
              private frontChatService: FrontChatService) {

    this.iconLibraries.registerFontPack('solid',
      {packClass: 'fas', iconClassPrefix: 'fa'}
    );
    this.iconLibraries.registerFontPack('regular',
      {packClass: 'far', iconClassPrefix: 'fa'}
    );
    this.iconLibraries.registerFontPack('light',
      {packClass: 'fal', iconClassPrefix: 'fa'}
    );
    this.iconLibraries.registerFontPack('duotone',
      {packClass: 'fad', iconClassPrefix: 'fa'}
    );
    this.iconLibraries.registerFontPack('brands',
      {packClass: 'fab', iconClassPrefix: 'fa'}
    );

    this.iconLibraries.setDefaultPack('solid');
    routingState.loadRouting();
  }

  ngOnInit(): void {
    this.translate.setDefaultLang('en');
    this.translate.use('fr');

    moment.tz.setDefault('Europe/Paris');
    moment.locale('fr');

    if (window.location.hostname.startsWith('recrutement')) {
      this.frontChatService.loadChat();
    }

    if (window.location.hostname.startsWith('leasys')) {
      window.parent.document.title = 'Leasys';
      (document.querySelector('link[rel="icon"]') as HTMLLinkElement).href = '/assets/images/leasys_favicon.ico';
    } else {
      window.parent.document.title = 'Pop Valet';
      (document.querySelector('link[rel="icon"]') as HTMLLinkElement).href = '/assets/images/favicon.ico';
    }

    if (environment.versionCheckUrl && !['localhost', '127.0.0.1', 'recrutement'].some(h => location.hostname.includes(h))) {
      this.versionCheckService.initVersionCheck(environment.versionCheckUrl, 60000);
    }
  }

}
