import { citiesInitialState, City } from './city.state';
import { CityActionTypes } from './city.actions';

export function citiesReducer(
  state = citiesInitialState,
  action: { type: CityActionTypes, payload?: City[] }
) {
  switch (action.type) {
    case CityActionTypes.GET_CITIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
