<nb-alert *ngIf="isChecking && !idCheck.idDocumentCheckStatus" outline="info">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <div [nbSpinner]="true" nbSpinnerStatus="primary" class="spinner"></div>
    </div>
    <span>
      {{'recruitment.process.step.id_check.checks_in_progress' | translate}}
    </span>
  </div>
</nb-alert>

<h6 *ngIf="!isChecking">
  {{'recruitment.process.step.id_check.please_load_an_id_document' | translate}}
</h6>

<h6 *ngIf="idCheck.idDocumentCheckStatus" class="mb-2">{{'recruitment.process.step.id_check.id_doc.name' | translate}}</h6>

<nb-alert *ngIf="idCheck.idDocumentCheckStatus === 'PROCESSING'" outline="info">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <div [nbSpinner]="true" nbSpinnerStatus="primary" class="spinner"></div>
    </div>
    <span>
      {{'recruitment.process.step.id_check.id_doc.processing' | translate}}
    </span>
  </div>
</nb-alert>
<nb-alert *ngIf="idCheck.idDocumentCheckStatus === 'PENDING'" outline="warning">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <nb-icon icon="pause-circle" status="warning" style="font-size:2rem;"></nb-icon>
    </div>
    <span>
      {{'recruitment.process.step.id_check.id_doc.pending' | translate}}
    </span>
  </div>
</nb-alert>
<nb-alert *ngIf="idCheck.idDocumentCheckStatus === 'VALID'" outline="success">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <nb-icon icon="check" status="success" style="font-size:2rem;"></nb-icon>
    </div>
    <span>
      {{'recruitment.process.step.id_check.id_doc.accepted' | translate}}
    </span>
  </div>
</nb-alert>
<nb-alert *ngIf="idCheck.idDocumentCheckStatus === 'INVALID'" outline="danger">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <nb-icon icon="times" status="danger" style="font-size:2rem;"></nb-icon>
    </div>
    <span>
      {{'recruitment.process.step.id_check.id_doc.rejected' | translate}}<br>
      <strong>{{idCheck.idDocumentCheckRejectedComment}}</strong><br><br>
      {{'recruitment.process.step.id_check.id_doc.please_retry' | translate}}
    </span>
  </div>
</nb-alert>

<div *ngIf="!isChecking || idCheck.idDocumentCheckStatus === 'INVALID'" class="row">
  <div class="col-md-6 col-12 mb-2">
    <pv-common-file-upload
      uploadIndication="{{'recruitment.process.step.id_check.id_doc.front' | translate}}"
      [acceptedFileTypes]="'.pdf, .jpg, .jpeg, .png'"
      [maxSize]="20 * 1024 * 1024"
      (fileChanged)="fileLoaded($event, 'idDocFront')">
    </pv-common-file-upload>
  </div>
  <div class="col-md-6 col-12 mb-2">
    <pv-common-file-upload
      uploadIndication="{{'recruitment.process.step.id_check.id_doc.back' | translate}}"
      [acceptedFileTypes]="'.pdf, .jpg, .jpeg, .png'"
      [maxSize]="20 * 1024 * 1024"
      (fileChanged)="fileLoaded($event, 'idDocBack')">
    </pv-common-file-upload>
  </div>
</div>

<div *ngIf="!valet.siret || initialInvalidSiret" class="mt-2 d-flex justify-content-center">
  <ng-container *ngTemplateOutlet="siretField"></ng-container>
</div>

<div *ngIf="files.idDocFront && files.idDocBack && form.valid" class="d-flex justify-content-center">
  <button
    class="start-id-check-button"
    nbButton
    status="primary"
    [nbSpinner]="isSending"
    [disabled]="isSending"
    (click)="startIdCheck(true, true, true)">
    {{'recruitment.process.step.id_check.start_id_check' | translate}}
  </button>
</div>

<div *ngIf="idCheck.idVerificationCheckStatus" class="divider my-5"></div>

<h6 *ngIf="idCheck.idVerificationCheckStatus" class="mb-2">
  {{'recruitment.process.step.id_check.driving_license.name' | translate}}
</h6>

<nb-alert *ngIf="idCheck.idVerificationCheckStatus === 'PENDING'" outline="warning">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <nb-icon icon="pause-circle" status="warning" style="font-size:2rem;"></nb-icon>
    </div>
    <span>
      {{'recruitment.process.step.id_check.driving_license.pending' | translate}}
    </span>
  </div>
</nb-alert>
<nb-alert *ngIf="idCheck.idVerificationCheckStatus === 'VALID'" outline="success">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <nb-icon icon="check" status="success" style="font-size:2rem;"></nb-icon>
    </div>
    <span>
      {{'recruitment.process.step.id_check.driving_license.accepted' | translate}}
    </span>
  </div>
</nb-alert>
<nb-alert *ngIf="idCheck.idVerificationCheckStatus === 'INVALID'" outline="danger">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <nb-icon icon="times" status="danger" style="font-size:2rem;"></nb-icon>
    </div>
    <span>
      {{'recruitment.process.step.id_check.driving_license.rejected' | translate}}<br>
      <strong>{{idCheck.idVerificationCheckRejectedComment}}</strong><br><br>
      {{'recruitment.process.step.id_check.driving_license.please_retry' | translate}}<br>
    </span>
  </div>
</nb-alert>
<div *ngIf="idCheck.idVerificationCheckStatus === 'INVALID'" class="d-flex justify-content-center">
  <button
    class="start-id-check-button"
    nbButton
    status="primary"
    [nbSpinner]="isSending"
    [disabled]="isSending"
    (click)="startIdCheck(false, true, false)">
    {{'recruitment.process.step.id_check.driving_license.retry' | translate}}
  </button>
</div>
<div *ngIf="idCheck.idVerificationCheckStatus === 'FAILED'">
  <nb-alert outline="danger">
    <div class="d-flex align-items-center">
      <div class="mr-3">
        <nb-icon icon="times" status="danger" style="font-size:2rem;"></nb-icon>
      </div>
      <span>
        {{'recruitment.process.step.id_check.driving_license.failed' | translate}}
      </span>
    </div>
  </nb-alert>
  <div class="row">
    <div class="col-md-6 col-12 mb-2">
      <pv-common-file-upload
        uploadIndication="{{'recruitment.process.step.id_check.driving_license.front' | translate}}"
        [acceptedFileTypes]="'.pdf, .jpg, .jpeg, .png'"
        [maxSize]="20 * 1024 * 1024"
        (fileChanged)="fileLoaded($event, 'licenseFront')">
      </pv-common-file-upload>
    </div>
    <div class="col-md-6 col-12 mb-2">
      <pv-common-file-upload
        uploadIndication="{{'recruitment.process.step.id_check.driving_license.back' | translate}}"
        [acceptedFileTypes]="'.pdf, .jpg, .jpeg, .png'"
        [maxSize]="20 * 1024 * 1024"
        (fileChanged)="fileLoaded($event, 'licenseBack')">
      </pv-common-file-upload>
    </div>
  </div>
  <div *ngIf="files.licenseFront && files.licenseBack" class="d-flex justify-content-center">
    <button
      class="start-id-check-button"
      nbButton
      status="primary"
      [nbSpinner]="isSending"
      [disabled]="isSending"
      (click)="sendLicense()">
      {{'recruitment.process.step.id_check.driving_license.send' | translate}}
    </button>
  </div>
</div>
<nb-alert *ngIf="idCheck.idVerificationCheckUrl && idCheck.idVerificationCheckStatus === 'PROCESSING'" outline="info">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <nb-icon icon="info-circle" style="font-size:2rem;"></nb-icon>
    </div>
    <div class="text-break">
      {{'recruitment.process.step.id_check.driving_license.sms_received' | translate}}
      <a [href]="idCheck.idVerificationCheckUrl" target="_blank">{{idCheck.idVerificationCheckUrl}}</a><br><br>
      {{'recruitment.process.step.id_check.driving_license.sms_validity' | translate}}
      <div class="d-flex justify-content-center">
        <button
          class="refresh-check-url-button"
          nbButton
          status="primary"
          [nbSpinner]="isSending"
          [disabled]="isSending"
          (click)="refreshIdVerificationCheckUrl()">
          {{'recruitment.process.step.id_check.driving_license.refresh_check_url' | translate}}
        </button>
      </div>
      {{'recruitment.process.step.id_check.driving_license.usage_explanation' | translate}}
    </div>
  </div>
</nb-alert>

<div *ngIf="idCheck.siretStatus" class="divider my-5"></div>

<h6 *ngIf="idCheck.siretStatus" class="mb-2">
  {{'recruitment.process.step.id_check.siret.name' | translate}}
</h6>
<nb-alert *ngIf="idCheck.siretStatus === 'VALID'" outline="success">
  <div class="d-flex align-items-center">
    <div class="mr-3">
      <nb-icon icon="check" status="success" style="font-size:2rem;"></nb-icon>
    </div>
    <span>
      {{'recruitment.process.step.id_check.siret.accepted' | translate}}
    </span>
  </div>
</nb-alert>
<ng-container *ngIf="idCheck.siretStatus === 'INVALID'">
  <nb-alert outline="danger">
    <div class="d-flex align-items-center">
      <div class="mr-3">
        <nb-icon icon="times" status="danger" style="font-size:2rem;"></nb-icon>
      </div>
      <span>
        {{'recruitment.process.step.id_check.siret.rejected' | translate}}<br>
        <strong>{{idCheck.siretErrorMessage}}</strong><br><br>
        {{'recruitment.process.step.id_check.siret.please_retry' | translate}}<br>
    </span>
    </div>
  </nb-alert>
  <div class="d-flex flex-column align-items-center">
    <ng-container *ngTemplateOutlet="siretField"></ng-container>
    <button
      class="start-id-check-button"
      nbButton
      status="primary"
      [nbSpinner]="isSending"
      [disabled]="isSending || !form.valid"
      (click)="startIdCheck(false, false, true)">
      {{'recruitment.process.step.id_check.siret.retry' | translate}}
    </button>
  </div>
</ng-container>

<ng-template #siretField>
  <div class="form-group mb-0" [formGroup]="form">
    <label class="label">
      {{'recruitment.process.step.id_check.siret.name' | translate}}
      ({{'recruitment.popup.placeholder.siret' | translate}})
    </label>
    <input nbInput
           type="number"
           formControlName="siret"
           maxlength="14"
           placeholder="{{'recruitment.popup.placeholder.siret' | translate}}">
    <slnk-shared-input-validator>
      <slnk-shared-input-validator-item
        [validationFormControl]="form.controls['siret']"
        [validation]="'required'">
      </slnk-shared-input-validator-item>
      <slnk-shared-input-validator-item
        [validationFormControl]="form.controls['siret']"
        [validation]="'pattern'">
      </slnk-shared-input-validator-item>
    </slnk-shared-input-validator>
  </div>
</ng-template>
