<div class="d-flex align-items-center flex-column container-tutorial-video">
  <div *ngIf="candidate.eligibilityStatus === 'valid'" class="d-flex align-items-center justify-content-center flex-column">
    <h4>{{'recruitment.process.step.tutorial.title' | translate}}</h4>
    <video controls>
      <source src="https://d17m62rzgdg0on.cloudfront.net/videos/POP VALET - Vidéo de présentation v4-2500kbps.mp4#t=0.001" type="video/mp4">
    </video>
    <a href="https://popvalet.com/nous-rejoindre" target="_blank" class="mt-4">{{'recruitment.process.step.tutorial.link' | translate}}</a>
  </div>

  <div *ngIf="candidate.eligibilityStatus === 'invalid'" class="d-flex justify-content-center align-items-center flex-column" style="height: 500px">
    <nb-icon icon="times-circle"
             [nbTooltip]="'admin.modules.recruitment.grid.status_values.eligibility_invalid' | translate"
             status="danger"
             nbTooltipStatus="danger"></nb-icon>
    <p>{{'recruitment.process.step.tutorial.eligibility_invalid' | translate}}</p>
    <button nbButton class="yellow-button"
            (click)="showRecruitmentDialog()">{{'recruitment.process.step.tutorial.retry' | translate}}</button>
  </div>
</div>
