import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Anomaly } from '../../../models/anomaly';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { AnomalyService } from '../../../services/common-anomalies.service';
import { ACCEPTED_ANOMALY_FILE_TYPES } from 'src/app/shared/constants/constants';

@Component({
  selector: 'pv-common-anomaly-treat-infraction-dialog',
  templateUrl: './common-anomaly-treat-infraction-dialog.component.html',
  styleUrls: ['./common-anomaly-treat-infraction-dialog.component.scss']
})
export class CommonAnomalyTreatInfractionDialogComponent implements OnInit {

  @Input() anomaly: Anomaly;
  @Output() submitEvent = new EventEmitter();
  
  public form: FormGroup;
  public denounce: boolean = true;
  public acceptedFileTypes = ACCEPTED_ANOMALY_FILE_TYPES;
  public createDisabled: boolean = true;

  constructor(private fb: FormBuilder,
              private anomalyService: AnomalyService,
              protected dialogRef: NbDialogRef<CommonAnomalyTreatInfractionDialogComponent>) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      denounce : [true],
      email: [this.anomaly.valet.email, [Validators.required, Validators.email]],
      docs: []
    });

    this.createDisabled = !this.form.valid;
    
    this.form.valueChanges.subscribe(() => {
      this.denounce = this.form.get('denounce').value;
      this.createDisabled = !this.form.valid;
    });
  }

  async submit() {
    this.anomalyService.treatInfractionAnomaly(this.anomaly.id, this.denounce, this.form.get('email').value, this.form.get('docs').value).then((res)=>{
      this.submitEvent.emit();
      this.closeDialog();
    });
  }

  public uploadFiles(event: any) {
    if (event && event.target && event.target.files) {
      const files = Array.from(event.target.files);
      const formControl = this.form.get('docs');
      if (formControl) {
        formControl.setValue(files);
      }
    }
  }

  public deleteFile(fileName: string) {
    const formControl = this.form.get('docs');
    if (formControl && formControl.value) {
      const files = formControl.value.filter((file: File) => file.name !== fileName);
      formControl.setValue(files);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // openUrl(url: string) {
  //   console.log(url);
  //   window.open(url, '_blank');
  // }
}
