<nb-layout>
  <nb-layout-column style="padding: 0">
    <pv-pv-recruitment-common-header></pv-pv-recruitment-common-header>
    <div class="registration-welcome-cover">
      <div class="text-center">
        <img src="assets/images/popvalet_icon.png" alt="" style="height: 82px">
      </div>
      <p class="isidorasansalt-semi-bold-blue-48px">Devenir valet</p>
    </div>
    <div class="registration-reminder-section">
      <div class="registration-reminder-container">
        <p class="isidorasansalt-bold-blue-24px">Pour rappel, pour devenir Convoyeur chez Pop Valet vous devez :</p>
        <ul class="registration-reminder-list">
          <li class="isidorasansalt-semi-bold-blue-24px">Avoir plus de 8 points sur votre permis de conduire</li>
          <li class="isidorasansalt-semi-bold-blue-24px">5 ans de permis de conduire (+ de 23 ans)</li>
          <li class="isidorasansalt-semi-bold-blue-24px">Un statut d'auto-entrepreneur actif : voici <a href="https://www.portail-autoentrepreneur.fr/lp/partenaires/popvalet" style="color: #0958A1">un guide</a> pour vous aider</li>
        </ul>
      </div>
    </div>
    <pv-pv-recruitment-registration-form></pv-pv-recruitment-registration-form>
    <pv-pv-recruitment-common-footer></pv-pv-recruitment-common-footer>
  </nb-layout-column>
</nb-layout>
