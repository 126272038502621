import {Component, Input, OnInit} from '@angular/core';
import {FilteringWrapper} from '@pop-valet/smart-table-backend-wrapper';
import {AdminValetService} from '../../../../admin/modules/admin-valet/services/admin-valet.service';
import * as moment from 'moment/moment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'pv-common-valet-invoices',
  templateUrl: './common-valet-invoices.component.html',
  styleUrls: ['./common-valet-invoices.component.scss']
})
export class CommonValetInvoicesComponent extends FilteringWrapper implements OnInit {
  @Input() valetId: string;

  constructor(private adminValetService: AdminValetService,
              private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.initGridCustomization();
  }

  loadData(): void {
    this.loading = true;
    this.adminValetService.getValetInvoices(this.valetId, this.filteringObj).then(res => {
      this.filteringObj.setPager(
        res.paginationData.currentPage,
        res.paginationData.pageCount,
        res.paginationData.perPage,
        res.paginationData.totalItems,
      );
      this.source.load(res.data);
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    });
  }

  setColumns(): void {
    this.settings = {
      pager: false,
      mode: 'external',
      actions: false,
      hideSubHeader: true,
      columns: {
        date: {
          title: this.translateService.instant('modules.valet.valet_invoices.grid.date'),
          type: 'string',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          },
          valuePrepareFunction: (cell, row) => {
            return cell ? moment(cell).format('DD/MM/YYYY') : '';
          }
        },
        invoiceReference: {
          title: this.translateService.instant('modules.valet.valet_invoices.grid.invoice_reference'),
          type: 'string',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          },
          valuePrepareFunction: (cell, row) => {
            return cell;
          }
        },
        paymentReference: {
          title: this.translateService.instant('modules.valet.valet_invoices.grid.payment_reference'),
          type: 'string',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          },
          valuePrepareFunction: (cell, row) => {
            return cell;
          }
        },
        amount: {
          title: this.translateService.instant('modules.valet.valet_invoices.grid.amount'),
          type: 'string',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          },
          valuePrepareFunction: (cell, row) => {
            return cell ? new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(cell) : '';
          }
        },
        file: {
          title: this.translateService.instant('modules.valet.valet_invoices.grid.pdf'),
          type: 'html',
          sort: false,
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          },
          valuePrepareFunction: (cell, row) => {
            return cell ? `<a href="${cell.url}" target="_blank">${this.translateService.instant('modules.valet.valet_invoices.grid.open')}</a>` : '';
          }
        },
      }
    };
  }
}
