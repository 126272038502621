import {Pipe, PipeTransform} from '@angular/core';
import {orderForType, OrderHelper} from '../helpers/order.helper';
import {MISSION_TYPE} from "../models/order.model";

@Pipe({
  name: 'getFirstMissionType'
})
export class GetFirstMissionType implements PipeTransform {

  transform(order: orderForType): MISSION_TYPE {
    if (!order) return null;
    return OrderHelper.getFirstMissionType(order);
  }

}

@Pipe({
  name: 'getSecondMissionType'
})
export class GetSecondMissionType implements PipeTransform {

  transform(order: orderForType): MISSION_TYPE {
    if (!order) return null
    return OrderHelper.getSecondMissionType(order);
  }

}
