import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ToastHandlerService} from './toast-handler.service';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  private currentHash = '';

  constructor(private toastHandler: ToastHandlerService) { }

  public initVersionCheck(url: string, frequency: number) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  private checkVersion(url: string) {
    fetch(url + '?t=' + new Date().getTime()).then(res => {
      return res.text();
    }).then(hash => {
      const hashChanged = this.hasHashChanged(hash);

      if (hashChanged) {
        this.toastHandler.showReloadToast();
      }
      this.currentHash = hash;
    });
  }

  private hasHashChanged(newHash) {
    if (!this.currentHash) {
      return false;
    }

    return this.currentHash !== newHash;
  }
}
