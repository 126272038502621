import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profileActionReason'
})
export class ProfileActionReasonPipe implements PipeTransform {

  transform(reason: string): string {
    switch (reason) {
      case 'process':
        return 'Manquements de process répétitifs';
      case 'speeding':
        return 'Grand excès de vitesse';
      case 'delay':
        return 'Retards répétitifs';
      case 'behavior':
        return 'Comportement inapproprié';
      case 'withdrawal':
        return 'Absences / Plantages répétitifs';
      case 'asked':
        return 'Sur demande du Valet';
      case 'hijacking':
        return 'Détournement';
      case 'accident':
        return 'Sinistre';
      case 'other':
        return 'Autre';
      default:
        return '';
    }
  }

}
