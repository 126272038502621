<div [formGroup]="formGroup">
  <international-phone-number2
    *ngIf="!formGroup.controls[controlName].disabled"
    [formControlName]="controlName"
    [placeholder]="placeholder !== undefined ? placeholder : ('pv_modules.pv_reservation.components.forms.place_form.phone' | translate)"
    [defaultCountry]="'fr'"
    [required]="required"
    [noUSCountryCode]="false"
    [maxlength]="20"
    (change)="removeSpacesPhoneNumber()"
    name="phone"
  ></international-phone-number2>
  <input
    *ngIf="formGroup.controls[controlName].disabled"
    nbInput
    fullWidth
    [formControlName]="controlName">
</div>
