import { AuthActionTypes, AuthSuccessLoginPayload } from './auth.actions';
import { userInitialState, UserState } from './auth.state';

export function authReducer(
  state = userInitialState,
  action: { type: AuthActionTypes, payload?: UserState | AuthSuccessLoginPayload }
) {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        user: action.payload.user,
        error: false
      };
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        ...action.payload
      };
    case AuthActionTypes.GET_USER_SUCCESS:
      return {
        user: action.payload
      };

    case AuthActionTypes.UPDATE_USER:
      return {
        user: action.payload
      };
    /*case AuthActionTypes.LOGOUT:
      return {
        user: null,
        error: {}
      };*/
    default:
      return state;
  }

}
