import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { ofType } from '@ngrx/effects';
import { AuthService } from '../../services/auth.service';
import {AuthActionTypes, LogIn, LogInFailure, LogInSuccess, LogInWithToken} from './auth.actions';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { tap } from 'rxjs/internal/operators/tap';
import { Router } from '@angular/router';
import { ToastHandlerService } from '../../services/toast-handler.service';
import { TranslateService } from '@ngx-translate/core';
import {FrontChatService} from '../../services/front-chat.service';

@Injectable()
export class AuthEffects {

  constructor(private actions: Actions,
              private authService: AuthService,
              private router: Router,
              private tanslate: TranslateService,
              private toastService: ToastHandlerService,
              private frontChatService: FrontChatService) {
  }

  @Effect()
  LogIn: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN),
    map((action: LogIn) => action.payload),
    switchMap(payload => {
      return this.authService.logIn(payload.email, payload.password, payload.rememberMe).pipe(
        map((login) => {
          return new LogInSuccess(
            {
              access_token: login.token,
              refresh_token: null,
              user: login.user,
              rememberMe: payload.rememberMe,
              redirectUrl: payload.redirectUrl
            }
          );
        }),
        catchError((error) => {
          return of(new LogInFailure({error}));
        })
      );
    })
  );

  @Effect()
  LogInWithToken: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_WITH_TOKEN),
    map((action: LogInWithToken) => action.payload),
    switchMap(payload => {
      return this.authService.loginWithToken(payload.token).pipe(
        map((login) => {
          return new LogInSuccess(
            {
              access_token: login.token,
              refresh_token: null,
              user: login.user,
              rememberMe: true,
              redirectUrl: payload.redirectUrl
            }
          );
        }),
        catchError((error) => {
          return of(new LogInFailure({error}));
        })
      );
    })
  );

  @Effect({dispatch: false})
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap(async (user) => {
      this.authService.setLocalStorageData(
        user.payload.access_token,
        user.payload.refresh_token,
        user.payload.user,
        user.payload.rememberMe);
      // REDIRECT BY ROLE

      let url = user.payload.redirectUrl;
      if (url === '/') {
        url = null;
      }

      if (!url) {
        url = this.authService.getDefaultUrlFromRole(user.payload.user.role);
      }

      if (url) {
        await this.router.navigateByUrl(url);
        this.toastService.showSuccessToast(
          this.tanslate.instant('auth.toast.logged_in'),
          this.tanslate.instant('global.toasts.success')
        );
      } else {
        this.toastService.showErrorToasts('Unsupported user role.');
      }

      this.frontChatService.loadChat();
    })
  );

  @Effect({dispatch: false})
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE),
    tap(error => {
      console.log(error);
      if (error.payload?.error?.error?.errorCode === 'incorrect_email_or_password') {
        this.toastService.showErrorToasts(this.tanslate.instant('auth.toast.forbidden'));
      } else if (error.payload?.error?.error?.errorCode === 'deactivated_user') {
        this.toastService.showErrorToasts(this.tanslate.instant('auth.toast.deactivated'));
      } else {
        this.toastService.showErrorToastsResponse(error.payload);
      }
    })
  );

  @Effect({dispatch: false})
  LogOut: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGOUT),
    tap(logout => {
      this.authService.logOutUser();
      if (window.location.hostname.startsWith('recrutement')) {
        this.router.navigate(['/'], {
          queryParams: {
            page: 'login'
          }
        });
      } else {
        this.router.navigate(['./auth']);
      }
    })
  );

  @Effect()
  getUserInfo$: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.GET_USER),
    switchMap((action: any) =>
      this.authService.getUserData().pipe(
        map((userData: any) => {
          return {
            type: AuthActionTypes.GET_USER_SUCCESS,
            payload: userData.data
          };
        }),
        catchError(() => {
          // todo error
          return of({type: AuthActionTypes.GET_USER_ERROR});

        })
      )
    )
  );

  @Effect({dispatch: false})
  updateUser: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.UPDATE_USER),
    tap((user) => {
      this.authService.setUserLocalStorageData(user.payload) })
  );

}
