import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PvRecruitmentRoutingModule } from './pv-recruitment-routing.module';
import { PvRecruitmentDialogComponent } from './pv-recruitment-dialog/pv-recruitment-dialog.component';
import {CommonCardModule} from '../../common-modules/common-card/common-card.module';
import { PvRecruitmentDialogReactivateAccountComponent } from './pv-recruitment-dialog-reactivate-account/pv-recruitment-dialog-reactivate-account.component';
import {
    NbAlertModule,
    NbButtonModule, NbCardModule,
    NbCheckboxModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
    NbSelectModule,
    NbStepperModule,
    NbTooltipModule
} from '@nebular/theme';
import { PvRecruitmentProcessComponent } from './pv-recruitment-process/pv-recruitment-process.component';
import { RecruitementTutorialStepComponent } from './process-steps/recruitement-tutorial-step/recruitement-tutorial-step.component';
import { RecruitementInterviewStepComponent } from './process-steps/recruitement-interview-step/recruitement-interview-step.component';
import { RecruitementProfileStepComponent } from './process-steps/recruitement-profile-step/recruitement-profile-step.component';
import {CommonValetModule} from '../../common-modules/common-valet/common-valet.module';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import { RecruitementFormationStepComponent } from './process-steps/recruitement-formation-step/recruitement-formation-step.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonAutocompleteModule} from '../../common-modules/common-autocomplete/common-autocomplete.module';
import {CommonPipesModule} from '../../common-modules/common-pipes/common-pipes.module';
import { RecruitementIdCheckStepComponent } from './process-steps/recruitement-id-check-step/recruitement-id-check-step.component';
import {CommonFileUploadModule} from '../../common-modules/common-file-upload/common-file-upload.module';
import { PvRecruitmentRegistrationComponent } from './pv-recruitment-registration/pv-recruitment-registration.component';
import { PvRecruitmentCommonHeaderComponent } from './common-components/pv-recruitment-common-header/pv-recruitment-common-header.component';
import { PvRecruitmentCommonFooterComponent } from './common-components/pv-recruitment-common-footer/pv-recruitment-common-footer.component';
import { PvRecruitmentRegistrationFormComponent } from './pv-recruitment-registration/components/pv-recruitment-registration-form/pv-recruitment-registration-form.component';
import {PipesModule} from '../../shared/pipes/pipes.module';


@NgModule({
  declarations: [
    PvRecruitmentDialogComponent,
    PvRecruitmentDialogReactivateAccountComponent,
    PvRecruitmentProcessComponent,
    RecruitementTutorialStepComponent,
    RecruitementInterviewStepComponent,
    RecruitementProfileStepComponent,
    RecruitementFormationStepComponent,
    RecruitementIdCheckStepComponent,
    PvRecruitmentRegistrationComponent,
    PvRecruitmentCommonHeaderComponent,
    PvRecruitmentCommonFooterComponent,
    PvRecruitmentRegistrationFormComponent
  ],
  exports: [
    PvRecruitmentCommonHeaderComponent,
    PvRecruitmentCommonFooterComponent
  ],
  imports: [
    CommonModule,
    PvRecruitmentRoutingModule,
    CommonCardModule,
    NbLayoutModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbSelectModule,
    NbIconModule,
    NbCardModule,
    NbStepperModule,
    CommonValetModule,
    ReactiveFormsModule,
    SharedModule,
    NbTooltipModule,
    TranslateModule,
    CommonAutocompleteModule,
    NbAlertModule,
    CommonPipesModule,
    CommonFileUploadModule,
    PipesModule
  ]
})
export class PvRecruitmentModule { }
