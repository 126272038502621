<div class="drop_wrapper h-100">
  <div class="drop h-100 d-flex flex-column justify-content-end">
    <div class="text-center mt-3">
      <nb-icon icon="check" status="success"
               *ngIf="file; else notUploadedIcon"
      ></nb-icon>
      <ng-template #notUploadedIcon>
        <nb-icon icon="upload"></nb-icon>
      </ng-template>
    </div>
    <div class="text-center">
      <b *ngIf="uploadIndication; else genericIndication">{{uploadIndication}}</b>
      <ng-template #genericIndication>
        <b>{{'modules.file_upload.upload_a_file' | translate}}</b>
      </ng-template>
    </div>
    <div class="caption text-center mb-3">
      {{filename ? filename : 'modules.file_upload.drag_drop_file_here' | translate}}
    </div>
  </div>
  <input type="file"
         [accept]="acceptedFileTypes"
         (change)="change($event)"
  >
</div>
