import {Directive, ElementRef, EventEmitter, HostListener, NgZone, OnInit, Output} from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import Autocomplete = google.maps.places.Autocomplete;
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import AutocompleteService = google.maps.places.AutocompleteService;
import PlacesService = google.maps.places.PlacesService;
import PlaceResult = google.maps.places.PlaceResult;
import Geocoder = google.maps.Geocoder;
import {Address} from '../../../admin/modules/admin-places/models/address';
import {GoogleAddressParser} from '../../../shared/classes/google-address-parser';
import GeocoderResult = google.maps.GeocoderResult;

export interface SetPlaceInterface {
  lat?: number;
  lng?: number;
  address?: string;
}

@Directive({
  selector: '[placeSearch]'
})
export class PlaceSearchDirective implements OnInit {
  private autocomplete: Autocomplete;
  private geoCoder: Geocoder;
  private autocompleteService: AutocompleteService;
  private placesService: PlacesService;
  private autocompleteOptions: AutocompleteOptions = {
    componentRestrictions: {
      country: []
    }
  };
  @Output() placeSelected: EventEmitter<PlaceResult | null> = new EventEmitter();
  @HostListener('keydown', ['$event'])
  onKeyDown(e) {
    if (e.keyCode === 13) {
      this.selectFirstPlace();
    }
  };

  constructor(private elementRef: ElementRef,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone
  ) {
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      this.placesService = new google.maps.places.PlacesService(this.elementRef.nativeElement);
      this.autocomplete = new google.maps.places.Autocomplete(
        this.elementRef.nativeElement, this.autocompleteOptions
      );
      this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: PlaceResult = this.autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.placeSelected.emit(place);
        });
      });
    });
  }

  selectFirstPlace() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.autocompleteService.getPlacePredictions({
      input: this.elementRef.nativeElement.value,
    }, (autocompletePredictions) => {
      this.elementRef.nativeElement.value = autocompletePredictions[0].description;
      this.placesService.getDetails({placeId: autocompletePredictions[0].place_id }, place => {
        this.placeSelected.emit(place);
      });
    });
  }

  public setName(name: string) {
    this.elementRef.nativeElement.value = name ?? '';
  }

  public setPlaceByGeocoding(address: Partial<Address>) {
    const params: SetPlaceInterface = address.name && address.name !== '' ?
      { address: address.label } :
      { lat: address.lat, lng: address.lng };

    this.getGeocode(params).then((results: GeocoderResult[]) => {
      let result: GeocoderResult;
      if (!!params.address) {
        result = results.find(res => res.types.includes('establishment'));
      }
      if (!result) {
        result = results[0];
      }

      this.placesService.getDetails({placeId: result.place_id }, place => {
        this.elementRef.nativeElement.value = GoogleAddressParser.getAddressFormattedLabels(place).label;
        this.placeSelected.emit(place);
      });
    }).catch(error => {
      console.error(error);
    });
  }

  private async getGeocode({ lat, lng, address }: SetPlaceInterface) {
    for (let i = 0; i < 3; i++) {
      try {
        return await this.geocodePromise({ lat, lng, address });
      } catch (error) {
        if (error === 'OVER_QUERY_LIMIT') {
          await this.sleep(1000);
        }
      }
    }
  }

  private geocodePromise({ lat, lng, address }: SetPlaceInterface) {
    const payLoad = address ? { address } : {location: {lat, lng}};

    return new Promise((resolve, reject) => {
      this.geoCoder.geocode(payLoad, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
