import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {VideoRecordingService} from './video-recording.service';
import {PvRecruitmentService} from '../../pv-recruitment.service';
import {ToastHandlerService} from '../../../../shared/services/toast-handler.service';
import {Candidate} from '../../models/Candidate';
import {Question} from '../../models/Question';
import {UtilsHelper} from '../../../../shared/helpers/utils.helper';
import {Platform} from '@angular/cdk/platform';

@Component({
  selector: 'pv-recruitement-interview-step',
  templateUrl: './recruitement-interview-step.component.html',
  styleUrls: ['./recruitement-interview-step.component.scss']
})
export class RecruitementInterviewStepComponent implements OnInit {

  @ViewChild('videoElement') videoElement: any;
  video: any;
  introductionVideo = true;
  isVideoRecording = false;
  testStep = true;
  microphoneTest = false;
  microphoneIsTesting = false;
  finalRecord = false;
  recordFinished = false;
  uploadInProgress = false;
  uploadPercent = 0;
  disabledButton = true;
  cameraActivated = false;
  secondsRemaining: number;
  showSecondsRemaining = false;
  interval: any;
  intervalRecording: any;
  intervalStartVideo: any;
  recordingTime = 0;
  pauseRecording = false;
  questions: Question[] = [];
  questionsData = {
    questions: []
  };
  currentQuestionIndex = 0;
  startTime = 0;
  endTime = 0;
  startVideoCounter = 3;
  showStartVideoCounter = false;
  videoConf: MediaStreamConstraints = {
    video: {
      facingMode: 'user',
      width: 720
    },
    audio: true
  };

  @Input() candidate: Candidate;

  constructor(
    private ref: ChangeDetectorRef,
    private videoRecordingService: VideoRecordingService,
    private recruitmentService: PvRecruitmentService,
    private toastService: ToastHandlerService,
    public platform: Platform
  ) {
    this.videoRecordingService.recordingFailed().subscribe(error => {
      this.isVideoRecording = false;
      this.toastService.showErrorToasts(error);
      this.ref.detectChanges();
    });
  }

  ngOnInit() {
    if (this.candidate?.videoInterviewStatus === 'missing') {
      this.recruitmentService.getInterviewQuestions().then(res => {
        this.questions = res;
      })
    }
  }

  startInterviewProcess() {
    this.introductionVideo = false;
    setTimeout(() => {
      this.video = this.videoElement.nativeElement;
      this.video.controls = false;
      this.video.muted = true;
      this.activateCamera()
    })
  }

  activateCamera() {
    return new Promise<MediaStream>((resolve, reject) => {
      this.videoRecordingService.activeCamera(this.videoConf)
        .then(stream => {
          this.video.srcObject = stream;
          this.video.play();
          this.cameraActivated = true;
          resolve(stream);
        })
        .catch(error => {
          this.cameraActivated = false;
          reject(error);
        });
    });
  }

  recordCounter() {
    this.intervalRecording = setInterval(() => {
      if (!this.pauseRecording) {
        this.recordingTime++;
      }}, 1000);
  }

  secondsCounter() {
    this.interval = setInterval(() => {
      if (this.secondsRemaining > 1) {
        this.secondsRemaining--;
      } else {
        this.disabledButton = true;
        this.pauseVideoRecording()
      }}, 1000);
  }

  showTestMicrophone() {
    this.microphoneTest = true;
    this.disabledButton = true;
    setTimeout(() => {
      this.disabledButton = false;
    }, 2000);
  }

  async checkCameraAndMicrophonePermissions(): Promise<boolean> {
    try {
      const cameraPermission = await navigator.permissions.query({ name: 'camera' });
      const microphonePermission = await navigator.permissions.query({ name: 'microphone' });

      const cameraGranted = cameraPermission.state === 'granted';
      const microphoneGranted = microphonePermission.state === 'granted';

      return cameraGranted && microphoneGranted;

    } catch (error) {
      return false;
    }
  }

  async startTestMicrophone() {
    const cameraAccess = await this.checkCameraAndMicrophonePermissions();
    this.testStep = true;

    if (cameraAccess) {
      this.microphoneIsTesting = true;
      const stream = await this.activateCamera();

      const audioContext = new AudioContext();
      const microphone = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();

      analyser.smoothingTimeConstant = 0.3;
      analyser.fftSize = 1024;

      microphone.connect(analyser);

      let microphoneOk = false;
      while (!microphoneOk) {
        await UtilsHelper.sleep(200);

        const array =  new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);

        const sum = array.reduce((a, b) => a + b, 0);
        const average = (sum / array.length) || 0;

        if (average > 5) {
          microphoneOk = true;
        }
      }

      microphone.disconnect();

      this.microphoneIsTesting = false;
      this.testStep = false;
      this.microphoneTest = false;
    } else {
      this.testStep = false;
    }
  }

  showFirstQuestion() {
    this.finalRecord = true
    this.disabledButton = true;
    setTimeout(() => {
      this.disabledButton = false;
    }, 5000);
  }


  startVideoRecording() {
    clearInterval(this.interval);
    this.secondsRemaining = this.questions[this.currentQuestionIndex].maxAnswerDuration;
    this.recordingTime = 0;
    this.disabledButton = true;
    this.showStartVideoCounter = true;
    this.intervalStartVideo = setInterval(() => {
      this.startVideoCounter--;
      if (this.startVideoCounter === 0) {
        setTimeout(() => {
          clearInterval(this.intervalStartVideo)
          this.showStartVideoCounter = false;
        }, 2000)
      }}, 1000);
    setTimeout(() => {
      this.videoRecordingService.startRecording();
      this.isVideoRecording = true;
      this.showSecondsRemaining = true;
      this.recordCounter();
      setTimeout(() => {
        this.disabledButton = false;
      }, this.questions[this.currentQuestionIndex].minAnswerDuration * 1000);
      this.secondsCounter();
    }, 5000);
  }

  async stopVideoRecording() {
    this.uploadInProgress = true;
    const blob = await this.videoRecordingService.stopRecording();
    this.isVideoRecording = false;
    clearInterval(this.interval);
    clearInterval(this.intervalRecording);
    this.video.srcObject = null;
    this.videoRecordingService.stopStream();
    try {
      await this.uploadInterviewVideo(blob);
      await this.updateInterviewVideo();
      this.uploadInProgress = false;
    } catch (error) {
      this.toastService.showErrorToasts(error);
    }
  }

  async uploadInterviewVideo(videoBlob) {
    return new Promise(async (resolve, reject) => {
      try {
        const videoMimeType = this.videoRecordingService.videoMimeType;
        const presignedUrl = await this.recruitmentService.getVideoPresignedUrl(videoMimeType);
        const {progress, promise} = this.recruitmentService.uploadInterviewVideo(presignedUrl, videoBlob, videoMimeType);
        progress.subscribe(percent => {
          this.uploadPercent = percent;
        });
        promise.then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  updateInterviewVideo() {
    return this.recruitmentService.updateInterviewVideo(this.questionsData);
  }

  setTimeVideoData() {
    this.endTime = this.recordingTime * 1000;

    const newQuestionData = {
      id: this.questions[this.currentQuestionIndex].id,
      start: this.startTime !== 0 ? this.startTime : 0,
      end: this.endTime
    };

    this.questionsData.questions.push(newQuestionData);

  }

  pauseVideoRecording() {
    if (this.isVideoRecording) {
      this.videoRecordingService.pauseRecording();
      this.isVideoRecording = false;
      this.disabledButton = true;
      this.showSecondsRemaining = false;
      clearInterval(this.interval);
      clearInterval(this.intervalRecording);

      this.setTimeVideoData();

      setTimeout(() => {
        this.disabledButton = false;
      }, 5000);

      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.startTime = this.endTime;
      } else {
        this.stopVideoRecording();
        this.recordFinished = true;
      }
    }
  }

  resumeVideoRecording() {
    if (!this.isVideoRecording) {
      this.videoRecordingService.resumeRecording();
      this.isVideoRecording = true;
      this.showSecondsRemaining = true;
      clearInterval(this.interval);
      clearInterval(this.intervalRecording)
      this.secondsRemaining = this.questions[this.currentQuestionIndex].maxAnswerDuration;
      this.disabledButton = true;
      if (this.isVideoRecording) {
        this.recordCounter();
        this.secondsCounter()
      }
      setTimeout(() => {
        this.disabledButton = false;
      }, this.questions[this.currentQuestionIndex].minAnswerDuration * 1000);
    }
  }

}
