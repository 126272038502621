import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BatchHelper {

  public static getPrice(batch: any): number {
    let price = 0;
    for (const mission of batch.missions) {
      price += mission.price ?? mission.defaultPrice.value;
    }
    return price;
  }

  public static getCost(batch: any): number {
    let cost = 0;
    for (const mission of batch.missions) {
      cost += mission.cost;
    }
    return cost;
  }

}
