import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastHandlerService } from '../../../../shared/services/toast-handler.service';
import { BackendFilteringObject } from '@pop-valet/smart-table-backend-wrapper';
import { BackendPaginationResponse } from '../../../../shared/models/backend-pagination-response';
import { environment } from '../../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Metropolis } from '../models/metropolis';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AdminGlobalMetropolisService {

  constructor(private http: HttpClient,
              private translateService: TranslateService,
              private toastService: ToastHandlerService) {
  }

  public getMetropolis(filteringObj: BackendFilteringObject): Promise<BackendPaginationResponse<Metropolis>> {
    return this.http.get(
      `${environment.backendUrl}metropolises?paginate=true&${filteringObj.getBackendFiltering()}`
    )
      .pipe(
        map((res: any) => res),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.global.services.metropolis.get_metropolis_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public getAllMetropolis(): Promise<Metropolis[]> {
    return this.http.get(
      `${environment.backendUrl}metropolises`
    )
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.global.services.metropolis.get_all_metropolis_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public createMetropolis(metropolisData: Metropolis): Promise<BackendPaginationResponse<Metropolis>> {
    return this.http.post(
      `${environment.backendUrl}admin/metropolises`,
      metropolisData
    ).pipe(
      map((res: any) => {
        this.toastService.showSuccessToast(
          this.translateService.instant('admin.modules.global.services.metropolis.metropolis_created')
        );
        return res;
      }),
      catchError(err => {
        this.toastService.showErrorToasts(
          this.translateService.instant('admin.modules.global.services.metropolis.metropolis_create_error')
        );
        return throwError(err);
      })
    ).toPromise();
  }

  public updateMetropolis(metropolisId: string, metropolisData: Metropolis): Promise<BackendPaginationResponse<Metropolis>> {
    return this.http.put(
      `${environment.backendUrl}admin/metropolises/${metropolisId}`,
      metropolisData
    ).pipe(
      map((res: any) => {
        this.toastService.showSuccessToast(
          this.translateService.instant('admin.modules.global.services.metropolis.metropolis_updated_success')
        );
        return res;
      }),
      catchError(err => {
        this.toastService.showErrorToasts(
          this.translateService.instant('admin.modules.global.services.metropolis.metropolis_update_error')
        );
        return throwError(err);
      })
    ).toPromise();
  }
}
