import {Component, Input, OnInit} from '@angular/core';
import {Valet} from '../../../../admin/modules/admin-valet/models/valet';

@Component({
    selector: 'pv-admin-valet-evaluation',
    templateUrl: './common-valet-profile-evaluation.component.html',
    styleUrls: ['./common-valet-profile-evaluation.component.scss']
})
export class CommonValetEvaluationComponent implements OnInit {

  @Input() valet: Valet;

  constructor() {
  }

  ngOnInit() {
  }

}
