export interface Candidate {
  id: string;
  archived: boolean;
  archiveReason: boolean;
  archiveDate: Date;
  step: Step;
  referralCode: string | null;
  hasAutoentrepreneurStatus: boolean;
  noTVA: boolean;
  drivingLicensePoints: number;
  drivingLicenseAge: DrivingLicenseAge;
  drivingLicenseType: DrivingLicenceType;
  nationality: Nationality;
  availableOnSaturday: boolean;
  eligibilityStatus: Status;
  idCheckStatus: Status;
  videoInterviewStatus: Status;
  videoInterviewScoring: any;
  videoInterviewNote: string;
  profileStatus: Status;
  documentsStatus: Status;
  contractStatus: Status;
  trainingStatus: Status;
  creationDate: Date;
  lastModificationDate: Date;
  sourcingType: string;
  sourcingNote: string
}

export enum Step {
  tutorial = 'tutorial',
  videoInterview = 'videoInterview',
  idCheck = 'idCheck',
  profile = 'profile',
  contract = 'contract',
  training = 'training'
}

export enum DrivingLicenseAge {
  lessThan3 = 'lessThan3',
  lessThan5 = 'lessThan5',
  moreThanOrEqual3 = 'moreThanOrEqual3',
  moreThanOrEqual5 = 'moreThanOrEqual5'
}

export enum DrivingLicenceType {
  european = 'european',
  foreign = 'foreign'
}

export enum Nationality {
  european = 'european',
  foreign = 'foreign'
}

export enum Status {
  missing = 'missing',
  pending = 'pending',
  valid = 'valid',
  invalid = 'invalid'
}
