import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PvRecruitmentService} from '../../pv-recruitment.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'pv-recruitement-formation-step',
  templateUrl: './recruitement-formation-step.component.html',
  styleUrls: ['./recruitement-formation-step.component.scss']
})
export class RecruitementFormationStepComponent implements OnInit {
  @Input() candidateUser: any;
  @Input() candidate: any;

  @Output() candidateUpdated = new EventEmitter();
  @Output() sessionSetError = new EventEmitter();

  constructor(private fb: FormBuilder,
              private recruitementService: PvRecruitmentService) { }

  public form: FormGroup;
  public sessions = [];
  public isSubmitting = false;

  ngOnInit(): void {
    this.form = this.fb.group({
      session: ['', Validators.required]
    });

    if (['missing', 'invalid'].includes(this.candidate.trainingStatus)) {
      this.loadSessions();
    }
  }

  private loadSessions() {
    this.recruitementService.getUpcomingWebinarSessions().then(sessions => {
      this.sessions = sessions;
    });
  }

  submit() {
    this.isSubmitting = true;
    const formValue = this.form.getRawValue();
    this.recruitementService.setWebinarSession(formValue).then(candidate => {
      this.candidateUpdated.emit(candidate);
    }).catch(() => {
      this.sessionSetError.emit();
      this.loadSessions();
    }).finally(() => {
      this.isSubmitting = false;
    });
  }

}
