import { AfterViewInit, Component, ContentChild, ElementRef, Input, OnInit } from '@angular/core';
import { CommonCardBodyComponent } from '../common-card-body/common-card-body.component';
import { CommonCardFooterComponent } from '../common-card-footer/common-card-footer.component';
import { CommonCardHeaderComponent } from '../common-card-header/common-card-header.component';

@Component({
  selector: 'common-card',
  templateUrl: './common-card.component.html',
  styleUrls: ['./common-card.component.scss']
})
export class CommonCardComponent implements OnInit {
  public isBodyDisplayed = false;
  public isFooterDisplayed = false;
  public isHeaderDisplayed = false;
  @Input() cardColor: 'basic' | 'dark' | 'dark-transparent' | 'basic-transparent' = 'basic';
  @Input() fullHeight: boolean;
  @Input() marginBottom = '30px';
  @ContentChild(CommonCardBodyComponent, {static: true, read: ElementRef}) cardBody: ElementRef;
  @ContentChild(CommonCardFooterComponent, {static: true, read: ElementRef}) cardFooter: ElementRef;
  @ContentChild(CommonCardHeaderComponent, {static: true, read: ElementRef}) cardHeader: ElementRef;

  constructor() {
  }

  ngOnInit() {
    if (this.cardHeader && this.cardHeader.nativeElement.innerHTML) {
      this.isHeaderDisplayed = true;
    }
    if (this.cardBody && this.cardBody.nativeElement.innerHTML) {
      this.isBodyDisplayed = true;
    }
    if (this.cardFooter && this.cardFooter.nativeElement.innerHTML) {
      this.isFooterDisplayed = true;
    }
  }

}
