<common-card style="width: 900px">
  <common-card-header>
    <h5>News</h5>
  </common-card-header>
  <common-card-body>
    <div class="news-content" *ngFor="let newsletter of newsletters">
      <div [innerHTML]="newsletter.content"></div>
      <button nbButton fullWidth status="primary" (click)="markAsRead(newsletter)">J'ai lu</button>
    </div>
  </common-card-body>
</common-card>
