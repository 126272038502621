import {User} from 'src/app/shared/models/user';
import {Mission} from '../../common-mission/models/mission';
import {Customer} from 'src/app/admin/modules/admin-customer/models/customer';

export interface Anomaly {
    id: string;
    type: AnomalyType;
    stage: AnomalyStage;
    missingInfoValet: string[];
    missingInfoCustomer: string[];
    waitingFor: AnomalyWaitingFor[];
    mission: Mission;
    customer: Customer;
    valet: User;
    history: AnomalyHistory[];
    location: string;
    description: string;
    valetPrecision: string;
    report: File;
    accidentReport: File;
    registrationCertificate: File;
    quotations: File[];
    photos: File[];
    photosAccident: File[];
    otherFiles: File[];
    amount: number;
    contraventionMessage: string;
    breakdownLocation: string;
    breakdownReport: File;
    repairerName: string;
    assistanceFileNumber: string;
    garageName: string;
    speedRating: number;
    assistanceRating: number;
    clientMessage: string;
    createdAt: Date;
    updatedAt: Date;
    isRolling: boolean;
}

export interface AnomalyHistory {
    id: string;
    description: string;
    user: HistoryUser;
    type: HistoryType;
    anomaly: Anomaly;
    createdAt: Date;
}

export enum AnomalyType {
    Accident = 'Sinistre',
    Infraction = 'Contravention',
    Breakdown = 'Panne',
    Behaviour = 'Comportement'
}

export enum AnomalyCustomerStage {
    ToFill = 'Données à fournir',
    Closed = 'Cloturé',
    InProgress = 'En cours de traitement',
    Repair = 'En réparation'
}

export enum AnomalyStage {
    InCompletion = 'En complétion',
    ToBeProcessed = 'À traiter',
    Repair = 'En réparation',
    Closed = 'Cloturé',
    InProgress = 'En cours'
}

export enum AnomalyWaitingFor {
    Popvalet = 'Popvalet',
    Valet = 'Valet',
    Customer = 'Client'
}

export enum HistoryType {
    Closed = 'Closed',
    Modified = 'Modified',
    AskedInfo = 'Asked Info',
    StatusChanged = 'Status changed',
    Created = 'Created',
    Internal = 'Internal',
    Message = 'Message',
}

export enum HistoryUser {
    Customer = 'Client',
    Valet = 'Valet',
    Admin = 'Admin'
}

export enum AnomalyFields {
    Location = 'location',
    Description = 'description',
    Amount = 'amount',
    Photos = 'photos',
    Report = 'report',
    AccidentReport = 'accidentReport',
    Quotations = 'quotations',
    ValetPrecision = 'valetPrecision',
    OtherFiles = 'otherFiles',
    RegistrationCertificate = 'registrationCertificate',
    IsRolling = 'isRolling',
    BreakdownLocation = 'breakdownLocation',
    RepairerName = 'repairerName',
    AssistanceFileNumber = 'assistanceFileNumber',
    GarageName = 'garageName'
}

export const neededInfoValet: Record<AnomalyType, AnomalyFields[]> = {
    [AnomalyType.Accident]: [
      AnomalyFields.ValetPrecision,
      AnomalyFields.IsRolling,
      AnomalyFields.AssistanceFileNumber,
      AnomalyFields.GarageName
    ],
    [AnomalyType.Breakdown]: [
      AnomalyFields.Description,
      AnomalyFields.BreakdownLocation,
      AnomalyFields.RepairerName,
      AnomalyFields.AssistanceFileNumber,
      AnomalyFields.Location,
      AnomalyFields.IsRolling
    ],
    [AnomalyType.Infraction]: [],
    [AnomalyType.Behaviour]: []
};

export const neededInfoCustomer: Record<AnomalyType, AnomalyFields[]> = {
    [AnomalyType.Accident]: [
      AnomalyFields.Location,
      AnomalyFields.Description,
      AnomalyFields.Photos,
      AnomalyFields.Quotations,
      AnomalyFields.RegistrationCertificate
    ],
    [AnomalyType.Breakdown]: [],
    [AnomalyType.Infraction]: [AnomalyFields.Photos, AnomalyFields.Amount, AnomalyFields.Description],
    [AnomalyType.Behaviour]: [AnomalyFields.Description]
};
