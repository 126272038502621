import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceSearchDirective } from './directives/place-search.directive';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environment';
import { CommonMapAddressSelectorComponent } from './components/common-map-address-selector/common-map-address-selector.component';
import {CommonTranslateModule} from '../common-translate/common-translate.module';
import {NbCardModule, NbIconModule, NbInputModule, NbPopoverModule} from '@nebular/theme';
import {SharedModule} from '../../shared/shared.module';
import { MapHelperIconComponent } from './components/map-helper-icon/map-helper-icon.component';
import {AddressFormHelper} from './helpers/address-form.helper';


@NgModule({
  declarations: [
    PlaceSearchDirective,
    CommonMapAddressSelectorComponent,
    MapHelperIconComponent,
  ],
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places']
    }),
    CommonTranslateModule,
    NbInputModule,
    SharedModule,
    NbIconModule,
    NbPopoverModule,
    NbCardModule,
  ],
  exports: [
    PlaceSearchDirective,
    AgmCoreModule,
    CommonMapAddressSelectorComponent,
    MapHelperIconComponent
  ]
})
export class CommonMapModule {
}
